<script setup lang="ts">
import { ref } from 'vue';
import LInput from '@/core/components/LInput.vue';
import { useNotification } from '@/core/composables/use-notification';
import { useUserApi } from '@/modules/user/api/user.api';
import { useLearnerStore } from '@/modules/learner/store/learner.store';
import { storeToRefs } from 'pinia';
import { t } from '@/plugins/i18n';
import LButton from '@/core/components/LButton.vue';
import { IonText } from '@ionic/vue';

const userApi = useUserApi();
const learnerStore = useLearnerStore();
const { profile } = storeToRefs(learnerStore);
const notify = useNotification();

const newName = ref(profile.value.user.firstName);
const newLastName = ref(profile.value.user.lastName);
const error = ref<string>('');
const error2 = ref<string>('');

const updateNames = async () => {
    error.value = ''; // Сброс ошибки при каждом вызове функции
    error2.value = ''; // Сброс ошибки для фамилии

    // Проверяем, что значения не null или undefined и не пустые
    if ((newName.value ?? '').trim() === '') {
        error.value = t('learner.verification.nameEmpty'); // Устанавливаем ошибку для имени
        return;
    }

    if ((newLastName.value ?? '').trim() === '') {
        error2.value = t('learner.verification.nameEmpty'); // Устанавливаем ошибку для фамилии
        return;
    }

    const trimmedFirstName = (newName.value ?? '').trim();
    const trimmedLastName = (newLastName.value ?? '').trim();

    const response = await userApi.patchLearnerData({
        firstName: trimmedFirstName,
        lastName: trimmedLastName,
    });

    if (!response.error) {
        await learnerStore.fetch();

        newName.value = profile.value.user.firstName;
        newLastName.value = profile.value.user.lastName;

        notify.success({
            mode: 'notification',
            type: 'success',
            duration: 2000,
            title: t('learner.verification.successChangeNameTitle'),
            message: t('learner.verification.successChangeName'),
        });
    } else {
        error.value = t('auth.reset.newPasswordErrorTitle');
        notify.error({
            mode: 'notification',
            type: 'danger',
            duration: 3000,
            title: t('auth.reset.newPasswordErrorTitle'),
            message: t('auth.reset.newPasswordErrorDescription'),
        });
    }
};
</script>

<template>
    <div class="flex flex-col gap-2 p-2">
        <ion-text class="font-bold text-base"> {{ t('learner.settings.name') }}</ion-text>
        <l-input
            class="settings w-full"
            :showIcon="true"
            label="Name"
            type="text"
            v-model="newName"
        />
        <!-- Блок с ошибкой для имени -->
        <div
            v-if="error"
            class="error-message text-red-500 text-sm mt-1"
        >
            {{ error }}
        </div>

        <ion-text class="font-bold text-base"> {{ t('learner.settings.SecondName') }}</ion-text>
        <l-input
            class="settings w-full"
            :showIcon="true"
            label="Last Name"
            type="text"
            v-model="newLastName"
        />
        <!-- Блок с ошибкой для фамилии -->
        <div
            v-if="error2"
            class="error-message text-red-500 text-sm mt-1"
        >
            {{ error2 }}
        </div>

        <l-button
            @click="updateNames"
            expand="block"
        >
            {{ t('learner.verification.successButton') }}
        </l-button>
    </div>
</template>

<style scoped lang="scss">
@use '@/css/ui-kit.scss' as *;
.error-message {
    margin: 4px 0;
    margin-left: 4px;
    font-size: 14px;
    background: #ffdcd9;
    width: fit-content;
    height: 28px;
    padding: 5px;
    border-radius: 6px;
}
</style>
