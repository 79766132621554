<script setup lang="ts">
import { IonRow, IonCol, IonIcon, IonText } from '@ionic/vue';
import { chevronBack, eyeOffOutline, eyeOutline } from 'ionicons/icons';
import { computed, ref, watch } from 'vue';
import { t } from '@/plugins/i18n';
import LPage from '@/core/components/LPage.vue';
import LIsland from '@/core/components/LIsland.vue';
import { useNotification } from '@/core/composables/use-notification';
import LInput from '@/core/components/LInput.vue';
import { useAuthApi } from '@/modules/auth/api/auth.api';
import LButton from '@/core/components/LButton.vue';
import { useLearnerStore } from '@/modules/learner/store/learner.store';
import { LearnerRoutes } from '@/router/learner.routes';
import { useRouter } from 'vue-router';

const router = useRouter();

const notify = useNotification();

const learnerStore = useLearnerStore();
const learnerEmail = ref(learnerStore.profile.user.email);
const isNewPasswordEntered = ref(false);

const authApi = useAuthApi();

const title = computed(() => t('learner.settings.passwordTitle'));
const settingsTitle = computed(() => t('learner.settings.title'));
const actualPassword = computed(() => t('learner.settings.actualPassword'));
const newPassword = computed(() => t('learner.settings.newPassword'));
const approvePassword = computed(() => t('learner.settings.approvePassword'));

const passwordForm = ref({
    password: '',
    newPassword: '',
    acceptPassword: '',
});

const isCurrentPasswordVisible = ref(false);
const isNewPasswordVisible = ref(false);
const isConfirmPasswordVisible = ref(false);

const toggleCurrentPasswordVisible = () => {
    isCurrentPasswordVisible.value = !isCurrentPasswordVisible.value;
};
const toggleNewPasswordVisible = () => {
    isNewPasswordVisible.value = !isNewPasswordVisible.value;
};
const toggleConfirmPasswordVisible = () => {
    isConfirmPasswordVisible.value = !isConfirmPasswordVisible.value;
};

const successMessage = ref('');

const fieldErrors = ref({
    current: '',
    new: '',
    confirm: '',
});

const isPasswordMatch = computed(() => {
    return (
        passwordForm.value.newPassword === passwordForm.value.acceptPassword &&
        passwordForm.value.newPassword !== ''
    );
});

const isPasswordHaveUpperAndLowerCase = (password: string) =>
    /[A-Z]/.test(password) && /[a-z]/.test(password);
const isPasswordHaveDigit = (password: string) => /\d/.test(password);
const isPasswordLengthValid = (password: string) => password.length >= 8;

const isPasswordValid = computed(() => {
    return (
        passwordForm.value.password !== '' &&
        passwordForm.value.newPassword !== '' &&
        passwordForm.value.acceptPassword !== '' &&
        isPasswordMatch.value &&
        isPasswordHaveUpperAndLowerCase(passwordForm.value.newPassword) &&
        isPasswordHaveDigit(passwordForm.value.newPassword) &&
        isPasswordLengthValid(passwordForm.value.newPassword)
    );
});

watch(
    () => passwordForm.value.password,
    () => {
        fieldErrors.value.current = '';
        successMessage.value = '';
    },
);
watch(
    () => passwordForm.value.newPassword,
    () => {
        fieldErrors.value.new = '';
        successMessage.value = '';
    },
);
watch(
    () => passwordForm.value.acceptPassword,
    () => {
        fieldErrors.value.confirm = '';
        successMessage.value = '';
    },
);

const updatePassword = async () => {
    fieldErrors.value.current = '';
    fieldErrors.value.new = '';
    fieldErrors.value.confirm = '';
    successMessage.value = '';

    if (!isPasswordValid.value) {
        if (!passwordForm.value.password) {
            fieldErrors.value.current = t('auth.reset.passwordPlaceholder');
        }
        if (!passwordForm.value.newPassword) {
            fieldErrors.value.new = t('auth.reset.passwordPlaceholder');
        }
        if (!passwordForm.value.acceptPassword) {
            fieldErrors.value.confirm = t('auth.reset.passwordPlaceholder');
        }
        if (
            passwordForm.value.newPassword &&
            passwordForm.value.acceptPassword &&
            !isPasswordMatch.value
        ) {
            fieldErrors.value.confirm = t('auth.reset.passwordPlaceholder');
        }
        return;
    }

    const { success: isCurrentPasswordCorrect } = await authApi.login({
        email: learnerEmail.value,
        password: passwordForm.value.password,
    });

    if (!isCurrentPasswordCorrect) {
        fieldErrors.value.current = t('auth.reset.newPasswordErrorDescription');
        return;
    }

    const success = await authApi.passwordChange(
        passwordForm.value.password,
        passwordForm.value.newPassword,
    );

    if (success) {
        notify.success({
            mode: 'notification',
            type: 'success',
            duration: 2000,
            title: t('auth.reset.successPasswordTitle'),
            message: t('auth.reset.successTitle'),
        });

        passwordForm.value.password = '';
        passwordForm.value.newPassword = '';
        passwordForm.value.acceptPassword = '';

        setTimeout(() => {
            router.push({ name: LearnerRoutes.Settings });
        }, 2000);
    } else {
        notify.error({
            mode: 'notification',
            type: 'danger',
            duration: 3000,
            title: t('auth.reset.newPasswordErrorTitle'),
            message: t('auth.reset.newPasswordErrorDescription'),
        });
    }
};

// Переменные для отслеживания состояния валидации каждого пароля
const isPasswordUpperLowerCaseValid = computed(() =>
    isPasswordHaveUpperAndLowerCase(passwordForm.value.newPassword),
);
const isPasswordDigitValid = computed(() => isPasswordHaveDigit(passwordForm.value.newPassword));
const isPasswordLengthValids = computed(() =>
    isPasswordLengthValid(passwordForm.value.newPassword),
);
</script>

<template>
    <l-page class="page-container">
        <ion-row>
            <ion-col
                size="12"
                size-lg="6"
            >
                <router-link
                    class="min-h-14 flex flex-row gap-2 my-auto"
                    :to="{ name: LearnerRoutes.Settings }"
                >
                    <ion-icon
                        class="me-2 my-auto"
                        color="dark"
                        :icon="chevronBack"
                    />
                    <ion-text class="text-base my-auto pl-2">
                        {{ settingsTitle }}
                    </ion-text>
                </router-link>
                <ion-row class="gap-y-12">
                    <l-island>
                        <ion-col class="pl-0">
                            <h5 class="pb-8">{{ title }}</h5>

                            <div
                                v-if="successMessage"
                                class="text-success mb-4"
                            >
                                {{ successMessage }}
                            </div>

                            <div class="flex flex-col gap-2 pb-8">
                                <ion-text class="font-bold text-base">
                                    {{ actualPassword }}
                                </ion-text>
                                <l-input
                                    v-model="passwordForm.password"
                                    :type="isCurrentPasswordVisible ? 'text' : 'password'"
                                    :placeholder="t('auth.login.passwordPlaceholder')"
                                    :color="fieldErrors.current ? 'danger' : undefined"
                                >
                                    <l-button
                                        @click="toggleCurrentPasswordVisible"
                                        variant="text"
                                        slot="end"
                                        aria-label="Показать/скрыть текущий пароль"
                                        class="pr-4 text-black"
                                    >
                                        <ion-icon
                                            v-if="isCurrentPasswordVisible"
                                            slot="icon-only"
                                            :icon="eyeOutline"
                                            aria-hidden="true"
                                        />
                                        <ion-icon
                                            v-else
                                            slot="icon-only"
                                            :icon="eyeOffOutline"
                                            aria-hidden="true"
                                        />
                                    </l-button>
                                </l-input>
                                <div
                                    v-if="fieldErrors.current"
                                    class="text-error"
                                >
                                    {{ fieldErrors.current }}
                                </div>

                                <ion-text class="font-bold text-base">
                                    {{ newPassword }}
                                </ion-text>
                                <l-input
                                    v-model="passwordForm.newPassword"
                                    :type="isNewPasswordVisible ? 'text' : 'password'"
                                    :placeholder="t('auth.login.passwordPlaceholder')"
                                    :color="fieldErrors.new ? 'danger' : undefined"
                                    @input="isNewPasswordEntered = true"
                                >
                                    <l-button
                                        @click="toggleNewPasswordVisible"
                                        variant="text"
                                        slot="end"
                                        aria-label="Показать/скрыть новый пароль"
                                        class="pr-4 text-black"
                                    >
                                        <ion-icon
                                            v-if="isNewPasswordVisible"
                                            slot="icon-only"
                                            :icon="eyeOutline"
                                            aria-hidden="true"
                                        />
                                        <ion-icon
                                            v-else
                                            slot="icon-only"
                                            :icon="eyeOffOutline"
                                            aria-hidden="true"
                                        />
                                    </l-button>
                                </l-input>

                                <!-- Подсказки для нового пароля -->
                                <div
                                    v-if="isNewPasswordEntered"
                                    class="password-validation"
                                    :style="{
                                        backgroundColor: isPasswordUpperLowerCaseValid
                                            ? '#d4f9e1'
                                            : '#ffdcd9',
                                    }"
                                >
                                    {{ t('auth.signup.passwordCaseRule') }}
                                </div>
                                <div
                                    v-if="isNewPasswordEntered"
                                    class="password-validation"
                                    :style="{
                                        backgroundColor: isPasswordDigitValid
                                            ? '#d4f9e1'
                                            : '#ffdcd9',
                                    }"
                                >
                                    {{ t('auth.signup.passwordDigitRule') }}
                                </div>
                                <div
                                    v-if="isNewPasswordEntered"
                                    class="password-validation"
                                    :style="{
                                        backgroundColor: isPasswordLengthValids
                                            ? '#d4f9e1'
                                            : '#ffdcd9',
                                    }"
                                >
                                    {{ t('auth.signup.passwordLengthRule') }}
                                </div>

                                <div
                                    v-if="fieldErrors.new"
                                    class="text-error"
                                >
                                    {{ fieldErrors.new }}
                                </div>

                                <ion-text class="font-bold text-base">
                                    {{ approvePassword }}
                                </ion-text>
                                <l-input
                                    v-model="passwordForm.acceptPassword"
                                    :type="isConfirmPasswordVisible ? 'text' : 'password'"
                                    :placeholder="t('auth.login.passwordPlaceholder')"
                                    :color="fieldErrors.confirm ? 'danger' : undefined"
                                >
                                    <l-button
                                        @click="toggleConfirmPasswordVisible"
                                        variant="text"
                                        slot="end"
                                        aria-label="Показать/скрыть подтверждение пароля"
                                        class="pr-4 text-black"
                                    >
                                        <ion-icon
                                            v-if="isConfirmPasswordVisible"
                                            slot="icon-only"
                                            :icon="eyeOutline"
                                            aria-hidden="true"
                                        />
                                        <ion-icon
                                            v-else
                                            slot="icon-only"
                                            :icon="eyeOffOutline"
                                            aria-hidden="true"
                                        />
                                    </l-button>
                                </l-input>
                                <div
                                    v-if="fieldErrors.confirm"
                                    class="text-error"
                                >
                                    {{ fieldErrors.confirm }}
                                </div>

                                <l-button
                                    :disabled="!isPasswordValid"
                                    class="mt-6"
                                    @click="updatePassword"
                                    variant="contained"
                                    color="primary"
                                >
                                    {{ t('learner.settings.updatePasswordButton') }}
                                </l-button>
                            </div>
                        </ion-col>
                    </l-island>
                </ion-row>
            </ion-col>
        </ion-row>
    </l-page>
</template>

<style lang="scss" scoped>
/* Другие стили */

.password-validation {
    margin-left: 4px;
    font-size: 14px;
    width: fit-content;
    height: 28px;
    padding: 5px;
    border-radius: 6px;
    transition: background-color 0.3s ease;
}

.text-error {
    margin: 4px 0;
    margin-left: 4px;
    font-size: 14px;
    background: #ffdcd9;
    width: fit-content;
    height: 28px;
    padding: 5px;
    border-radius: 6px;
}

.text-success {
    color: #069e2d;
}
</style>
