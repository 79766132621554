<script setup lang="ts">
import { IonRow, IonCol, onIonViewWillEnter } from '@ionic/vue';
import LPage from '@/core/components/LPage.vue';
import LIsland from '@/core/components/LIsland.vue';
import LSubscriptions from '../components/subscriptions/LSubscriptions.vue';
import { useLearnerStore } from '../store/learner.store';
import { storeToRefs } from 'pinia';

const learnerStore = useLearnerStore();

const { isLoading, subscriptions } = storeToRefs(learnerStore);

const fetchSubs = () => {
    learnerStore.fetchSubscriptions();
};

onIonViewWillEnter(fetchSubs);
</script>

<template>
    <l-page class="page-container">
        <ion-row>
            <ion-col size="12">
                <l-island class="mt-4 lg:mt-0">
                    <l-subscriptions
                        :subscriptions="subscriptions"
                        :is-loading="isLoading.subscriptions"
                    />
                </l-island>
            </ion-col>
        </ion-row>
    </l-page>
</template>

<style scoped>
.page-container {
    --ion-grid-padding: 0;
    --ion-grid-column-padding: 0;
}
</style>
