<script setup lang="ts">
import { computed } from 'vue';

type TailPosition =
    | 'top-left'
    | 'top-right'
    | 'left-up'
    | 'left-down'
    | 'right-up'
    | 'right-down'
    | 'bottom-left'
    | 'bottom-right';

const props = defineProps<{
    message: string;
    maxChars?: number;
    showTail?: boolean;
    tailPosition?: TailPosition;
}>();

const truncatedMessage = computed(() => {
    return props.message.length > (props.maxChars ?? 200)
        ? `${props.message.slice(0, props.maxChars)}...`
        : props.message;
});
</script>

<template>
    <div class="message-container">
        <div
            class="message"
            :class="{
                'with-tail': showTail,
                [`tail-${tailPosition}`]: showTail,
                'has-left-tail':
                    showTail && ['left-up', 'left-down'].includes(props.tailPosition || ''),
            }"
        >
            <span>{{ truncatedMessage }}</span>
        </div>
    </div>
</template>

<style scoped lang="scss">
@use '@/css/ui-kit.scss' as *;

.message-container {
    position: relative;
    display: inline-block;
    z-index: 4;
}

.message {
    background-color: $purple-light;
    padding: 8px 12px;
    border-radius: 8px;
    font-size: 14px;
    color: $grey-700;
    max-width: 100%;
    word-wrap: break-word;
    position: relative;
}

.message.with-tail::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-width: 15px;
    border-style: solid;
    border-radius: 6px;
    border-color: transparent transparent $purple-light transparent;
    transform: rotate(225deg);
    z-index: -1;
}

.message.tail-top-left::after {
    top: -10px;
    left: 10px;
    transform: rotate(225deg);
}

.message.tail-top-right::after {
    top: -10px;
    right: 10px;
    transform: rotate(135deg);
}

.message.tail-left-up::after {
    top: 10px;
    left: -7px;
    transform: rotate(135deg);
}

.message.tail-left-down::after {
    bottom: 10px;
    left: -10px;
    transform: rotate(45deg);
}

.message.tail-right-up::after {
    top: 10px;
    right: -10px;
    transform: rotate(225deg);
}

.message.tail-right-down::after {
    bottom: 10px;
    right: -10px;
    transform: rotate(135deg);
}

.message.tail-bottom-left::after {
    bottom: -10px;
    left: -8px;
    transform: rotate(-45deg);
}

.message.tail-bottom-right::after {
    bottom: -10px;
    right: 10px;
    transform: rotate(-45deg);
}

.message.has-left-tail {
    margin-left: 20px;
}
</style>
