<script setup lang="ts">
import { ref, computed } from 'vue';
import RequestResetCode from './reset-password/RequestResetCode.vue';
import VerifyResetCode from './reset-password/VerifyResetCode.vue';
import LPage from '@/core/components/LPage.vue';
import LLogo from '@/core/components/LLogo.vue';
import { t } from '@/plugins/i18n';

const STEPS = [
    { name: 'requestResetCode', component: RequestResetCode },
    { name: 'verigtCode', component: VerifyResetCode },
];
const email = ref<string>('');
const currentStepIndex = ref(0);
const currentComponent = computed(() => STEPS[currentStepIndex.value].component);
const subtitle = computed(() =>
    currentStepIndex.value === 0 ? t('auth.reset.subTitle') : t('auth.reset.subTitleCodeSent'),
);

const updateEmail = (newEmail: string) => {
    email.value = newEmail;
};

const nextStep = () => {
    if (currentStepIndex.value < STEPS.length - 1) {
        currentStepIndex.value += 1;
    }
};

const prevStep = () => {
    if (currentStepIndex.value > 0) {
        currentStepIndex.value -= 1;
    }
};
</script>

<template>
    <l-page>
        <div class="container px-4 pt-16">
            <div class="inner-container">
                <l-logo class="mx-auto mb-8 h-16" />
            </div>

            <div class="caption-2 mb-6 text-center">{{ t('auth.reset.title') }}</div>
            <div class="body-2 mb-6 text-center">{{ t('auth.reset.subTitle') }}</div>

            <div class="w-full mb-4">
                <component
                    :is="currentComponent"
                    :email="email"
                    @updateEmail="updateEmail"
                    @nextStep="nextStep"
                    @prevStep="prevStep"
                />
            </div>
        </div>
    </l-page>
</template>

<style scoped>
.container {
    .inner-container {
        margin: 0 auto;
        max-width: 450px;
    }
}
</style>
