<script setup lang="ts">
import { IonText, IonIcon } from '@ionic/vue';
import LButton from '@/core/components/LButton.vue';
import LAvatarSequence from '@/core/components/LAvatarSequence.vue';
import LIsland from '@/core/components/LIsland.vue';
import { LearnerRoutes } from '@/router/learner.routes';
import { t } from '@/plugins/i18n';
import { computed } from 'vue';
import { arrowForwardOutline } from 'ionicons/icons';

// TODO: replace temp mock images with real tutor avatars
const tutorAvatars = [
    '/assets/tutor-avatar-1.jpg',
    '/assets/tutor-avatar-2.jpg',
    '/assets/tutor-avatar-3.jpg',
];
const findTutorText = computed(() => t('learner.home.findTutor'));
const findTutorTitle = computed(() => t('learner.home.findTutorTitle'));
const findTutorDescription = computed(() => t('learner.home.findTutorDescription'));
</script>

<template>
    <l-island class="find-tutor-section">
        <l-avatar-sequence
            :sources="tutorAvatars"
            size="xl"
        />
        <ion-text class="caption-1 font-semibold">
            <h4>{{ findTutorTitle }}</h4>
        </ion-text>

        <ion-text class="body-1">
            <p>
                {{ findTutorDescription }}
            </p>
        </ion-text>

        <l-button
            :routerLink="{ name: LearnerRoutes.Catalog }"
            class="w-full max-w-md"
            expand="block"
        >
            {{ findTutorText }}
            <ion-icon
                :icon="arrowForwardOutline"
                slot="end"
            />
        </l-button>
    </l-island>
</template>

<style lang="scss" scoped>
.find-tutor-section {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    gap: 1.5rem;
}
</style>
