import { createClientProxy } from '@/core/api/client-proxy';
import { paths } from '../../../../../../packages/api-client/src';
import { ExtractElement } from '@common/types';

export type Learner =
    paths['/learner/profile']['get']['responses']['200']['content']['application/json'];
export type LearnerSubscription =
    paths['/learner/subscriptions/']['get']['responses']['200']['content']['application/json']['data'][0];
export type TutorCatalogResponse =
    paths['/learner/tutors/search']['get']['responses']['200']['content']['application/json'];
export type TutorCatalog =
    paths['/learner/tutors/search']['get']['responses']['200']['content']['application/json']['data'];
export type TutorProfile =
    paths['/learner/tutors/{tutorUserUid}']['get']['responses']['200']['content']['application/json'];
export type TutorPricing =
    paths['/learner/tutors/{tutorUserUid}']['get']['responses']['200']['content']['application/json']['pricing'];
export type Tutor =
    paths['/learner/tutors/search']['get']['responses']['200']['content']['application/json']['data'][0];
export type LessonEvent =
    paths['/learner/events/']['get']['responses']['200']['content']['application/json']['data'][0];
export type PaymentMethod =
    paths['/learner/saved-cards/']['get']['responses']['200']['content']['application/json']['data'][0];
export type PaymentAddResponse =
    paths['/learner/saved-cards/add']['post']['responses']['200']['content']['application/json'];
export type Meta =
    paths['/learner/tutors/search']['get']['responses']['200']['content']['application/json']['meta'];
export type AvaliableSlot =
    paths['/learner/available-slots/']['get']['responses']['200']['content']['application/json']['data'][0];
export type ScheduleTrialParams =
    paths['/learner/trial-lesson/schedule']['post']['requestBody']['content']['application/json'];
export type GetAvaliableSlotsParams =
    paths['/learner/available-slots/']['get']['parameters']['query'];
export type GetAvaliableSlotsRecurrentParams =
    paths['/learner/available-slots/recurrent']['get']['parameters']['query'];
export type PatchLearnerDto =
    paths['/user/']['patch']['requestBody']['content']['application/json'];
export type PatchPaymentMethodParams =
    paths['/learner/saved-cards/{uid}']['patch']['requestBody']['content']['application/json'];
export type GetEventsParams = paths['/learner/events/']['get']['parameters']['query'];
export type TutorsCatalogParams = paths['/learner/tutors/search']['get']['parameters']['query'];

// NOTE: workaround to extract LearnerLessonStatus type
export type LearnerLessonStatus = ExtractElement<
    paths['/learner/events/']['get']['parameters']['query']['statuses']
>;

export interface IResponse<T> {
    data?: T;
    success: boolean;
}

export interface IBookTrialResponse {
    success: boolean;
}

export interface IScheduleTrialLessonResponse {
    success: boolean;
}

const client = createClientProxy<paths>({
    errorNotify: false,
    errorThrow: false,
});

export class LearnerApi {
    async getLearnerProfile(): Promise<Learner> {
        const { data } = await client.GET('/learner/profile');
        return data!;
    }

    async getTutor(tutorUserUid: string): Promise<TutorProfile> {
        const { data } = await client.GET('/learner/tutors/{tutorUserUid}', {
            params: { path: { tutorUserUid } },
        });
        return data!;
    }

    async getCatalogOfTutors({
        page,
        pageSize,
        subject,
    }: TutorsCatalogParams): Promise<IResponse<TutorCatalogResponse>> {
        const { data, error } = await client.GET('/learner/tutors/search', {
            params: { query: { page, pageSize, subject } },
        });

        return {
            data,
            success: !error,
        };
    }

    async getLearnerEvents(payload: GetEventsParams): Promise<IResponse<LessonEvent[]>> {
        const resp = await client
            .GET('/learner/events/', {
                params: { query: payload },
            })
            .catch((error) => console.error('Failed to fetch lessons:', error));

        return {
            data: resp?.data?.data,
            success: !resp?.error,
        };
    }
    async getSubscriptions(): Promise<IResponse<LearnerSubscription[]>> {
        const { data, error } = await client.GET('/learner/subscriptions/');
        return {
            data: data?.data,
            success: !error,
        };
    }

    async getTutorAvailableSlots({
        startTime,
        endTime,
        tutorUserUid,
    }: GetAvaliableSlotsParams): Promise<IResponse<AvaliableSlot[]>> {
        const { data, error } = await client.GET('/learner/available-slots/', {
            params: { query: { startTime, endTime, tutorUserUid } },
        });
        return {
            data: data?.data,
            success: !error,
        };
    }
    async getTutorAvailableSlotsRecurrent({
        tutorUserUid,
        slotDuration,
    }: GetAvaliableSlotsRecurrentParams): Promise<IResponse<AvaliableSlot[]>> {
        const { data, error } = await client.GET('/learner/available-slots/recurrent', {
            params: { query: { slotDuration, tutorUserUid } },
        });
        return {
            data: data?.data,
            success: !error,
        };
    }

    async scheduleTrialLesson(params: ScheduleTrialParams): Promise<IScheduleTrialLessonResponse> {
        const { endTime, startTime, tutorUserUid, subjectCode } = params;
        const { error } = await client.POST('/learner/trial-lesson/schedule', {
            body: { endTime, startTime, tutorUserUid, subjectCode },
        });
        return {
            success: !error,
        };
    }

    async patchLearnerData(data: PatchLearnerDto) {
        return await client.PATCH('/user/', { body: data });
    }

    async addPaymentMethod(): Promise<IResponse<PaymentAddResponse>> {
        const { data, error } = await client.POST('/learner/saved-cards/add', {
            body: {},
        });
        return {
            data,
            success: !error,
        };
    }

    async getPaymentMethods(): Promise<IResponse<PaymentMethod[]>> {
        const { data, error } = await client.GET('/learner/saved-cards/');
        return {
            data: data?.data,
            success: !error,
        };
    }

    async deletePaymentMethod(cardUid: string): Promise<IResponse<undefined>> {
        const { error } = await client.DELETE('/learner/saved-cards/{uid}', {
            params: { path: { uid: cardUid } },
        });

        return {
            success: !error,
        };
    }

    async patchPaymentMethod(
        cardUid: PaymentMethod['uid'],
        body: PatchPaymentMethodParams,
    ): Promise<IResponse<undefined>> {
        const { error } = await client.PATCH('/learner/saved-cards/{uid}', {
            body,
            params: { path: { uid: cardUid } },
        });

        return {
            success: !error,
        };
    }
}

export const useLearnerApi = () => new LearnerApi();
