<script setup lang="ts">
import { onMounted, ref, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { IonFooter, IonGrid, IonRow, IonCol, IonButtons, IonLabel, IonSpinner } from '@ionic/vue';
import { useRoute, useRouter } from 'vue-router';
import { useTutorCatalogStore } from '../../store/tutors-catalog.store';
import LTutorCard from './LTutorCard.vue';
import LTutorPreview from './LTutorPreview.vue';
import { t } from '@/plugins/i18n';
import { useScreenSize } from '@/core/utils/use-screen-size';
import LButton from '@/core/components/LButton.vue';
import LSkeletonTutorList from './LSkeletonTutorList.vue';
import { computed } from 'vue';

defineProps<{ isOnboarding: boolean }>();

const { isLargeSize } = useScreenSize();
const localePref = 'learner.tutorCatalog';
const tutorsStore = useTutorCatalogStore();

const route = useRoute();
const router = useRouter();
const showMoreText = computed(() => t('learner.home.loadMore'));

const page = ref(route.query.page ? Number(route.query.page) : 1);
const activeCardPosition = ref(0);

const subject = 'english';
const { currentPage, totalPages, tutors, isLoading, isLoadMoreAvailable } =
    storeToRefs(tutorsStore);

onMounted(async () => {
    await tutorsStore.fetchTutors('english', page.value);
});

watch(route, async () => {
    if (route.path.includes('catalog')) {
        page.value = Number(route.query.page) || 1;
        await tutorsStore.fetchTutors(subject, page.value);
    }
});

const updateQueryParams = (newPage: number) => {
    router.push({ query: { page: newPage } });
};

const handleHoverCard = (position: { top: number; left: number }) => {
    activeCardPosition.value = position.top + 16; // padding ;
};
</script>
<template>
    <div v-if="!isLoading && tutors?.length === 0">
        <p>{{ t(`${localePref}.noTutorsFound`) }}</p>
    </div>
    <div>
        <ion-grid>
            <ion-row>
                <ion-col
                    size-lg="7"
                    size-sm="12"
                    size-xs="12"
                >
                    <div class="left-column">
                        <l-skeleton-tutor-list
                            v-if="isLoading"
                            :length="5"
                        />
                        <l-tutor-card
                            v-else
                            v-for="(tutor, index) in tutors"
                            :key="tutor.tutorUserUid"
                            :index="index"
                            :tutor="tutor"
                            :isOnboarding="isOnboarding"
                            @hoverCard="handleHoverCard"
                        />
                    </div>
                </ion-col>
                <ion-col
                    v-show="isLargeSize"
                    size="5"
                >
                    <l-tutor-preview :activeCardPosition="activeCardPosition" />
                </ion-col>
            </ion-row>
        </ion-grid>

        <!-- // TODO: Developed without design. Will need to be changed after designer edits  -->
        <ion-footer>
            <l-button
                v-if="isLoadMoreAvailable"
                @click="() => tutorsStore.loadMore(subject)"
                class="w-full flex text-center justify-center items-center"
                type="button"
                size="small"
                variant="text"
                color="secondary"
            >
                {{ showMoreText }}
            </l-button>
        </ion-footer>
    </div>
</template>

<style scoped>
ion-col {
    margin: 0px;
    padding: 0px;
}

ion-footer {
    display: flex;
    justify-content: center;
    padding: 10px;
}

@media screen and (max-width: 767px) {
    ion-grid {
        padding: 4px;
    }
}
</style>
