<script setup lang="ts">
import LPairAvatar from '@/core/components/LPairAvatar.vue';
import { IonCol, IonGrid, IonIcon, IonRow, IonText } from '@ionic/vue';
import LSubjectChip from '@/core/components/LSubjectChip.vue';
import { LearnerLessonStatus, LessonEvent } from '../../api/learner.api';
import LLessonStatus from './LLessonStatus.vue';
import { computed } from 'vue';
import { isAfter, subMinutes } from 'date-fns';
import { useLocaleDateFormat } from '@/core/utils/use-locale-date-format';
import { getUserAvatarUrlByUid } from '@/core/utils/get-user-avatar-by-uid';
import { LESSON_DATE_FORMAT } from '../../constants';
import { t } from '@/plugins/i18n';
import { LessonScheduleType } from '../../types';
import LButton from '@/core/components/LButton.vue';
import LLessonDuration from './LLessonDuration.vue';
import { useAuthStore } from '@/modules/auth/store/auth.store';
import { getJoinLessonUrl } from '../../utils';
import LLessonMenu from './LLessonMenu.vue';
import RouterLinkExtended from '@/core/components/RouterLinkExtended.vue';
import { LearnerRoutes } from '@/router/learner.routes';
import { arrowForward } from 'ionicons/icons';
import { useScreenSize } from '@/core/utils/use-screen-size';
import { useRoute } from 'vue-router';

interface Props {
    lesson: LessonEvent;
    lessonType?: LessonScheduleType;
    underline?: Boolean;
}
const { lesson, lessonType, underline = true } = defineProps<Props>();
const formatLocale = useLocaleDateFormat();
const { isLargeSize } = useScreenSize();
const { user } = useAuthStore();
const route = useRoute();

const lessonDate = computed(() =>
    formatLocale(new Date(lesson.startTime?.toString()), LESSON_DATE_FORMAT),
);
const tutorAvatar = computed(() => getUserAvatarUrlByUid(lesson.tutorUserUid));
const learnerAvatar = computed(() => getUserAvatarUrlByUid(user.uid));

const subjectCode = computed(() => lesson.subjectCode);
const isNextLesson = computed(() => lessonType === LessonScheduleType.Next);
const isScheduleButtonShown = computed(() => !isNextLesson.value && lesson.type === 'trial_lesson');
const lessonStatus = computed(() => lesson.status as LearnerLessonStatus);
const joinLessonPath = computed(() => getJoinLessonUrl(lesson.uid));

/** @description a predicate to check if the lesson has started or will start in 5 minutes */
const isJoinLessonShown = computed(() =>
    isAfter(Date.now(), subMinutes(lesson.startTime?.toString(), 5)),
);
const lessonMainRowClasses = computed(() => ({
    'past-lesson-main-row': !isNextLesson.value,
}));

const joinLessonText = computed(() => t('learner.home.joinLesson'));
const learnerLabel = computed(() => t('auth.selectRole.learner'));
const tutorLabel = computed(() => t('auth.selectRole.tutor'));
</script>

<template>
    <!-- TODO: find a way to get rid of the manual 'pl-0' class in the ion-grid -->
    <ion-grid class="pl-0 pt-4 border-b border-gray-100">
        <ion-row
            v-if="isNextLesson"
            class="pl-0"
        >
            <ion-col class="pl-0">
                <ion-text class="font-bold text-base">{{ lessonDate }}</ion-text>
            </ion-col>
        </ion-row>

        <ion-row
            class="pl-0 pb-1 flex gap-3 items-center flex-nowrap"
            :class="lessonMainRowClasses"
        >
            <ion-col
                class="pl-0"
                size="auto"
            >
                <l-subject-chip :code="subjectCode" />
            </ion-col>

            <ion-col
                size="auto"
                class="flex items-center gap-x-4"
            >
                <l-pair-avatar
                    :first-src="tutorAvatar"
                    :second-src="learnerAvatar"
                    :first-alt="learnerLabel"
                    :second-alt="tutorLabel"
                    size="xs"
                />
                <l-lesson-status
                    v-if="!isNextLesson"
                    :status="lessonStatus"
                    :lesson-date="lesson.startTime.toString()"
                />
            </ion-col>

            <ion-col
                v-if="isNextLesson"
                size="auto"
            >
                <l-lesson-duration :lesson="lesson" />
            </ion-col>

            <ion-col class="text-end">
                <l-lesson-menu
                    v-if="isNextLesson"
                    :lesson="lesson"
                />

                <!-- NOTE: commented out for now, as the design is updated
                     TODO: uncomment or remove when the design is ready
                <router-link-extended
                    v-else
                    :to="lessonPath"
                >
                    <l-button
                        variant="text"
                        size="small"
                    >
                        <ion-icon
                            :icon="chevronForwardOutline"
                            class="icon-middle icon-large"
                            color="tertiary"
                        />
                    </l-button>
                </router-link-extended> -->
            </ion-col>
        </ion-row>

        <ion-row
            v-if="isScheduleButtonShown"
            class="mt-8 mb-2"
        >
            <ion-col size="12">
                <l-button
                    :router-link="{
                        name: LearnerRoutes.ScheduleRecurrentTime,
                        params: { tutorUserUid: lesson.tutorUserUid },
                    }"
                    :expand="isLargeSize ? undefined : 'block'"
                    variant="secondary"
                >
                    {{ t('learner.home.banner.schedule') }}
                    <ion-icon
                        :icon="arrowForward"
                        slot="end"
                    />
                </l-button>
            </ion-col>
        </ion-row>

        <ion-row
            v-if="isNextLesson"
            class="pl-0 pb-4"
        >
            <router-link-extended :to="joinLessonPath">
                <!-- TODO: remove route.query.test after demo -->
                <l-button
                    v-if="route.query.test ? true : isJoinLessonShown"
                    color="primary"
                    size="small"
                    strong
                >
                    {{ joinLessonText }}
                </l-button>
            </router-link-extended>
        </ion-row>
    </ion-grid>
</template>

<style scoped lang="scss">
.past-lesson-main-row {
    flex-direction: column;
    align-items: flex-start;
}
</style>
