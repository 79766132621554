<script lang="ts" setup>
import { ref, computed } from 'vue';
import { Color } from '@ionic/core';
import { t } from '@/plugins/i18n';
import { eyeOffOutline, eyeOutline } from 'ionicons/icons';
import LInput from '@/core/components/LInput.vue';
import LFeedback from '@/core/components/LFeedback.vue';
import LButton from '@/core/components/LButton.vue';
import { IonIcon } from '@ionic/vue';

const props = defineProps<{
    modelValue?: string | null;
    placeholder?: string;
    color?: Color;
    disabled?: boolean;
    clearInput?: boolean;
    debounce?: number;
    label?: string;
}>();

const emit = defineEmits<{
    (e: 'update:modelValue', value: string | null): void;
    (e: 'input', value: InputEvent): void;
    (e: 'blur', value: FocusEvent): void;
    (e: 'validate', value: boolean): void;
}>();

const handleInput = (event: CustomEvent) => {
    const target = event.target as HTMLIonInputElement;
    const value = target.value as string;

    const isPasswordValid =
        isPasswordHaveUpperAndLowerCase(value) &&
        isPasswordHaveDigit(value) &&
        isPasswordLengthValid(value);

    emit('update:modelValue', value || null);
    emit('input', event as any);
    emit('validate', isPasswordValid);
};

const handleBlur = (event: any) => {
    emit('blur', event);
};

const isPasswordVisible = ref(false);

const togglePasswordVisible = () => {
    isPasswordVisible.value = !isPasswordVisible.value;
};

const isPasswordHaveUpperAndLowerCase = (password: string) => {
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    return hasUpperCase && hasLowerCase;
};

const isPasswordHaveDigit = (password: string) => /\d/.test(password);

const isPasswordLengthValid = (password: string) => password.length >= 8;

const getPasswordRuleColor = (callback: (password: string) => boolean) => {
    if (!props.modelValue) {
        return 'base';
    }

    if (callback(props.modelValue)) {
        return 'light';
    }

    return 'danger-tint';
};
</script>
<template>
    <l-input
        v-model="props.modelValue"
        :type="isPasswordVisible ? 'text' : 'password'"
        :placeholder="t('auth.login.passwordPlaceholder')"
        @ionInput="handleInput"
        @ion-blur="handleBlur"
    >
        <l-button
            @click="togglePasswordVisible"
            variant="text"
            slot="end"
            aria-label="Show/hide"
            class="pr-4 text-black"
        >
            <ion-icon
                v-if="isPasswordVisible"
                slot="icon-only"
                :icon="eyeOutline"
                aria-hidden="true"
            ></ion-icon>
            <ion-icon
                v-else
                slot="icon-only"
                :icon="eyeOffOutline"
                aria-hidden="true"
            ></ion-icon>
        </l-button>
    </l-input>
    <div class="helpers body-1">
        <l-feedback
            class="mt-1"
            :color="getPasswordRuleColor(isPasswordHaveUpperAndLowerCase)"
            >{{ t('auth.signup.passwordCaseRule') }}</l-feedback
        >
        <l-feedback :color="getPasswordRuleColor(isPasswordHaveDigit)">{{
            t('auth.signup.passwordDigitRule')
        }}</l-feedback>
        <l-feedback :color="getPasswordRuleColor(isPasswordLengthValid)">{{
            t('auth.signup.passwordLengthRule')
        }}</l-feedback>
    </div>
</template>

<style lang="scss">
// TODO: extract these styles since it affects other inputs
ion-input.link-input {
    label {
        border: 2px solid var(--ion-color-base);
        border-radius: 8px !important;
        min-height: 48px !important;

        .label-text-wrapper {
            max-height: 14px;
            font-size: 16px;
        }
    }

    --padding-start: 8px;
    --padding-end: 8px;
    --padding-top: 0px;

    .sc-ionic-input-ios-s > [slot='start']:last-of-type {
        margin-inline-end: 8px;
    }

    .sc-ionic-input-ios-s > [slot='end']:first-of-type {
        margin-inline-start: 8px;
    }
}
</style>
