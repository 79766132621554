<script setup lang="ts">
import { ref, onMounted, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { alertController, IonButton, IonInput, IonItem, IonLabel } from '@ionic/vue';
import { t } from '@/plugins/i18n';
import { useAuthApi } from '../../api/auth.api';
import LButton from '@/core/components/LButton.vue';
import LInput from '@/core/components/LInput.vue';
import LPasswordInput from '../LPasswordInput.vue';
import { CommonRoutes } from '@/router/common.routes';
import { useAuthStore } from '../../store/auth.store';

const code = ref('');
const newPassword = ref('');
const isCodeVerified = ref(false);
const isPasswordValid = ref(false);

const route = useRoute();
const router = useRouter();
const authStore = useAuthStore();
const authApi = useAuthApi();

const props = defineProps<{ email: string }>();
const emit = defineEmits(['prevStep', 'nextStep']);

onMounted(() => {
    const queryCode = route.query.code as string;
    if (queryCode) {
        code.value = queryCode;
    }
});

const verifyCode = async () => {
    try {
        const isValid = await authApi.validateResetCode(props.email, code.value);

        if (isValid) {
            isCodeVerified.value = true;
        } else {
            const errorAlertMessage = {
                header: t('auth.reset.codeErrorTitle'),
                message: t('auth.reset.codeErrorDescription'),
                buttons: [t('auth.reset.codeErrorButton')],
            };
            const alert = await alertController.create(errorAlertMessage);
            await alert.present();
            emit('prevStep');
        }
    } catch (error) {
        console.error('Error verifying code:', error);
    }
};

const handleValidation = (val: boolean) => {
    isPasswordValid.value = val;
};

const updatePassword = async () => {
    const errorAlertMessage = {
        header: t('auth.reset.newPasswordErrorTitle'),
        message: t('auth.reset.newPasswordErrorDescription'),
        buttons: [t('auth.reset.newPasswordErrorButton')],
    };
    const successAlertMessage = {
        header: t('auth.reset.successTitle'),
        message: t('auth.reset.successDescription'),
        buttons: [t('auth.reset.successButton')],
    };

    if (!isPasswordValid.value) {
        const alert = await alertController.create(errorAlertMessage);
        await alert.present();
        return;
    }

    const isSuccessful = await authApi.resetPasswordConfirm(
        props.email,
        newPassword.value,
        code.value,
    );
    const alertMessage = isSuccessful ? successAlertMessage : errorAlertMessage;
    const alert = await alertController.create(alertMessage);
    await alert.present();

    if (!isSuccessful) return;

    authStore
        .login(props.email, newPassword.value)
        .then(() => router.push({ name: CommonRoutes.Home }))
        .catch(console.error);
};
</script>

<template>
    <div v-if="!isCodeVerified">
        <label for="login" class="block caption-3 mb-1">{{ t('auth.reset.code') }}</label>
        <l-input v-model="code" :placeholder="t('auth.reset.codePlaceholder')" class="mb-4" />
        <l-button expand="block" @click="verifyCode">{{ t('auth.reset.getCode') }}</l-button>
    </div>

    <div v-else>
        <l-password-input v-model="newPassword" @validate="handleValidation" />

        <l-button class="mt-6" expand="block" @click="updatePassword" :disabled="!isPasswordValid">
            {{ t('auth.reset.updatePassword') }}
        </l-button>
    </div>
</template>
