<script setup lang="ts">
import LAvatar from '@shared-components/components/LAvatar.vue';
import { onMounted, onBeforeUnmount, computed, ref, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useLearnerStore } from '@/modules/learner/store/learner.store';
import LInput from '@/core/components/LInputSettings.vue';
import { useUserApi } from '@/modules/user/api/user.api';
import { useNotification } from '@/core/composables/use-notification';
import { t } from '@/plugins/i18n';
import InputForm from '@/modules/learner/components/settings/LearnerInputMob.vue';

const userApi = useUserApi();
const learnerStore = useLearnerStore();
const { profile } = storeToRefs(learnerStore);

const notify = useNotification();

const newName = ref(profile.value.user.firstName);
const newLastName = ref(profile.value.user.lastName);

const windowWidth = ref(window.innerWidth);

const updateWindowWidth = () => {
    windowWidth.value = window.innerWidth;
};

onMounted(() => {
    window.addEventListener('resize', updateWindowWidth);
});

onBeforeUnmount(() => {
    window.removeEventListener('resize', updateWindowWidth);
});

const isMobile = computed(() => windowWidth.value <= 992);

const learnerUid = computed(() => profile.value.user.uid);
const error = ref<string>('');
const error2 = ref<string>('');

const showNotification = () => {
    notify.create({
        mode: 'modal',
        duration: 0,
        title: t('learner.verification.successChangeEmailTitle'),
        message: t('learner.verification.successChangeEmail'),
        slotComponent: InputForm,
        slotProps: {},
        closable: true,
        onClose: () => {
            newName.value = profile.value.user.firstName;
            newLastName.value = profile.value.user.lastName;
        },
    });
};

// флаги для отслеживания изменений
const nameChanged = ref(false);
const lastNameChanged = ref(false);

watch(newName, (newVal, oldVal) => {
    nameChanged.value = newVal !== oldVal;
});

watch(newLastName, (newVal, oldVal) => {
    lastNameChanged.value = newVal !== oldVal;
});

const updateNames = async () => {
    if (newName.value === ' ' || newLastName.value === ' ') {
        notify.error({
            mode: 'notification',
            type: 'danger',
            duration: 3000,
            title: t('learner.verification.nameError'),
            message: t('learner.verification.nameEmpty'),
        });
        return;
    }

    // Очистка значений
    newName.value = newName.value.trim();
    newLastName.value = newLastName.value.trim();

    if (!newName.value) {
        newName.value = null;
    }
    if (!newLastName.value) {
        newLastName.value = null;
    }

    // Отправляем запрос, если изменения были
    if (nameChanged.value || lastNameChanged.value) {
        const response = await userApi.patchLearnerData({
            firstName: newName.value,
            lastName: newLastName.value,
        });

        if (!response.error) {
            await learnerStore.fetch();
            newName.value = profile.value.user.firstName;
            newLastName.value = profile.value.user.lastName;
            showSuccessMessage();
        } else {
            error.value = t('auth.reset.newPasswordErrorTitle');
            notify.error({
                mode: 'notification',
                type: 'danger',
                duration: 3000,
                title: t('auth.reset.newPasswordErrorTitle'),
                message: t('auth.reset.newPasswordErrorDescription'),
            });
        }
    }
};

const showSuccessMessage = () => {
    notify.success({
        mode: 'notification',
        type: 'success',
        duration: 2000,
        title: t('learner.verification.successChangeNameTitle'),
        message: t('learner.verification.successChangeName'),
    });
};

const removeFocusFromAllInputs = () => {
    const inputs = document.querySelectorAll('input, textarea, select');
    inputs.forEach((input) => {
        input.blur();
    });
};

const onNameBlur = () => {
    if (!window.matchMedia('(max-width: 992px)').matches) {
        if (nameChanged.value) {
            updateNames();
        }
    }
};

const onLastNameBlur = () => {
    if (!window.matchMedia('(max-width: 992px)').matches) {
        if (lastNameChanged.value) {
            updateNames();
        }
    }
};

const onNameClick = () => {
    if (window.matchMedia('(max-width: 992px)').matches) {
        showNotification();
    }
};

const onLastNameClick = () => {
    if (window.matchMedia('(max-width: 992px)').matches) {
        showNotification();
    }
};

const onIconClick = async () => {
    removeFocusFromAllInputs();
    if (!window.matchMedia('(max-width: 992px)').matches) {
        await updateNames();
    } else {
        showNotification();
    }
};
</script>

<template>
    <div class="flex items-center cursor-pointer justify-normal gap-6">
        <l-avatar
            :alt="newName"
            :uid="learnerUid"
            size="l"
        />
        <div class="flex items-center cursor-pointer md:gap-6 gap-1 flex-col md:flex-row">
            <l-input
                class="settings w-fit"
                :showIcon="true"
                label="Name"
                type="text"
                v-model="newName"
                @blur="onNameBlur"
                @click="onNameClick"
                @iconClick="onIconClick"
                :errorMessage="error"
                :danger="error"
                :disabled="isMobile"
            />
            <l-input
                class="settings w-fit"
                :showIcon="true"
                label="Last Name"
                type="text"
                v-model="newLastName"
                @blur="onLastNameBlur"
                @click="onLastNameClick"
                @iconClick="onIconClick"
                :errorMessage="error2"
                :danger="error2"
                :disabled="isMobile"
            />
        </div>
    </div>
</template>

<style scoped lang="scss">
@use '@/css/ui-kit.scss' as *;
</style>
