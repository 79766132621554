<script setup lang="ts">
import { LearnerLessonStatus } from '../../api/learner.api';
import { IonText, IonIcon } from '@ionic/vue';
import { useLessonStatus } from '../../composables/use-lesson-status';
import { ISODateString } from '@common/types';
import { format } from 'date-fns';
import { computed } from 'vue';
import { t } from '@/plugins/i18n';

type Props = {
    status: LearnerLessonStatus;
    lessonDate: ISODateString;
};
const { status, lessonDate } = defineProps<Props>();
const statusProps = useLessonStatus(status);
const statusText = computed(() => t(statusProps.value.localizationKey));

const statusDate = computed(() => format(lessonDate, 'dd/MM'));
</script>

<template>
    <div class="flex items-center gap-x-1">
        <ion-icon
            :icon="statusProps.icon"
            :color="statusProps.color"
            class="icon-middle"
        />
        <ion-text class="body-2 text-black capitalize">
            {{ statusText }} {{ statusDate }}
        </ion-text>
    </div>
</template>
