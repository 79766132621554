<script setup lang="ts">
import LPage from '@/core/components/LPage.vue';
import LIsland from '@/core/components/LIsland.vue';
import { computed, ref } from 'vue';
import { onIonViewWillEnter, IonCol, IonRow, IonIcon, IonText } from '@ionic/vue';
import { TutorProfile, useLearnerApi } from '../api/learner.api';
import { t } from '@/plugins/i18n';
import { ISODateString } from '@common/types';
import { LessonDuration } from '../types';
import { useLocaleDateFormat } from '@/core/utils/use-locale-date-format';
import { closestTo } from 'date-fns';
import { useRoute } from 'vue-router';
import LAvatar from '@shared-components/components/LAvatar.vue';
import LSubjectChip from '@/core/components/LSubjectChip.vue';
import { timeOutline } from 'ionicons/icons';
import LButton from '@/core/components/LButton.vue';
import { LearnerRoutes } from '@/router/learner.routes';
import { useScreenSize } from '@/core/utils/use-screen-size';

const format = useLocaleDateFormat();

const learnerApi = useLearnerApi();

const { isLargeSize } = useScreenSize();

const { tutorUserUid, timeSlots, lessonDuration } = defineProps<{
    tutorUserUid: string;
    timeSlots: ISODateString[];
    lessonDuration: LessonDuration;
}>();

const tutor = ref<TutorProfile | null>(null);
const isLoading = ref(false);

const lessonDurationReadable = computed(() => (lessonDuration === 60 ? 50 : 25));

const fetchData = async () => {
    if (!tutorUserUid) return;

    try {
        isLoading.value = true;

        tutor.value = await learnerApi.getTutor(tutorUserUid);
    } catch (e) {
        console.error(e);
    } finally {
        isLoading.value = false;
    }
};

const closestLesson = computed(() => closestTo(Date.now(), timeSlots));

onIonViewWillEnter(fetchData);
</script>

<template>
    <l-page class="page-container">
        <ion-row>
            <ion-col
                size="12"
                size-lg="7"
            >
                <!-- TODO: add skeleton -->
                <l-island v-if="tutor">
                    <h5 class="mb-4 mt-4 lg:mt-0">{{ t('learner.payment.success.title') }}</h5>
                    <div class="body-1">
                        {{
                            t('learner.payment.success.description', {
                                tutorName: tutor?.tutorName,
                            })
                        }}
                    </div>
                    <div
                        class="mt-8 mb-4"
                        v-if="closestLesson"
                    >
                        <div class="caption-3 mb-2">
                            {{ t('learner.payment.success.firstLesson') }}
                        </div>
                        <div class="caption-3">
                            {{ format(closestLesson, 'EEEEEE, dd MMMMMM, HH:mm') }}
                        </div>
                    </div>
                    <!-- TODO: use NextLesson component here -->
                    <div class="mb-8">
                        <div class="flex gap-4 items-center mb-4">
                            <l-avatar
                                :uid="tutor?.userUid"
                                size="m"
                            />
                            <div>{{ tutor?.tutorName }}</div>
                        </div>
                        <div class="flex gap-3">
                            <l-subject-chip :code="tutor?.subjectCodes?.[0]" />

                            <div class="flex items-center gap-1">
                                <ion-icon
                                    class="text-2xl"
                                    color="tertiary"
                                    :icon="timeOutline"
                                />
                                <ion-text class="body-2">
                                    {{
                                        t(
                                            'learner.subscriptions.lessonDuration',
                                            lessonDurationReadable,
                                        )
                                    }}
                                </ion-text>
                            </div>
                        </div>
                        <div class="body-1 mt-8">
                            {{ t('learner.payment.success.notifyByEmail') }}
                        </div>
                    </div>
                    <l-button
                        variant="secondary"
                        :expand="isLargeSize ? undefined : 'block'"
                        :router-link="{ name: LearnerRoutes.NextLessons }"
                    >
                        {{ t('learner.payment.success.toLessonsList') }}
                    </l-button>
                </l-island>
            </ion-col>
        </ion-row>
    </l-page>
</template>

<style scoped lang="scss">
@use '@/css/ui-kit' as *;

.page-container {
    --ion-grid-padding: 0;
    --ion-grid-column-padding: 0;
}
</style>
