import { createRouter, createWebHistory } from '@ionic/vue-router';
import { authGuard } from './guards/auth.guard';
import { routes } from './routes';
import { learnerOnboardingGuard } from './guards/learner-onboarding.guard';
import { tutorOnboardingGuard } from './guards/tutor-onboarding.guard';
import { roleGuard } from './guards/role-guard';

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes,
});

router.beforeEach(authGuard);
router.beforeEach(roleGuard);
router.beforeEach(learnerOnboardingGuard);
router.beforeEach(tutorOnboardingGuard);

export default router;
