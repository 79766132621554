<script lang="ts" setup>
import { computed, ref, watch } from 'vue';
import { Color } from '@ionic/core';

const props = defineProps<{
    color?: Color;
}>();

const defaultColor = 'base';

const colorRef = computed(() => `var(--ion-color-${props.color || defaultColor})`);
</script>

<template>
    <div class="body-1 container">
        <slot />
    </div>
</template>

<style scoped>
.container {
    margin-bottom: 5px;
    padding: 4px 8px !important;
    border-radius: 8px !important;
    background-color: v-bind(colorRef);
}
</style>
