import Cookies from 'js-cookie';
import { useLearnerStore } from '@/modules/learner/store/learner.store';

export const learnerOnboardingGuard = (to) => {
    const role = Cookies.get('role');
    const learnerStore = useLearnerStore();

    if (role === 'learner') {
        if (to.path.startsWith('/learner/settings')) {
            return true;
        }

        if (learnerStore.isOnboardingForm) {
            if (!to.path.startsWith('/learner/onboarding')) {
                return '/learner/onboarding/survey';
            }
        } else if (learnerStore.isScheduleTrialWait) {
            if (!to.path.includes('learner/onboarding')) {
                return '/learner/onboarding/tutors';
            }
        } else {
            if (to.path.includes('/learner/onboarding')) {
                return '/learner';
            }
        }
    }

    return true;
};
