<script setup lang="ts">
import { computed, onMounted } from 'vue';
import { i18n, t } from '@/plugins/i18n';
import {
    IonList,
    IonItem,
    IonNote,
    IonText,
    IonAvatar,
    IonListHeader,
    useIonRouter,
} from '@ionic/vue';
import { CustomChat, useChatStore } from '../store/chat.store';
import { requestNotificationPermission } from '../utils/chat.utils';
import { storeToRefs } from 'pinia';
import LIsland from '@/core/components/LIsland.vue';
import { getMessageTime } from '../utils/get-message-sended-time';

const ionRouter = useIonRouter();
const chatStore = useChatStore();
const { chats } = storeToRefs(chatStore);

onMounted(async () => {
    await requestNotificationPermission();
});

const yesterdayText = computed(() => `${t('chat.yesterday')}`);
const getLatestMessageTime = (chat: CustomChat) => {
    const messageDate = chat.latestMessageDate?.toString();
    if (!messageDate) return null;
    return getMessageTime(new Date(messageDate), yesterdayText.value, i18n.global.locale.value);
};

const toChat = (chatUid: string) => ionRouter.push(`chats/${chatUid}`);
</script>

<template>
    <l-island>
        <!-- NOTE: negative margin compensation to show full width items on mobile -->
        <ion-list class="xs:-m-4 lg:m-4">
            <ion-list-header>
                <h3 class="font-bold my-4 lg:mt-0">{{ t('chat.chats') }}</h3>
            </ion-list-header>

            <!-- // TODO: extract to a separate component and add loading skeletons -->
            <ion-item
                v-for="chat in chats"
                class="chat my-2 cursor-pointer"
                lines="full"
                color="white"
                aria-hidden="true"
                @click="toChat(chat.uid)"
            >
                <div class="flex w-full h-full flex-row items-center gap-x-2">
                    <ion-avatar
                        slot="start"
                        class="chat-avatar"
                    >
                        <img
                            :src="chat.chatAvatar"
                            onerror="this.src = '/icons/no-avatar.png'"
                        />
                    </ion-avatar>

                    <div class="flex w-full h-full flex-col justify-center gap-y-2 gap-x-2">
                        <ion-text class="font-semibold">
                            {{ chat.chatName }}
                        </ion-text>
                        <ion-text class="last-message-text body-2 max-w-96">
                            {{ chat.latestMessageContent }}
                        </ion-text>
                    </div>
                </div>

                <div
                    slot="end"
                    class="flex h-full flex-col items-end justify-center gap-y-2"
                >
                    <ion-note class="last-message-time">
                        {{ getLatestMessageTime(chat) }}
                    </ion-note>
                    <ion-note
                        v-if="chat.unreadCount"
                        class="unread-count"
                    >
                        {{ chat.unreadCount }}
                    </ion-note>
                </div>
            </ion-item>
        </ion-list>
    </l-island>
</template>

<style scoped lang="scss">
@use '@/css/ui-kit.scss' as *;

ion-list {
    --ion-item-background: $grey-100;
}

ion-item {
    --ion-color-shade: #{$grey-400};
    --inner-border-width: 0 0 1px 0;
    --inner-padding-bottom: 0.5rem;
    --inner-padding-top: 0.5rem;
    --inner-padding-start: 0rem;
    --inner-padding-end: 1rem;
}

.unread-count {
    background-color: $purple-primary;
    border-radius: 2rem;
    color: $grey-100;
    padding: 1px 5px;
    font-size: 0.8rem;
}

.chat {
    &::part(native) {
        background-color: $grey-100;
    }
}

.chat-avatar {
    min-height: 4rem;
    min-width: 4rem;
}

.last-message-time {
    font-size: 0.8rem;
    color: $grey-600;
}

.last-message-text {
    --last-date-width: 100px;

    color: $grey-600;
    display: block;
    width: calc(100% - var(--last-date-width));
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>
