<script setup lang="ts">
import LPage from '@/core/components/LPage.vue';
import { computed, ref, watch } from 'vue';
import { onIonViewWillEnter } from '@ionic/vue';
import {
    AvaliableSlot,
    GetAvaliableSlotsRecurrentParams,
    TutorProfile,
    useLearnerApi,
} from '../api/learner.api';

import LScheduleRecurrentTime from '../components/payment/LScheduleRecurrentTime.vue';
import { ISODateString } from '@common/types';
import { LessonDuration } from '../types';

const { tutorUserUid } = defineProps<{ tutorUserUid: string }>();

const learnerApi = useLearnerApi();

const isLoadingProfile = ref(false);
const isLoadingTimeSlots = ref(false);

const selectedTimeSlots = ref<ISODateString[]>([]);

const tutorProfile = ref<TutorProfile>();
const availableSlots = ref<AvaliableSlot[]>([]);

const selectedLessonDuration = ref<LessonDuration>(60);

watch(
    () => selectedLessonDuration.value,
    (duration) => {
        selectedTimeSlots.value = [];
        fetchAvailableSlots({
            slotDuration: duration,
            tutorUserUid,
        });
    },
);

const fetchAvailableSlots = async (params: GetAvaliableSlotsRecurrentParams) => {
    try {
        isLoadingTimeSlots.value = true;
        const response = await learnerApi.getTutorAvailableSlotsRecurrent(params);
        if (response.success) {
            availableSlots.value = response.data!;
        }
    } catch (e) {
        console.error(e);
    } finally {
        isLoadingTimeSlots.value = false;
    }
};

const fetchTutorProfile = async () => {
    try {
        isLoadingProfile.value = true;
        const response = await learnerApi.getTutor(tutorUserUid);
        tutorProfile.value = response;
    } catch (e) {
        console.error(e);
    } finally {
        isLoadingProfile.value = false;
    }
};

onIonViewWillEnter(async () => {
    try {
        await Promise.all([
            fetchTutorProfile(),
            fetchAvailableSlots({
                slotDuration: selectedLessonDuration.value,
                tutorUserUid,
            }),
        ]);
    } catch (e) {
        console.error(e);
    }
});

const handleLessonDurationChange = (value: LessonDuration) =>
    (selectedLessonDuration.value = value);

const handleSelectTimeSlot = (value: ISODateString) => {
    if (selectedTimeSlots.value.includes(value)) {
        selectedTimeSlots.value = selectedTimeSlots.value.filter((ts) => ts !== value);
    } else {
        selectedTimeSlots.value.push(value);
    }
};
</script>

<template>
    <l-page class="page-container">
        <l-schedule-recurrent-time
            :tutor="tutorProfile!"
            :availableSlots="availableSlots"
            :isLoadingProfile="isLoadingProfile || !tutorProfile"
            :isLoadingTimeSlots="isLoadingTimeSlots || !tutorProfile"
            :selectedTimeSlots="selectedTimeSlots"
            :selectedLessonDuration="selectedLessonDuration"
            @duration-change="handleLessonDurationChange"
            @select-time-slot="handleSelectTimeSlot"
        />
    </l-page>
</template>

<style scoped lang="scss">
@use '@/css/ui-kit' as *;

.page-container {
    --ion-grid-padding: 0;
    --ion-grid-column-padding: 0;
}
</style>
