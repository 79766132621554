<script setup lang="ts"></script>

<template>
    <section class="l-island">
        <header
            class="title"
            v-if="$slots.title"
        >
            <slot name="title"></slot>
        </header>
        <slot></slot>
    </section>
</template>

<style scoped lang="scss">
@use '@/css/ui-kit.scss' as *;

.l-island {
    border-radius: 0;
    padding: 0;
    width: 100%;
    background-color: $grey-100;

    .title {
        padding-bottom: 2rem;
    }
}

@media screen and (min-width: $breakpoint-lg) {
    .l-island {
        border-radius: 1rem;
        padding: 1rem;
    }
}
</style>
