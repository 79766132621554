<script lang="ts" setup>
import { IonSelectOption, IonSelect, IonButton, IonIcon } from '@ionic/vue';
import { chevronDown, closeCircle } from 'ionicons/icons';
import LButton from './LButton.vue';
const props = defineProps<{
    modelValue?: string | string[] | null;
    options: Array<{ label: string; value: string }>;
    placeholder?: string;
    ariaLabel?: string;
    multiple?: boolean;
    color?: string;
    fill?: 'outline' | 'solid';
    startIcon?: string;
    endIcon?: string;
}>();
const emit = defineEmits<{
    (e: 'update:modelValue', value: string | string[] | null): void;
}>();
const defaultPlaceholder = props.placeholder || 'Select an option';
const defaultColor = props.color || 'tertiary';
const handleChange = (event: CustomEvent) => {
    emit('update:modelValue', event.detail.value);
};
</script>
<template>
    <ion-select
        :color="defaultColor"
        :toggle-icon="chevronDown"
        :placeholder="defaultPlaceholder"
        :aria-label="props.ariaLabel || 'Select'"
        :multiple="props.multiple || false"
        :value="props.modelValue"
        @ionChange="handleChange"
        class="link-select"
    >
        <ion-icon
            v-if="props.startIcon"
            slot="start"
            :icon="props.startIcon"
            aria-hidden="true"
        ></ion-icon>
        <ion-select-option
            class="castom-select-option"
            v-for="option in props.options"
            :key="option.value"
            :value="option.value"
        >
            {{ option.label }}
        </ion-select-option>

        <l-button
            v-if="props.endIcon"
            variant="text"
            slot="end"
        >
            <ion-icon
                slot="icon-only"
                color="dark"
                :icon="props.endIcon"
                aria-hidden="true"
            ></ion-icon>
        </l-button>
    </ion-select>
</template>

<style scoped lang="scss">
ion-select.link-select {
    border: 2px solid var(--ion-color-base);
    border-radius: 8px !important;
    --padding-start: 16px;
    --padding-end: 16px;
}
</style>
