<script lang="ts">
export type LLinksArrayItemProps = {
    beforeIcon?: string;
    text?: string;
    afterIcon?: string;
    to?: RouteLocationRaw;
    selected?: boolean;
    clickable?: boolean;
};
</script>

<script setup lang="ts">
import { RouteLocationRaw } from 'vue-router';

const props = defineProps<LLinksArrayItemProps>();
const emit = defineEmits<{ click: [event: MouseEvent] }>();
const slots = defineSlots();
</script>

<template>
    <component
        :is="props.to ? 'router-link' : 'div'"
        :to="props.to"
        :class="[
            'l-links-array-item',
            { selected: props.selected, 'cursor-pointer': props.clickable },
        ]"
        @click="emit('click', $event)"
    >
        <div class="flex items-center gap-2 caption-3">
            <ion-icon
                v-if="props.beforeIcon"
                class="icon"
                :icon="props.beforeIcon"
            />
            <slot>
                <div class="flex flex-grow">{{ props.text }}</div>
            </slot>
            <ion-icon
                v-if="props.afterIcon"
                class="icon icon-after justify-self-end"
                :icon="props.afterIcon"
            />
        </div>
    </component>
</template>

<style scoped lang="scss">
@use '@/css/ui-kit' as *;

.l-links-array-item {
    width: 100%;
    padding: 0.625rem 1rem;

    border-bottom: 2px solid $grey-400;
    background-color: $grey-100;
    min-height: 2.875rem;

    &:hover,
    &.selected {
        background-color: $grey-300;
    }

    &:first-child {
        border-top-right-radius: $default-border-radius;
        border-top-left-radius: $default-border-radius;
    }

    &:last-child {
        border-bottom-right-radius: $default-border-radius;
        border-bottom-left-radius: $default-border-radius;
        min-height: 2.75rem;
        border-bottom: none;
    }
    .icon {
        font-size: 1.5rem;

        &-after {
            color: $grey-500;
        }
    }
}
</style>
