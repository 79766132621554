<script lang="ts" setup>
import { IonInput } from '@ionic/vue';
import { Color } from '@ionic/core';
import { pencilOutline, checkmarkOutline } from 'ionicons/icons';
import { ref, computed, watch } from 'vue';

const props = defineProps<{
    modelValue?: string | number | null;
    type?: 'text' | 'number' | 'password' | 'email' | 'tel' | 'url';
    placeholder?: string;
    color?: Color;
    disabled?: boolean;
    clearInput?: boolean;
    debounce?: number;
    label?: string;
    showIcon?: boolean;
    errorMessage?: string;
}>();

const emit = defineEmits<{
    (e: 'update:modelValue', value: string | number | null): void;
    (e: 'input', value: InputEvent): void;
    (e: 'blur', value: FocusEvent): void;
    (e: 'iconClick'): void;
}>();

const isFocused = ref(false);

const localErrorMessage = ref(props.errorMessage || '');

watch(
    () => props.errorMessage,
    (newVal) => {
        localErrorMessage.value = newVal || '';
    },
);

const handleInput = (event: CustomEvent) => {
    const target = event.target as HTMLIonInputElement;

    if (localErrorMessage.value) {
        localErrorMessage.value = '';
    }

    emit('update:modelValue', target.value ?? '');
    emit('input', event as any);
};

const handleFocus = () => {
    isFocused.value = true;
};

const handleBlur = (event: FocusEvent) => {
    isFocused.value = false;
    emit('blur', event);
};

const handleIconClick = () => {
    emit('iconClick');
};

const inputColor = computed<Color>(() => {
    return localErrorMessage.value ? 'danger' : (props.color ?? 'tertiary');
});
</script>

<template>
    <div>
        <ion-input
            mode="ios"
            class="custom"
            :type="props.type"
            :placeholder="props.placeholder"
            :disabled="props.disabled"
            :clear-input="props.clearInput"
            :debounce="props.debounce"
            :value="props.modelValue"
            :color="inputColor"
            :label-placement="props.label ? 'floating' : undefined"
            @ionInput="handleInput"
            @ion-focus="handleFocus"
            @ion-blur="handleBlur"
        >
            <ion-icon
                v-if="props.showIcon"
                slot="end"
                class="text-xl cursor-pointer"
                color="dark"
                @mousedown.stop.prevent="handleIconClick"
                :icon="isFocused ? checkmarkOutline : pencilOutline"
            />
        </ion-input>
        <p
            v-if="localErrorMessage"
            class="error-message"
        >
            {{ localErrorMessage }}
        </p>
    </div>
</template>

<style lang="scss" scoped>
ion-input.custom {
    &.input-disabled {
        opacity: 1 !important;
    }

    label {
        border: 0 transparent !important;
        --border: 0 !important;
        --border-radius: 8px !important;
        --min-height: 48px !important;

        .label-text-wrapper {
            max-height: 14px;
            font-size: 16px;
        }
    }

    --padding-start: 8px;
    --padding-end: 8px;
    --padding-top: 0px;

    .sc-ionic-input-ios-s > [slot='start']:last-of-type {
        margin-inline-end: 8px;
    }

    .sc-ionic-input-ios-s > [slot='end']:first-of-type {
        margin-inline-start: 8px;
    }
}

/* Стили для текста ошибки */
.error-message {
    margin: 4px 0;
    margin-left: 4px;
    font-size: 14px;
    background: #ffdcd9;
    width: fit-content;
    height: 28px;
    padding: 5px;
    border-radius: 6px;
}
</style>
