<script setup lang="ts">
import { calendar, ellipsisHorizontal, trash } from 'ionicons/icons';
import { IonPopover, IonIcon } from '@ionic/vue';
import LButton from '@/core/components/LButton.vue';
import { LessonEvent } from '../../api/learner.api';
import { ref } from 'vue';
import { t } from '@/plugins/i18n';

interface Props {
    lesson: LessonEvent;
}
const { lesson } = defineProps<Props>();

// NOTE: manual popover control to avoid id collisions on ion-page animation
const isPopoverOpen = ref(false);
const event = ref<MouseEvent>();
const onTriggerClick = (e: MouseEvent) => {
    event.value = e;
    isPopoverOpen.value = !isPopoverOpen.value;
};

const onCancelLesson = () => {
    // TODO: implement canceling a lesson
    console.log('Cancel event clicked');
};

const onMoveLesson = () => {
    // TODO: implement moving a lesson
    console.log('Transfer event clicked');
};
</script>

<template>
    <ion-popover
        @didDismiss="isPopoverOpen = false"
        :is-open="isPopoverOpen"
        :event="event"
        :arrow="false"
        alignment="center"
        side="bottom"
        class="popover"
    >
        <!-- TODO: possibly extract this button into a separate component to reuse in popovers like this -->
        <l-button
            :href="`learner/chats/${lesson.tutorUserUid}`"
            variant="text"
            expand="full"
            size="small"
            strong
        >
            {{ t('learner.home.moveLesson') }}
            <ion-icon
                :icon="calendar"
                slot="end"
                color="dark"
            />
        </l-button>

        <!-- TODO: disabled because the cancelation will be available on BE in future -->
        <div class="separator" />
        <l-button
            @click="onCancelLesson"
            variant="text"
            color="danger"
            expand="full"
            size="small"
            strong
            disabled
        >
            {{ t('learner.home.cancelLesson') }}
            <ion-icon
                :icon="trash"
                slot="end"
                color="danger"
            />
        </l-button>
    </ion-popover>

    <l-button
        @click="onTriggerClick($event)"
        variant="text"
        size="small"
    >
        <ion-icon
            :icon="ellipsisHorizontal"
            color="dark"
        />
    </l-button>
</template>

<style lang="scss" scoped>
@use '@/css/ui-kit.scss' as *;

.popover::part(content) {
    border: 2px solid black;
}

.separator {
    height: 1px;
    background-color: $grey-400;
}
</style>
