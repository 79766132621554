<script setup lang="ts">
import { computed } from 'vue';
import { RouterLink } from 'vue-router';

/**
 * This component extends the RouterLink component to add the ability to open links in a new tab.
 *
 * @example
 * <RouterLinkExtended to="https://example.com" activeClass="active" inactiveClass="inactive">
 *   Go to example.com
 * </RouterLinkExtended>
 *
 * @link https://router.vuejs.org/guide/advanced/extending-router-link.html
 */
defineOptions({
    inheritAttrs: false,
});

const props = defineProps({
    // @ts-ignore docs recommends to ignore this
    ...RouterLink.props,
    inactiveClass: String,
});

const isExternalLink = computed(() => {
    return typeof props.to === 'string' && props.to.startsWith('http');
});
</script>

<template>
    <a
        v-if="isExternalLink"
        v-bind="$attrs"
        :href="props.to"
        target="_blank"
    >
        <slot />
    </a>

    <!-- @vue-expect-error We could type this with some magic, but it would probably be complex to do -->
    <router-link
        v-else
        v-bind="$props"
        v-slot="{ isActive, href, navigate }"
        custom
    >
        <a
            v-bind="$attrs"
            :href="href"
            @click="navigate"
            :class="isActive ? props.activeClass : props.inactiveClass"
        >
            <slot />
        </a>
    </router-link>
</template>
