<script setup lang="ts">
import { useAuthStore } from '@/modules/auth/store/auth.store';
import { CustomChatMessage, useChatStore } from '../store/chat.store';
import { alertCircleOutline, checkmarkDoneOutline, checkmarkOutline } from 'ionicons/icons';
import { t, i18n } from '@/plugins/i18n';
import { computed, useTemplateRef } from 'vue';
import { IonIcon, IonNote } from '@ionic/vue';
import { defineProps, defineEmits } from 'vue';
import { getMessageTime } from '../utils/get-message-sended-time';

// TODO: bad extracted code from LChat, please refactor
const { message, selectedMessage } = defineProps<{
    message: CustomChatMessage;
    selectedMessage: CustomChatMessage | null;
}>();

const emit = defineEmits<{
    click: [event: MouseEvent];
}>();

const messageRef = useTemplateRef('messageRef');
defineExpose({ messageRef });

const chatStore = useChatStore();
const authStore = useAuthStore();

const getMessageClassBySender = (message: CustomChatMessage) => {
    if (message.senderUid === authStore.user.uid) {
        return 'message-right';
    } else {
        return 'message-left';
    }
};

const getCheckmarkIconByMessage = (message: CustomChatMessage) => {
    if (message.isSendFailed) {
        return alertCircleOutline;
    }
    if (message.uid <= (chatStore.activeChat?.otherParticipant.latestReadMessageUid as string)) {
        return checkmarkDoneOutline;
    } else if (message.isMessageReceivedByServer) {
        return checkmarkOutline;
    } else {
        return null;
    }
};

const formatMessage = (message: string) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g; // Замена URL на гиперссылки
    return message.replace(urlRegex, '<a href="$1" class="underline" target="_blank">$1</a>');
};

const messageDate = new Date(message.createdAt?.toString());
const yesterdayText = computed(() => `${t('chat.yesterday')}`);
const messageSendedTime = getMessageTime(
    messageDate,
    yesterdayText.value,
    i18n.global.locale.value,
);

const messageClasses = computed(() => [
    'message',
    getMessageClassBySender(message),
    {
        'unread-message':
            message.uid > (chatStore.activeChat?.latestReadMessageUid as string) &&
            message.senderUid !== authStore.user.uid,
        selected: message.uid === selectedMessage?.uid,
    },
]);

const handleMessageClick = (event: MouseEvent) => {
    emit('click', event);
};

const messageStatusIcon = computed(() => getCheckmarkIconByMessage(message));
</script>

<template>
    <div
        @click="handleMessageClick"
        :class="messageClasses"
        :key="message.uid"
        :uid="message.uid"
        ref="messageRef"
    >
        <div
            class="message-content"
            v-html="formatMessage(message.content)"
        />
        <br />
        <div class="message-date">
            <ion-note class="caption-6">
                {{ messageSendedTime }}
            </ion-note>
            <ion-icon
                v-if="messageStatusIcon"
                :class="['checkmark']"
                :icon="messageStatusIcon"
                :color="message.isSendFailed ? 'danger' : ''"
            />
        </div>
    </div>
</template>

<style lang="scss" scoped>
@use '@/css/ui-kit.scss' as *;

.message-container {
    display: flex;
    flex-direction: column-reverse;
    overflow-y: auto;
    height: 100%;
    background-color: $grey-100;
}

.message {
    max-width: 80%;
    margin: 10px 25px;
    padding: 15px;
    border-radius: $default-border-radius;

    .message-content {
        display: inline-block;
        white-space: pre-wrap;
        word-wrap: break-word;
        overflow-wrap: break-word;
        word-break: break-word;
    }

    &.selected {
        opacity: 0.5;
    }
}

.message-left {
    align-self: flex-start;
    background-color: $grey-300;
    border-bottom-left-radius: 0;

    .message-date {
        text-align: left;

        .checkmark {
            display: none;
        }
    }
}

.message-right {
    align-self: flex-end;
    background-color: $blue-light;
    border-bottom-right-radius: 0;

    .message-date {
        text-align: right;

        .checkmark {
            margin-left: 7px;
            width: 14px;
            height: 14px;
        }

        .message-read {
            color: $purple-primary;
        }
    }
}

.message-date {
    display: flex;
    align-items: center;
    text-align: right;
    justify-content: flex-end;
}
</style>
