<script setup lang="ts">
import { ref } from 'vue';
import LInput from '@/core/components/LInput.vue';
import { useNotification } from '@/core/composables/use-notification';
import { useUserApi } from '@/modules/user/api/user.api';
import { useLearnerStore } from '@/modules/learner/store/learner.store';
import { storeToRefs } from 'pinia';
import { t } from '@/plugins/i18n';
import LButton from '@/core/components/LButton.vue';
import { IonText } from '@ionic/vue';
import { emailRegExp } from '@common/utils/email-regexp';

// Инициализация API и хранилища
const userApi = useUserApi();
const learnerStore = useLearnerStore();
const { profile } = storeToRefs(learnerStore);
const notify = useNotification();

// Состояние и ошибки
const learnerEmail = ref(profile.value.user.email);
const newEmail = ref(learnerEmail.value);
const error = ref<string>('');
const isEmailValidationFailed = ref(false);

// Валидация email
const validateEmail = (emailValue: string) => {
    const result = emailValue.match(emailRegExp);
    isEmailValidationFailed.value = !result;
    return result;
};

// Обновление почты
const changeEmail = async () => {
    const response = await userApi.patchLearnerData({ email: newEmail.value });
    if (!response.error) {
        await learnerStore.fetch();
        learnerEmail.value = learnerStore.profile.user.email;
    } else {
        error.value = t('auth.reset.emailErrorTitle');
        isEmailValidationFailed.value = true;
    }
};

// Показать сообщение об успехе
const showSuccessMessage = () => {
    notify.success({
        mode: 'notification',
        type: 'success',
        duration: 2000,
        title: t('learner.verification.successChangeEmailTitle'),
        message: t('learner.verification.successChangeEmail'),
    });
};

// Обработчик клика по иконке (для обновления почты)
const onIconClick = async () => {
    // Убираем присваивание: newEmail.value = learnerEmail.value;

    if (validateEmail(newEmail.value)) {
        await changeEmail();

        if (!isEmailValidationFailed.value) {
            showSuccessMessage();
        }
    } else {
        error.value = t('learner.verification.emailError');
    }
};
</script>

<template>
    <div class="flex flex-col gap-2 p-2">
        <ion-text class="font-bold text-base"> {{ t('learner.settings.email') }}</ion-text>
        <l-input
            class="settings w-full"
            :showIcon="true"
            label="Email"
            type="email"
            v-model="newEmail"
        />
        <!-- Блок с ошибкой для почты -->
        <div
            v-if="error"
            class="error-message text-red-500 text-sm mt-1"
        >
            {{ error }}
        </div>

        <l-button
            @click="onIconClick"
            expand="block"
        >
            {{ t('learner.verification.successButton') }}
        </l-button>
    </div>
</template>

<style scoped lang="scss">
@use '@/css/ui-kit.scss' as *;
.error-message {
    margin: 4px 0;
    margin-left: 4px;
    font-size: 14px;
    background: #ffdcd9;
    width: fit-content;
    height: 28px;
    padding: 5px;
    border-radius: 6px;
}
</style>
