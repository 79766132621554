<script setup lang="ts">
import { computed, onMounted } from 'vue';
import { Maybe } from '@common/types';
import { useCalendarStore } from '../../store/calendar.store';
import { storeToRefs } from 'pinia';
import { t } from '@/plugins/i18n';
import { LessonScheduleType } from '../../types';
import { filterLessonsByListType } from '../../utils';
import LSkeletonLessonList from './LSkeletonLessonList.vue';
import LLessonItem from './LLessonItem.vue';
import LEmptyLessonList from './LEmptyLessonList.vue';
import LLinkShowAll from './LLinkShowAll.vue';

type Props = {
    listType: LessonScheduleType;
    listLength?: number;
};
const { listLength = 2, listType } = defineProps<Props>();

const calendarStore = useCalendarStore();
const { pastLessons, nextLessons, loading } = storeToRefs(calendarStore);

// TODO: extract logic to a composable
const isNext = computed(() => listType === LessonScheduleType.Next);
const lessons = computed(() => (isNext.value ? nextLessons?.value : pastLessons?.value));
const lessonList = computed(() => lessons.value.slice(0, listLength));
const isListEmpty = computed(() => !loading.value && lessonList.value.length === 0);
const showAllPath = computed(() => `/learner/${isNext.value ? 'calendar' : 'history'}` as const);

const listTitle = computed(() => t(`learner.home.${isNext.value ? 'nextLessons' : 'pastLessons'}`));
const showAllLessonsText = computed(() => t('learner.home.showAll'));
</script>

<template>
    <h5 class="pb-4">{{ listTitle }}</h5>

    <l-skeleton-lesson-list
        v-if="loading"
        :length="2"
    />

    <transition-group
        name="slide-fade"
        tag="div"
    >
        <l-lesson-item
            v-if="!loading"
            v-for="lesson in lessonList"
            :key="lesson.uid"
            :lesson="lesson"
            :lessonType="listType"
        />
    </transition-group>

    <l-empty-lesson-list
        v-if="isListEmpty"
        :listType="listType"
    />

    <l-link-show-all
        v-if="!isListEmpty"
        :to="showAllPath"
        :showAllText="showAllLessonsText"
    />
</template>

<style scoped>
/* TODO: extract animation to reusable styles */
.slide-fade-enter-active {
    transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
    transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
    transform: translateX(20px);
    opacity: 0;
}
</style>
