<script lang="ts" setup>
import { computed, ref, watch } from 'vue';

const props = defineProps<{
	size?: 'xs' | 's' | 'm' | 'l' | 'xl';
	src?: string | null;
	alt?: string | null;
	uid?: string;
}>();

const emit = defineEmits<{
	(e: 'click', event: MouseEvent): void;
}>();

const altInitial = computed(() => props.alt?.charAt(0).toUpperCase() || '?');
const source = computed(() => {
	if (props.src) {
		return props.src;
	}
	if (props.uid) {
		return `https://content.dev1.learnlink.team/platform/avatars/${props.uid}_256.webp`;
	}
	return null;
});

const imageError = ref(!Boolean(source.value));

watch(source, () => (imageError.value = false));

const handleError = () => {
	imageError.value = true;
};

const handleClick = (e: MouseEvent) => {
	emit('click', e);
};

const avatarClasses = computed(() => ({
	avatar: true,
	[props.size || 'm']: true,
	'no-avatar': imageError.value,
}));
</script>

<template>
	<div
		:class="avatarClasses"
		@click="handleClick"
	>
		<template v-if="!imageError">
			<img :src="source" :alt="altInitial" @error="handleError" />
		</template>
		<template v-else>
			<span class="alt-initial">{{ altInitial }}</span>
		</template>
	</div>
</template>

<style scoped>
.avatar {
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	overflow: hidden;
	position: relative;
	background-color: #ffffff;
	border: 2px solid #f4f2f7;
}

.no-avatar {
	background-color: #dac7ff;
	border-color: white;
	color: #b28cff;
	font-weight: bold;
	text-align: center;
}

.alt-initial {
	font-size: 0.8em;
}

img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.xs {
	width: 24px;
	height: 24px;

	.alt-initial {
		font-size: 16px;
	}
}

.s {
	width: 40px;
	height: 40px;

	.alt-initial {
		font-size: 20px;
	}
}

.m {
	width: 48px;
	height: 48px;

	.alt-initial {
		font-size: 20px;
	}
}

.l {
	width: 64px;
	height: 64px;

	.alt-initial {
		font-size: 34px;
	}
}

.xl {
	width: 96px;
	height: 96px;

	.alt-initial {
		font-size: 56px;
	}
}
</style>
