<script setup lang="ts">
import LChat from '@/modules/chat/components/LChat.vue';
import { IonPage } from '@ionic/vue';
import { computed, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { Maybe } from '@common/types';
import { t } from '@/plugins/i18n';
import { ChatOpeningReason } from '../types';

const route = useRoute();

const reason = ref<Maybe<ChatOpeningReason>>(null);
const meta = ref<Maybe<Record<string, string>>>(null);

watch(
    () => route.query,
    (q) => {
        reason.value = q?.reason as ChatOpeningReason;
        meta.value = JSON.parse((q?.meta as string) || '{}');
    },
    { immediate: true },
);

const initialMessage = computed(() => {
    switch (reason.value) {
        case ChatOpeningReason.ChangeTutor:
            return t('chat.initialMessage.changeTutor', { tutorName: meta.value?.tutorName || '' });
        case ChatOpeningReason.ChangeLessonDuration:
            return t('chat.initialMessage.changeLessonDuration', {
                tutorName: meta.value?.tutorName || '',
            });
        case ChatOpeningReason.CancelSubscription:
            return t('chat.initialMessage.cancelSubscription');
        default:
            return undefined;
    }
});
</script>

<template>
    <ion-page class="container">
        <l-chat :initial-message="initialMessage" />
    </ion-page>
</template>

<style scoped lang="scss">
@use '@/css/ui-kit.scss' as *;

.container {
    max-width: var(--breakpoint-xl);
    margin: 0 auto;
    background-color: $grey-100;
}
</style>
