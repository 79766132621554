import { t } from '@/plugins/i18n';
import { checkmarkCircle, stopCircle, arrowForwardCircle } from 'ionicons/icons';
import { computed } from 'vue';
import { LearnerLessonStatus } from '../api/learner.api';
import { Color } from '@ionic/core';

type IonIconString = string;
type LessonStatusOptions = {
    color: Color;
    icon: IonIconString;
    localizationKey: string;
};
// TODO: implement 'in_progress' status
const STATUS_MAP: Record<Exclude<LearnerLessonStatus, 'in_progress'>, LessonStatusOptions> = {
    completed: {
        color: 'success',
        icon: checkmarkCircle,
        localizationKey: 'learner.schedule.lessonCompleted',
    },
    cancelled: {
        color: 'danger',
        icon: stopCircle,
        localizationKey: 'learner.schedule.lessonCancelled',
    },
    scheduled: {
        color: 'warning',
        icon: arrowForwardCircle,
        localizationKey: 'learner.schedule.lessonScheduled',
    },
};

export function useLessonStatus(status: LearnerLessonStatus) {
    const statusProps = computed(() => STATUS_MAP?.[status]);

    return statusProps;
}
