<script setup lang="ts">
import { t } from '@/plugins/i18n';

import LSubjectChip from '@/core/components/LSubjectChip.vue';
import { informationCircleOutline, pencilOutline, timeOutline } from 'ionicons/icons';
import LButton from '@/core/components/LButton.vue';

import { IonText, IonIcon, IonCol, IonRow } from '@ionic/vue';
import { TutorProfile } from '../../api/learner.api';
import LIsland from '@/core/components/LIsland.vue';
import { useScreenSize } from '@/core/utils/use-screen-size';
import LAvatar from '@shared-components/components/LAvatar.vue';
import { computed } from 'vue';
import { getTotalPrice, groupTimeSlots } from '../../utils';
import { useI18n } from 'vue-i18n';
import { daysInWeek } from 'date-fns/constants';
import { LearnerRoutes } from '@/router/learner.routes';
import LScheduleTimeSkeleton from './LScheduleTimeSkeleton.vue';
import { LessonDuration } from '../../types';
import { ISODateString } from '@common/types';

const COMISSION_FEE = 0;
const SUBSCRIPTION_WEEKS = 4;

const { locale } = useI18n();
const { slots, tutor, duration } = defineProps<{
    tutor: TutorProfile;
    slots: ISODateString[];
    duration: LessonDuration;
    isLoading: boolean;
}>();

const { isLargeSize } = useScreenSize();

const lessonDurationReadable = computed(() => (duration === 60 ? 50 : 25));

const lessonsPrice = computed(() => getTotalPrice(tutor, duration, lessonsNumber.value));
const totalPrice = computed(() => lessonsPrice.value + COMISSION_FEE);

const submitPayment = async () => {
    // TODO: implement payment
};

const lessonsNumber = computed(() => slots.length * SUBSCRIPTION_WEEKS);

const groupedTimes = computed(() => groupTimeSlots(slots, locale.value));

const lessonsPerWeekTitle = computed(() =>
    t('learner.payment.checkout.perWeek.title', slots.length, {
        named: {
            times: t(`learner.payment.checkout.perWeek.${slots.length}`),
        },
    }),
);
</script>

<template>
    <ion-row class="wrapper">
        <ion-col
            size-lg="7"
            size="12"
        >
            <l-island>
                <l-schedule-time-skeleton
                    type="profile"
                    v-if="isLoading"
                />
                <template v-else>
                    <h5 class="pb-8 mt-4">{{ t('learner.payment.checkout.title') }}</h5>
                    <div class="flex items-center mb-8">
                        <l-avatar
                            size="xl"
                            :src="tutor?.tutorAvatar"
                            :uid="tutor?.userUid"
                            :alt="tutor?.tutorName"
                        />
                        <p class="caption-2 ml-4">
                            {{ tutor?.tutorName }}
                        </p>
                    </div>

                    <h5
                        class="mb-4 mt-8"
                        v-if="!isLargeSize"
                    >
                        {{ lessonsPerWeekTitle }}
                    </h5>

                    <div class="flex gap-2 items-center justify-start mb-2">
                        <l-subject-chip
                            v-for="code of tutor?.subjectCodes"
                            :code="code"
                        />

                        <div class="flex items-center gap-1">
                            <ion-icon
                                class="text-2xl"
                                color="tertiary"
                                :icon="timeOutline"
                            />
                            <ion-text class="body-2">
                                {{
                                    t(
                                        'learner.subscriptions.lessonDuration',
                                        lessonDurationReadable,
                                    )
                                }}
                            </ion-text>
                        </div>
                    </div>

                    <div
                        class="mb-1 caption-3"
                        v-if="isLargeSize"
                    >
                        <div class="flex items-center justify-between gap-1">
                            <strong>{{ lessonsPerWeekTitle }}:</strong>
                            <l-button
                                variant="text"
                                :router-link="{
                                    name: LearnerRoutes.ScheduleRecurrentTime,
                                    params: { tutorUserUid: tutor.userUid },
                                }"
                            >
                                <ion-icon
                                    class="text-2xl"
                                    :icon="pencilOutline"
                                    slot="icon-only"
                                ></ion-icon>
                            </l-button>
                        </div>
                    </div>

                    <div class="body-2 capitalize">{{ groupedTimes }}</div>
                    <div class="body-2">
                        <div class="flex items-center justify-between gap-1">
                            {{
                                t(
                                    'learner.payment.checkout.period',
                                    daysInWeek * SUBSCRIPTION_WEEKS,
                                )
                            }}

                            <l-button variant="text">
                                <ion-icon
                                    :icon="informationCircleOutline"
                                    slot="icon-only"
                                    class="text-2xl"
                                ></ion-icon>
                            </l-button>
                        </div>
                    </div>

                    <div class="booking-details mt-8">
                        <div class="caption-2">
                            {{ t('learner.payment.checkout.bookingDetails') }}
                        </div>
                        <div class="flex flex-col gap-y-2 mt-4">
                            <div class="body-1 flex">
                                <div class="text-left w-9/12">
                                    {{ t('learner.payment.checkout.lessonsNumber', lessonsNumber) }}
                                </div>
                                <div class="text-right w-3/12">{{ lessonsPrice.toFixed(2) }}$</div>
                            </div>
                            <!-- TODO: after mvp -->
                            <!-- <div class="body-1 flex">
                                <div class="text-left w-9/12">
                                    {{ t('learner.payment.checkout.commission') }}
                                </div>
                                <div class="text-right w-3/12">
                                    {{ COMISSION_FEE.toFixed(2) }} $
                                </div>
                            </div> -->
                            <!-- TODO: after mvp -->
                            <!-- <div class="body-1 flex">
                            <div class="text-left w-9/12">
                                {{ t('learner.onboarding.trialPreview.discount') }}
                            </div>
                            <div class="text-right w-3/12">100%</div>
                        </div> -->
                            <div class="caption-2 flex">
                                <div class="text-left w-9/12">
                                    {{ t('learner.payment.checkout.total') }}
                                </div>
                                <div class="text-right w-3/12">{{ totalPrice.toFixed(2) }}$</div>
                            </div>
                        </div>
                    </div>
                </template>
            </l-island>
        </ion-col>

        <ion-col
            size-lg="5"
            size="12"
        >
            <l-island>
                <div :class="{ 'mt-8': !isLargeSize }">
                    <p class="caption-2 text-center ion-hide-lg-down">
                        {{ t('learner.payment.checkout.confirmationTitle') }}
                    </p>
                    <p class="body-1 mt-4 text-center mb-8 ion-hide-lg-down">
                        {{ t('learner.payment.checkout.confirmationDesc') }}
                    </p>
                    <!-- TODO: implement submit payment -->
                    <l-button
                        expand="block"
                        @click="submitPayment"
                        :disabled="isLoading"
                        :router-link="{
                            name: LearnerRoutes.PaymentSuccess,
                            query: {
                                tutorUserUid: tutor?.userUid,
                                timeSlots: slots,
                                lessonDuration: duration,
                            },
                        }"
                    >
                        {{ t('learner.payment.checkout.submit') }}
                    </l-button>
                    <p class="body-3 agreement-text ion-hide-lg-down">
                        {{ t('learner.payment.checkout.agreement') }}
                    </p>
                </div>
            </l-island>
        </ion-col>
    </ion-row>
</template>

<style lang="scss" scoped>
@use '@/css/ui-kit.scss' as *;

.wrapper {
    margin-left: -0.625rem;
    margin-right: -0.625rem;
}
.agreement-text {
    color: $grey-500;
    text-align: center;
    margin-top: 0.5rem;
}
</style>
