<script setup lang="ts">
import LAvatar from '@shared-components/components/LAvatar.vue';
import LButton from '@/core/components/LButton.vue';
import { LearnerSubscription, LessonEvent, TutorProfile } from '../../api/learner.api';
import { IonIcon } from '@ionic/vue';
import { arrowForward, informationCircleOutline } from 'ionicons/icons';
import { LearnerRoutes } from '@/router/learner.routes';
import { t } from '@/plugins/i18n';
import { computed } from 'vue';

type Props = {
    lesson: LessonEvent;
};

const { lesson } = defineProps<Props>();

/** TODO: implement */
const openModal = () => console.log('openModal', lesson);

const subPeriod = computed(() => 28);
</script>

<template>
    <div>
        <l-avatar
            size="xl"
            :uid="lesson.tutorUserUid!"
            :src="lesson.tutorAvatar"
            :alt="lesson.tutorName"
            class="mb-4"
        />

        <div class="flex flex-col gap-4">
            <h5>{{ t('learner.home.banner.title', { tutorName: lesson?.tutorName }) }}</h5>

            <div class="body-1">
                {{ t('learner.home.banner.caption') }}
            </div>
            <div class="flex gap-2 justify-between lg:justify-start items-center">
                <div
                    class="body-2"
                    v-if="subPeriod"
                >
                    {{ t('learner.home.banner.subscriptionPeriod', subPeriod) }}
                </div>
                <l-button
                    variant="text"
                    @click="openModal"
                >
                    <ion-icon
                        class="text-2xl"
                        slot="icon-only"
                        :icon="informationCircleOutline"
                    ></ion-icon>
                </l-button>
            </div>

            <div>
                <l-button
                    class="w-full lg:w-auto"
                    :router-link="{
                        name: LearnerRoutes.ScheduleRecurrentTime,
                        params: { tutorUserUid: lesson?.tutorUserUid },
                    }"
                >
                    {{ t('learner.home.banner.schedule') }}
                    <ion-icon
                        slot="end"
                        :icon="arrowForward"
                    />
                </l-button>
            </div>
        </div>
    </div>
</template>
