export function getHHMMSSFromDate(date: Date) {
  return `${date.getHours().toString().padStart(2, '0')
    }:${date.getMinutes().toString().padStart(2, '0')
    }:${date.getSeconds().toString().padStart(2, '0')
    }`;
}

export function getHHMMFromDate(date: Date) {
  return `${date.getHours().toString().padStart(2, '0')
    }:${date.getMinutes().toString().padStart(2, '0')
    }`;
}

export function getTzOffsetHHMMFromMilliseconds(milliseconds: number) {
  let sign = '';

  if (milliseconds < 0) {
    sign = '-';
  } else if (milliseconds > 0) {
    sign = '+';
  }

  const milli = Math.abs(milliseconds);
  const m = milli / 60000;
  const h = Math.floor(m / 60);

  return `${sign}${h.toString().padStart(2, '0')}:${(m - h * 60).toString().padStart(2, '0')}`;
}

export function getYYYYMMDDFromDate(date: Date) {
  return `${date.getFullYear()}` +
    `-${(date.getMonth() + 1).toString().padStart(2, '0')}` +
    `-${date.getDate().toString().padStart(2, '0')}`;
}

export function getDatesDiffInMinutes(date1: Date, date2: Date) {
  const diffInMs = Math.abs(date2.getTime() - date1.getTime()); 

  const diffInMinutes = Math.floor(diffInMs / (1000 * 60));

  return diffInMinutes;
}
