<script lang="ts" setup>
import { IonRow, IonCol, IonText, IonSkeletonText } from '@ionic/vue';
import { ref, Ref, computed } from 'vue';
import LToggleButton from '@/core/components/LToggleButton.vue';
import { t } from '@/plugins/i18n';
import { useLocaleDateFormat } from '@/core/utils/use-locale-date-format';

const format = useLocaleDateFormat();

const props = defineProps<{
    availableSlots: string[];
    selected: null | string[];
    isLoading?: boolean;
    disabled?: boolean;
}>();
const emit = defineEmits<{ select: [zonedTime: string] }>();

const morningSlots = computed(() => {
    return props.availableSlots.filter((slot) => {
        const hours = parseInt(format(new Date(slot), 'HH'));
        return hours < 12;
    });
});

const daySlots = computed(() => {
    return props.availableSlots.filter((slot) => {
        const hours = parseInt(format(new Date(slot), 'HH'));
        return hours >= 12 && hours < 18;
    });
});

const eveningSlots = computed(() => {
    return props.availableSlots.filter((slot) => {
        const hours = parseInt(format(new Date(slot), 'HH'));
        return hours >= 18;
    });
});

const handleSelect = (slot: string) => {
    emit('select', slot);
};

const isSelected = (slot: string) => {
    return props.selected?.includes(slot) ?? false;
};
</script>

<template>
    <div class="l-time-slots">
        <div
            class="body-1"
            v-if="!availableSlots.length && !props.isLoading"
        >
            {{ t('learner.onboarding.bookTime.noTimeslotsAvailable') }}
        </div>
        <div
            v-else-if="props.isLoading"
            class="skeleton-loader"
        >
            <ion-row
                class="row"
                v-for="index in 3"
                :key="`skeleton-morning-${index}`"
            >
                <ion-col
                    size="3"
                    size-sm="3"
                    size-md="2"
                    size-lg="2"
                    v-for="subIndex in 8"
                    :key="`skeleton-morning-${index}-${subIndex}`"
                >
                    <ion-skeleton-text
                        animated
                        class="rounded-md skeleton"
                    ></ion-skeleton-text>
                </ion-col>
            </ion-row>
        </div>

        <template v-else>
            <div
                v-show="morningSlots.length > 0"
                class="section"
            >
                <ion-text class="body-2">{{ t('learner.booking.morning') }}</ion-text>
                <ion-row class="row">
                    <ion-col
                        size="3"
                        size-sm="3"
                        size-md="2"
                        size-lg="2"
                        v-for="slot of morningSlots"
                        :key="slot"
                    >
                        <l-toggle-button
                            :selected="isSelected(slot)"
                            class="option"
                            :disabled="disabled && !isSelected(slot)"
                            @click="handleSelect(slot)"
                        >
                            {{ format(new Date(slot), 'HH:mm') }}
                        </l-toggle-button>
                    </ion-col>
                </ion-row>
            </div>

            <div
                v-show="daySlots.length > 0"
                class="section"
            >
                <ion-text class="body-2">{{ t('learner.booking.day') }}</ion-text>

                <ion-row class="row">
                    <template
                        v-for="slot of daySlots"
                        :key="slot"
                    >
                        <ion-col
                            size="3"
                            size-sm="3"
                            size-md="2"
                            size-lg="2"
                        >
                            <l-toggle-button
                                :selected="isSelected(slot)"
                                class="option"
                                :disabled="disabled && !isSelected(slot)"
                                @click="handleSelect(slot)"
                            >
                                {{ format(new Date(slot), 'HH:mm') }}
                            </l-toggle-button>
                        </ion-col>
                    </template>
                </ion-row>
            </div>

            <div
                v-show="eveningSlots.length > 0"
                class="section"
            >
                <ion-text class="body-2">{{ t('learner.booking.evening') }}</ion-text>

                <ion-row class="row">
                    <ion-col
                        size="3"
                        size-sm="3"
                        size-md="2"
                        size-lg="2"
                        v-for="slot of eveningSlots"
                        :key="slot"
                    >
                        <l-toggle-button
                            :selected="isSelected(slot)"
                            class="option"
                            :disabled="disabled && !isSelected(slot)"
                            @click="handleSelect(slot)"
                        >
                            {{ format(new Date(slot), 'HH:mm') }}
                        </l-toggle-button>
                    </ion-col>
                </ion-row>
            </div>
        </template>
    </div>
</template>

<style scoped lang="scss">
@use '@/css/ui-kit' as *;

.l-time-slots {
    display: flex;
    flex-direction: column;
    gap: 32px;

    .section {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    .row {
        row-gap: 8px;
    }

    .option {
        min-width: 80px;
    }

    .skeleton-loader {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    .skeleton {
        width: 80px;
        height: 40px;
    }
}
</style>
