<script setup lang="ts">
import { timeOutline } from 'ionicons/icons';
import { getLessonDuration } from '../../utils';
import { computed } from 'vue';
import { LessonEvent } from '../../api/learner.api';
import { useI18n } from 'vue-i18n';
import { IonRow, IonText, IonIcon } from '@ionic/vue';

const { lesson } = defineProps<{ lesson: LessonEvent }>();
const { locale } = useI18n();

const lessonDuration = computed(() => getLessonDuration(lesson, locale.value));
</script>

<template>
    <ion-row class="flex items-center h-full gap-x-1">
        <ion-icon
            :icon="timeOutline"
            class="size-6 text-gray-400"
            color="tertiary"
        />
        <ion-text class="body-2">{{ lessonDuration }}</ion-text>
    </ion-row>
</template>
