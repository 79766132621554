<script setup lang="ts">
import { useRoute, useRouter } from 'vue-router';
import LPage from '@/core/components/LPage.vue';
import BannerToGuide from '../components/onboarding/LBannerToGuide.vue';
import { IonCol, IonRow, IonSkeletonText } from '@ionic/vue';
import { LearnerRoutes } from '@/router/learner.routes';
import { TutorProfile, useLearnerApi } from '../api/learner.api';
import LButton from '@/core/components/LButton.vue';
import LIsland from '@/core/components/LIsland.vue';
import { storeToRefs } from 'pinia';
import { useCalendarStore } from '../store/calendar.store';
import { computed, onBeforeMount } from 'vue';
import { ref } from 'vue';
import { Ref } from 'vue';
import LAvatar from '@shared-components/components/LAvatar.vue';
import LChipList from '@/core/components/LChipList.vue';
import LLessonDuration from '../components/home/LLessonDuration.vue';
import { t } from '@/plugins/i18n';
import { LESSON_DATE_FORMAT } from '../constants';
import { useLocaleDateFormat } from '@/core/utils/use-locale-date-format';
import { useScreenSize } from '@/core/utils/use-screen-size';

const router = useRouter();
const route = useRoute();
const learnerApi = useLearnerApi();
const formatLocale = useLocaleDateFormat();
const { isLargeSize } = useScreenSize();

const calendarStore = useCalendarStore();
function toCalendar() {
    router.push({ name: LearnerRoutes.NextLessons });
}
const lessonStore = calendarStore;
const { nextLessons, loading } = storeToRefs(calendarStore);
const tutor = ref(null) as Ref<null | TutorProfile>;
const isLoading = ref(false);

const fetchData = async () => {
    isLoading.value = true;
    try {
        const startTime = route.query.start ? new Date(route.query.start as string) : new Date();
        const endTime = route.query.end ? new Date(route.query.end as string) : new Date();
        const tutorUid = route.query.uid ? (route.query.uid as string) : null;
        await lessonStore.fetchLessons(
            {
                startTime: startTime.toISOString(),
                endTime: endTime.toISOString(),
            },
            true,
        );
        if (tutorUid) {
            tutor.value = await learnerApi.getTutor(tutorUid);
        }
    } catch (error) {
        console.error('Error fetching data:', error);
    } finally {
        isLoading.value = false;
    }
};

const formatedDate = computed(
    () =>
        nextLessons[0]?.startTime &&
        formatLocale(new Date(nextLessons[0]?.startTime.toString()), LESSON_DATE_FORMAT),
);

onBeforeMount(async () => {
    await fetchData();
});
</script>

<template>
    <l-page>
        <ion-row>
            <ion-col
                size="12"
                size-lg="7"
                class="mb-8"
            >
                <l-island v-if="!isLoading">
                    <h5 class="mb-4">
                        {{ t('learner.onboarding.successTrial.title') }}
                    </h5>
                    <p class="mb-4">
                        {{ formatedDate }}
                    </p>
                    <div class="flex items-center">
                        <l-avatar
                            size="m"
                            :src="tutor?.tutorAvatar"
                            :alt="tutor?.tutorName"
                        ></l-avatar>
                        <div class="caption-2 ml-2">
                            {{ tutor?.tutorName }}
                        </div>
                    </div>
                    <div class="flex items-center">
                        <l-chip-list
                            v-if="tutor?.subjectCodes"
                            :list="tutor?.subjectCodes"
                        />
                        <l-lesson-duration
                            class="ml-2"
                            v-if="nextLessons.length > 0"
                            :lesson="nextLessons[0]"
                        />
                    </div>
                    <p class="mb-8">{{ t('learner.onboarding.successTrial.description') }}</p>
                    <div :class="{ 'submit-container': isLargeSize }">
                        <l-button
                            variant="secondary"
                            expand="block"
                            @click="toCalendar"
                        >
                            {{ t('learner.onboarding.successTrial.toCalendar') }}
                        </l-button>
                    </div>
                </l-island>
                <ion-skeleton-text
                    v-else
                    :animated="true"
                    style="height: 15rem"
                    class="rounded-md w-full"
                />
            </ion-col>
            <ion-col
                size="12"
                size-lg="4"
                :class="{ 'ml-5': isLargeSize }"
            >
                <banner-to-guide />
            </ion-col>
        </ion-row>
    </l-page>
</template>

<style lang="scss" scoped>
@use '@/css/ui-kit.scss' as *;

.submit-container {
    max-width: 14.6rem;
}
</style>
