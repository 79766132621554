import { defineStore } from 'pinia';
import {
    GetEventsParams,
    Meta,
    useLearnerApi,
    LessonEvent,
    LearnerLessonStatus,
} from '../api/learner.api';
import { addDays, addWeeks, subDays, subHours, subMinutes, subWeeks } from 'date-fns';
import { LessonScheduleType, LessonType } from '../types';
import { getIsLoadMoreAvailable } from '../utils';
import { Maybe } from '@common/types';

// TODO: rename store to LessonsCalendar or Schedule or something more descriptive
// TODO: add store typing via defineStore<StoreType> and remove type assertions
export const useCalendarStore = defineStore('calendarStore', {
    state: () => ({
        loading: false,
        pastLessons: [] as LessonEvent[],
        nextLessons: [] as LessonEvent[],
        isLoadMoreAvailable: false,
        meta: null as Meta | null,
    }),

    actions: {
        /**
         * Fetches lessons based on the provided parameters and updates the store accordingly.
         * @param {GetEventsParams} payload - The parameters for fetching the events.
         * @param {Maybe<boolean>} isNext - A flag indicating whether to fetch next lessons or past lessons.
         */
        async fetchLessons(payload: GetEventsParams, isNext: Maybe<boolean>) {
            const learnerApi = useLearnerApi();

            this.loading = true;
            const response = await learnerApi.getLearnerEvents(payload);
            this.loading = false;

            if (!response?.data) return;

            // TODO: remove this case and set isNext type to just boolean (redundant code)
            if (isNext === null) return response.data;

            if (isNext) {
                this.nextLessons = response.data;
            } else {
                this.pastLessons = response.data;
            }

            this.isLoadMoreAvailable = getIsLoadMoreAvailable(payload.limit, response.data);
        },
        async fetchLessonsByType(type: LessonScheduleType) {
            const isNext = type === LessonScheduleType.Next;

            const now = new Date().toISOString();
            const fourWeeksAgo = subWeeks(now, 4).toISOString();
            const fourWeeksLater = addWeeks(now, 4).toISOString();

            // to avoid fetch time collisions
            const hourAgo = subHours(now, 1).toISOString();
            const minuteAgo = subMinutes(now, 1).toISOString();

            const statuses: LearnerLessonStatus[] = isNext
                ? ['scheduled']
                : ['completed', 'cancelled'];

            await this.fetchLessons(
                {
                    startTime: isNext ? hourAgo : fourWeeksAgo,
                    endTime: isNext ? fourWeeksLater : minuteAgo,
                    sortOrder: isNext ? 'ASC' : 'DESC',
                    limit: 10,
                    statuses,
                },
                isNext,
            );
        },
        async fetchMoreLessons(lessonsCount: number, isNext: boolean) {
            const learnerApi = useLearnerApi();
            const today = new Date().toISOString();
            const sortOrder = isNext ? ('ASC' as const) : ('DESC' as const);
            const limit = 10;

            const payload = {
                startTime: isNext ? today : subDays(today, 60).toISOString(),
                endTime: isNext ? addDays(today, 60).toISOString() : today,
                /** @description - The number of lessons to skip before starting to collect the result set. */
                offset: lessonsCount,
                sortOrder,
                limit,
            };

            this.loading = true;
            const response = await learnerApi.getLearnerEvents(payload);
            this.loading = false;

            if (!response?.data) return;

            if (isNext) {
                this.nextLessons = [...this.nextLessons, ...response.data];
            } else {
                this.pastLessons = [...this.pastLessons, ...response.data];
            }

            this.isLoadMoreAvailable = getIsLoadMoreAvailable(limit, response.data);
        },
        async fetchCancelledLessons() {
            const learnerApi = useLearnerApi();

            const now = new Date().toISOString();
            const monthAgo = subDays(now, 30).toISOString();
            const monthLater = addDays(now, 30).toISOString();
            const payload = {
                startTime: monthAgo,
                endTime: monthLater,
                statuses: ['cancelled' as const],
            };

            this.loading = true;
            const response = await learnerApi.getLearnerEvents(payload);
            this.loading = false;

            if (!response?.data) return;

            return response.data;
        },
    },
});
