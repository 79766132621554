<script setup lang="ts">
import { onActivated, reactive, ref } from 'vue';
import FullCalendar from '@fullcalendar/vue3';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { CalendarOptions } from '@fullcalendar/core';
import { calendarApi, TutorCalendarEvent } from '../../api/calendar.api';
import { AvailableSlot } from '../../api/calendar.api';
import { getHHMMFromDate } from '@common/utils/date-formatters';
import LScheduleEventDialog from './LScheduleEventDialog.vue';
import LCancelEventDialog from './LCancelEventDialog.vue';
import LTransferEventDialog from './LTransferEventDialog.vue';
import { fromLocalToZoned, fromZonedToLocal } from '@/core/utils/date-formatters';
import { addMinutes, subHours, subMinutes } from 'date-fns';
import { useTutorStore } from '../../store/tutor.store';
import { toastController, IonModal, IonPopover, IonButton } from '@ionic/vue';
import { Ref } from 'vue';
import LButton from '@/core/components/LButton.vue';
import LSelect from '@/core/components/LSelect.vue';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { watch } from 'vue';

const store = useTutorStore();
const tutor = store.profile;
const { t, locale } = useI18n();

const timezoneOptions = computed(() => [
    {
        label: t('tutor.calendar.localTime'),
        value: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
    { label: t('tutor.calendar.calendarTime'), value: tutor.calendar.timezone },
]);
const updateCalendarKey = ref(1);
const choosedTimezoneOption = ref(timezoneOptions.value[0]);
const tutorEvents = reactive([]) as TutorCalendarEvent[];
const calendarEvents = reactive([]) as {
    start: Date;
    end?: Date;
    tutorEvent: TutorCalendarEvent;
}[];
const currentTimePeriod = reactive({}) as {
    start: Date;
    startStr: string;
    end: Date;
    endStr: string;
};
const isEventSchedulingPossible = ref(true);
const availableSlots = reactive([]) as AvailableSlot[];
const selectedEvent = ref({}) as Ref<{ start?: Date; tutorEvent?: any; jsEvent?: any }>;
const newDateTime = ref(null);
const currentTimePeriodByTz = ref({ start: new Date(), end: new Date() });

const modals = ref({
    isScheduleEventModalOpen: false,
    isScheduleEventPopupOpen: false,
    isCancelEventModalOpen: false,
    isEventPopupOpen: false,
    isEventModalOpen: false,
    isTransferEventModalOpen: false,
});

const convertTimeToTz = (time: string) =>
    fromLocalToZoned(new Date(time), choosedTimezoneOption.value.value);

const setModalState = async (modalName: keyof typeof modals.value, state: boolean) => {
    const modalsAfterNeedUpdate = [
        'isScheduleEventModalOpen',
        'isCancelEventModalOpen',
        'isTransferEventModalOpen',
    ] as Array<keyof typeof modals.value>;
    if (modalsAfterNeedUpdate.includes(modalName) && !state) {
        await fetchData();
    }
    modals.value[modalName] = state;
};

const datesSetForFullCalendar = async (period: any) => {
    Object.assign(currentTimePeriod, period);

    const startDate = fromZonedToLocal(
        new Date(currentTimePeriod.startStr),
        choosedTimezoneOption.value.value,
    );
    const endDate = fromZonedToLocal(
        new Date(currentTimePeriod.endStr),
        choosedTimezoneOption.value.value,
    );

    currentTimePeriodByTz.value = { start: startDate, end: endDate };

    await fetchData();
};

const dateSelectHandler = (event: { start: Date; jsEvent: any }) => {
    const startDate = event.start;

    isEventSchedulingPossible.value = !!availableSlots.find(
        (slot) => new Date(slot.startTime as string).getTime() === startDate.getTime(),
    );

    if (isEventSchedulingPossible.value) {
        isEventSchedulingPossible.value = startDate.getTime() > Date.now();
    }

    selectedEvent.value = event;
    setModalState('isScheduleEventPopupOpen', true);
};

const eventClickHandler = (info: any) => {
    if (info.event.extendedProps.tutorEvent.type === 'unavailable') {
        return;
    }

    selectedEvent.value = {
        ...info,
        tutorEvent: info.event.extendedProps.tutorEvent,
    };
    newDateTime.value = info.event.start;
    setModalState('isEventPopupOpen', true);
};

const eventDropHandler = async (eventDropInfo: any) => {
    const event = tutorEvents.find(
        (e) => e.uid === eventDropInfo.event.extendedProps.tutorEvent.uid,
    );

    if (['completed', 'cancelled'].includes(event?.status as string)) {
        const toast = await toastController.create({
            message: 'Это занятие нельзя перенести',
            color: 'danger',
            duration: 2000,
            icon: 'error',
            position: 'top',
        });
        toast.present();
        return;
    }
    selectedEvent.value = { tutorEvent: { ...event } };
    newDateTime.value = eventDropInfo.event.start;
    setModalState('isTransferEventModalOpen', true);
};

const cancelEventHandler = () => {
    setModalState('isCancelEventModalOpen', true);
};

const transferEventHandler = () => {
    setModalState('isTransferEventModalOpen', true);
};

const removeUnavailableEvent = async (info: Event) => {
    info.stopImmediatePropagation();

    const eventUid = (info?.target as HTMLDivElement)?.getAttribute('value');

    const event = tutorEvents.find((e) => e.uid === eventUid);

    const body = {
        startTime: fromZonedToLocal(
            new Date(event?.startTime as string),
            choosedTimezoneOption.value.value,
        ),
        reasonType: 'teacher_request',
        reasonDescription: 'Remove unavailable event',
        isCancelRecurrent: false,
    };

    await calendarApi.cancelEvent(eventUid as string, body as unknown as any);

    await fetchData();
};

const buttonTextComp = computed(() => ({
    today: t('tutor.calendar.button.today'),
    week: t('tutor.calendar.button.week'),
    day: t('tutor.calendar.button.day'),
    list: t('tutor.calendar.button.list'),
}));

watch(locale, (newLocale) => {
    calendarOptions.locale = newLocale;
    updateCalendarKey.value += 1;
});

const calendarOptions = reactive({
    plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
    height: 'auto',
    initialView: 'timeGridWeek',
    now: () => {
        const date = fromLocalToZoned(new Date(), choosedTimezoneOption.value.value);
        return date;
    },
    buttonText: buttonTextComp,
    slotLabelFormat: {
        hour: '2-digit',
        minute: '2-digit',
        omitZeroMinute: false,
        meridiem: 'short',
    },
    slotMaxTime: '24:00:00',
    slotDuration: '00:30:00',
    eventDurationEditable: false,
    eventStartEditable: true,
    locale: locale.value,
    firstDay: 1,
    nowIndicator: true,
    selectable: true,
    allDaySlot: false,
    dayMaxEvents: true,
    eventOverlap: false,
    selectOverlap: false,
    eventConstraint: 'businessHours',
    businessHours: false,
    datesSet: datesSetForFullCalendar,
    events: calendarEvents,
    eventClick: eventClickHandler,
    eventDrop: eventDropHandler,
    select: dateSelectHandler,
    selectConstraint: 'businessHours',
    eventDidMount: (info) => {
        const { type, uid } = info.event.extendedProps.tutorEvent;

        if (type === 'unavailable') {
            const dom = document.createElement('i');
            dom.classList.add('material-icons');
            dom.addEventListener('click', removeUnavailableEvent.bind(this));
            dom.innerText = 'close';
            dom.setAttribute('value', uid);
            info.el.append(dom);
        }
    },
}) as CalendarOptions;

const loadEvents = async () => {
    tutorEvents.splice(0);
    calendarEvents.splice(0);

    const result = await calendarApi.getCalendarEvents({
        startTime: currentTimePeriodByTz.value.start.toISOString(),
        endTime: currentTimePeriodByTz.value.end.toISOString(),
    });

    const tzConvertedData = result.data.map((event) => {
        const startDate = convertTimeToTz(event.startTime as string);
        const endDate = convertTimeToTz(event.endTime as string);

        return {
            ...event,
            startTime: startDate.toISOString(),
            endTime: endDate.toISOString(),
        };
    });

    tutorEvents.push(...tzConvertedData);

    for (const eventInfo of tutorEvents) {
        const startDate = new Date(eventInfo.startTime as string);
        const endDate = new Date(eventInfo.endTime as string);

        const event = {
            start: startDate,
            end: subHours(endDate, 1).getTime() === startDate.getTime() ? undefined : endDate,
            tutorEvent: eventInfo,
            backgroundColor: '#8A6CFF',
            borderColor: 'black',
            textColor: 'white',
            className: eventInfo.isRecurrent ? 'event-transferred' : '',
        };

        if (eventInfo.type === 'unavailable') {
            Object.assign(event, {
                backgroundColor: 'rgb(0, 0, 0, 0.65)',
                textColor: 'black',
                borderColor: 'rgb(0, 0, 0, 0.65)',
                display: 'background',
            });
        }

        calendarEvents.push(event);
    }
    calendarOptions.events = calendarEvents;
};

const loadAvailableSlots = async () => {
    availableSlots.splice(0);

    const result = await calendarApi.getAvailableSlots({
        startTime: currentTimePeriodByTz.value.start.toISOString(),
        endTime: currentTimePeriodByTz.value.end.toISOString(),
    });

    const tzConvertedData = result.data.map((slot) => {
        const startDate = convertTimeToTz(slot.startTime as string);
        const endDate = convertTimeToTz(slot.endTime as string);
        const date = startDate.toISOString().split('T')[0];

        return {
            ...slot,
            startTime: startDate.toISOString(),
            endTime: endDate.toISOString(),
            date,
        };
    });

    availableSlots.push(...tzConvertedData);
};

const updateBusinessHours = () => {
    const dates = [...availableSlots, ...tutorEvents]
        .map((event) => {
            const start = new Date(event.startTime as string);
            const end = new Date(event.endTime as string);

            const result = [start];

            if (subMinutes(end, 60).getTime() === start.getTime()) {
                result.push(subMinutes(end, 30));
            }

            return result;
        })
        .flat()
        .sort((a, b) => a.getTime() - b.getTime());

    const businessHours = [
        {
            daysOfWeek: [dates[0].getDay()],
            startTime: getHHMMFromDate(dates[0]),
            endTime: getHHMMFromDate(addMinutes(dates[0], 30)),
        },
    ];

    for (let i = 0; i < dates.length - 2; i += 1) {
        if (addMinutes(dates[i], 30).getTime() === dates[i + 1].getTime()) {
            const currentEndTime = businessHours[businessHours.length - 1].endTime;

            const [h, m] = currentEndTime.split(':').map(Number);
            const newTimeInMinutes = h * 60 + m + 30;

            const newH = Math.floor(newTimeInMinutes / 60);
            const newM = newTimeInMinutes % (newH * 60);

            businessHours[businessHours.length - 1].endTime = `${newH}:${newM}`;
        } else {
            businessHours.push({
                daysOfWeek: [dates[i + 1].getDay()],
                startTime: getHHMMFromDate(dates[i + 1]),
                endTime: getHHMMFromDate(addMinutes(dates[i + 1], 30)),
            });
        }
    }

    calendarOptions.businessHours = businessHours;
};

async function fetchData() {
    await loadEvents();
    await loadAvailableSlots();
    updateBusinessHours();
}

const updateCalendar = (value) => {
    choosedTimezoneOption.value = timezoneOptions.value.find((option) => option.value === value)!;
    updateCalendarKey.value += 1;
};

onActivated(fetchData);
</script>

<template>
    <div>
        <l-select
            :value="choosedTimezoneOption.value"
            @update:model-value="updateCalendar"
            interface="popover"
            :options="timezoneOptions"
        />
    </div>
    <ion-modal
        :is-open="modals.isTransferEventModalOpen"
        @didDismiss="() => setModalState('isTransferEventModalOpen', false)"
    >
        <LTransferEventDialog
            :event="selectedEvent"
            :timezone="choosedTimezoneOption.value"
            :newDateTime="newDateTime!"
            @ok="() => setModalState('isTransferEventModalOpen', false)"
            @close="() => setModalState('isTransferEventModalOpen', false)"
        />
    </ion-modal>

    <ion-modal
        :is-open="modals.isScheduleEventModalOpen"
        @didDismiss="setModalState('isScheduleEventModalOpen', false)"
    >
        <LScheduleEventDialog
            :startDateTime="selectedEvent?.start!"
            :availableSlots="availableSlots"
            :timezone="choosedTimezoneOption.value"
            @ok="() => setModalState('isScheduleEventModalOpen', false)"
        />
    </ion-modal>

    <ion-modal
        :is-open="modals.isCancelEventModalOpen"
        @didDismiss="setModalState('isCancelEventModalOpen', false)"
    >
        <LCancelEventDialog
            :event="selectedEvent?.tutorEvent"
            :timezone="choosedTimezoneOption.value"
            @ok="() => setModalState('isCancelEventModalOpen', false)"
            @close="() => setModalState('isCancelEventModalOpen', false)"
        />
    </ion-modal>

    <FullCalendar
        :options="calendarOptions"
        :key="updateCalendarKey"
    />

    <ion-popover
        :is-open="modals.isScheduleEventPopupOpen"
        :event="selectedEvent?.jsEvent"
        @willDismiss="() => setModalState('isScheduleEventPopupOpen', false)"
    >
        <l-button
            variant="text"
            color="dark"
            @click="() => setModalState('isScheduleEventModalOpen', true)"
        >
            {{ t('tutor.calendar.popup.scheduleEvent') }}
        </l-button>
    </ion-popover>

    <ion-popover
        :is-open="modals.isEventPopupOpen"
        :event="selectedEvent?.jsEvent"
        @willDismiss="() => setModalState('isEventPopupOpen', false)"
    >
        <l-button
            variant="text"
            color="dark"
            v-close-popup
            clickable
            @click="cancelEventHandler"
        >
            {{ t('tutor.calendar.popup.cancelEvent') }}
        </l-button>
        <l-button
            variant="text"
            color="dark"
            v-close-popup
            clickable
            @click="transferEventHandler"
        >
            {{ t('tutor.calendar.popup.transferEvent') }}
        </l-button>
    </ion-popover>
</template>

<style>
.fc .fc-non-business {
    background: rgba(255, 48, 48, 0.1) !important;
}

.fc .fc-col-header {
    background-color: rgb(161, 161, 161);
}
</style>
