<script setup lang="ts">
import { onMounted, ref, useTemplateRef, watch } from 'vue';
import {
    CustomChatMessage,
    LOCAL_STORAGE_FAILED_MESSAGES_TITLE,
    MESSAGE_FAILURE_TIMEOUT_SECONDS,
    useChatStore,
} from '../store/chat.store';
import { useRoute, useRouter } from 'vue-router';
import {
    IonTextarea,
    IonFooter,
    IonToolbar,
    IonContent,
    IonHeader,
    IonPopover,
    IonList,
    IonItem,
    IonImg,
    IonButton,
    IonLabel,
    IonNote,
    IonAvatar,
    IonIcon,
} from '@ionic/vue';
import {
    chevronBackOutline,
    attachOutline,
    checkmarkOutline,
    checkmarkDoneOutline,
    sendOutline,
    send,
    trashOutline,
    alertCircleOutline,
} from 'ionicons/icons';
import { useAuthStore } from '@/modules/auth/store/auth.store';
import { requestNotificationPermission, sendMessageToShw } from '../utils/chat.utils';

import LChatMessage from './LChatMessage.vue';
import LButton from '@/core/components/LButton.vue';
import { t } from '@/plugins/i18n';
import { subDays } from 'date-fns';
import { getMessageTime } from '../utils/get-message-sended-time';

const { initialMessage } = defineProps<{ initialMessage?: string }>();

let lastChangeTime = 0;
const messageText = ref(initialMessage ?? '');

const route = useRoute();
const router = useRouter();

const chatStore = useChatStore();

const authStore = useAuthStore();

const messageRefList = useTemplateRef('messageRefList');

const inputRef = useTemplateRef('input');

const baseToolbarHeight = '64px';
const headerHeight = ref(baseToolbarHeight);
const footerHeight = ref(baseToolbarHeight);
const baseInputHeight = ref('30px');
const inputMaxHeight = ref('65px');

const isPopoverOpen = ref(false);
const popoverEvent = ref<MouseEvent | null>(null);
const selectedMessage = ref<CustomChatMessage | null>(null);
const popoverSide = ref<'top' | 'bottom'>('bottom');
const popoverAlignment = ref<'start' | 'center' | 'end'>('center');

const openPopover = (event: MouseEvent, message: CustomChatMessage) => {
    if (!message.isSendFailed) return;

    const target = (event.currentTarget as HTMLElement).getBoundingClientRect();
    const viewportHeight = window.innerHeight;

    selectedMessage.value = message;
    popoverEvent.value = event;

    if (target.top > viewportHeight / 2) {
        popoverSide.value = 'top';
    } else {
        popoverSide.value = 'bottom';
    }

    isPopoverOpen.value = true;
};

const closePopoverHandler = () => {
    selectedMessage.value = null;
    isPopoverOpen.value = false;
};

const observer = new IntersectionObserver((entries) => {
    entries.forEach(async (entry) => {
        if (entry.isIntersecting) {
            const messageUid = entry.target.getAttribute('uid') as string;
            if (
                chatStore.activeChat &&
                messageUid > (chatStore.activeChat.latestReadMessageUid as string)
            ) {
                chatStore.setActiveChatMessageRead(messageUid);
                observer.unobserve(entry.target);
            }
            if (
                chatStore.activeChat?.chatMessagesMeta?.hasMore &&
                messageUid === chatStore.activeChat.chatMessagesMeta.nextCursor
            ) {
                await chatStore.loadActiveChatMessages(chatStore.activeChat.uid, messageUid);
                observeNeededMessages();
            }
        }
    });
});

const sendMessage = (text: string, isClearInput?: boolean) => {
    if (!text) {
        return;
    }

    const clientMessageId = Date.now().toString();

    const message: CustomChatMessage = {
        chatUid: chatStore.activeChat?.uid as string,
        content: text,
        senderUid: authStore.user.uid,
        uid: clientMessageId,
        isMessageDelivered: false,
        isMessageReceivedByServer: false,
        createdAt: new Date().toISOString(), // TODO
        deletedAt: new Date().toISOString(), // TODO
        editedAt: new Date().toISOString(), // TODO
    };

    message.sendFailTimeout = setTimeout(() => {
        if (chatStore.activeChat?.uid === message.chatUid) {
            const foundMsg = chatStore.activeChatMessages.find((msg) => msg.uid == message.uid);

            if (foundMsg) {
                foundMsg.isSendFailed = true;
            }
        }

        const failedMessages: CustomChatMessage[] = [];
        const existedFailedMessagesJSON = localStorage.getItem(LOCAL_STORAGE_FAILED_MESSAGES_TITLE);

        if (existedFailedMessagesJSON) {
            failedMessages.push(...JSON.parse(existedFailedMessagesJSON));
        }

        failedMessages.push(message);

        localStorage.setItem(LOCAL_STORAGE_FAILED_MESSAGES_TITLE, JSON.stringify(failedMessages));
    }, MESSAGE_FAILURE_TIMEOUT_SECONDS);

    chatStore.addMessageToActiveChat(message);

    const dto = {
        type: 'send_message',
        chatUid: chatStore.activeChat?.uid,
        content: text,
        clientMessageId,
    };

    sendMessageToShw(dto);

    if (isClearInput) {
        messageText.value = '';
    }
};

const resendMessage = (message: CustomChatMessage) => {
    chatStore.removeFailedMessage(message.uid);
    sendMessage(message.content);
    closePopoverHandler();
};

const removeFailedMessage = (message: CustomChatMessage) => {
    chatStore.removeFailedMessage(message.uid);
    closePopoverHandler();
};

const observeNeededMessages = () => {
    messageRefList.value?.forEach((item) => {
        if (!item?.messageRef) return console.error('MESSAGE_REF_IS_NULL');

        if (item.messageRef?.classList.contains('unread-message')) {
            observer.observe(item.messageRef);
        }
    });

    if (chatStore.activeChat?.chatMessagesMeta?.hasMore) {
        const lastMessageUid = chatStore.activeChat.chatMessagesMeta.nextCursor;

        const messageInstance = messageRefList.value?.find(
            (item) => item?.messageRef?.getAttribute('uid') === lastMessageUid,
        );

        if (messageInstance?.messageRef) {
            observer.observe(messageInstance.messageRef);
        }
    }
};

const observeInputSize = async () => {
    const textarea = await inputRef.value?.$el.getInputElement();
    textarea.style.maxHeight = inputMaxHeight.value;

    const observer = new ResizeObserver((val) => {
        const height = val[0].contentRect.height;

        let textareaHeight = height;

        if (height > parseInt(inputMaxHeight.value)) {
            textareaHeight = parseInt(inputMaxHeight.value);
        }

        footerHeight.value = `${parseInt(baseToolbarHeight) + (textareaHeight - parseInt(baseInputHeight.value))}px`;
    });

    observer.observe(textarea);
};

async function fetchData() {
    await chatStore.setActiveChat(route.params.chatUid as string);

    observeNeededMessages();
    observeInputSize();
}

watch(
    () => chatStore.activeChatMessages,
    (newMessages) => {
        const newMessage = newMessages[0];

        if (newMessage) {
            if (newMessage.senderUid !== authStore.user.uid) {
                const messageInstance = messageRefList.value?.find(
                    (i) => i?.messageRef?.getAttribute('uid') === newMessage.uid,
                );

                if (messageInstance?.messageRef) {
                    observer.observe(messageInstance.messageRef);
                }
            }
        }
    },
    { deep: true, flush: 'post' },
);

const checkInputScroll = async () => {
    const textarea = await inputRef.value?.$el.getInputElement();

    if (textarea.scrollHeight > parseInt(inputMaxHeight.value)) {
        textarea.style.overflowY = 'scroll';
    } else {
        textarea.style.overflowY = 'hidden';
    }

    textarea.scrollTop = textarea.scrollHeight;
};

const inputHandler = async (value: CustomEvent) => {
    messageText.value = value.detail.value;

    if (Date.now() >= lastChangeTime + 3000) {
        const dto = {
            type: 'typing',
            chatUid: chatStore.activeChat?.uid,
        };
        sendMessageToShw(dto);

        lastChangeTime = Date.now();
    }
};

const keyDownHandler = (event) => {
    if (event.key === 'Enter') {
        if (event.ctrlKey) {
            messageText.value += '\n';
            event.preventDefault();
        } else {
            sendMessage(messageText.value, true);
            event.preventDefault();
        }
    }
};

watch(messageText, () => {
    checkInputScroll();
});

onMounted(async () => {
    fetchData();
    await requestNotificationPermission();
});
</script>

<template>
    <ion-header>
        <ion-toolbar>
            <ion-icon
                class="icon-back"
                slot="start"
                :icon="chevronBackOutline"
                @click="router.go(-1)"
            />
            <ion-avatar
                slot="start"
                class="chat-avatar"
            >
                <img
                    :src="chatStore.activeChat?.chatAvatar"
                    onerror="this.src = '/icons/no-avatar.png'"
                />
            </ion-avatar>

            <div class="chat-name">
                <div>{{ chatStore.activeChat?.chatName }}</div>
                <ion-note
                    v-if="chatStore.activeChat?.isParticipantTyping"
                    class="typing-indicator-container"
                >
                    <span>Печатает</span>
                    <div class="typing-animation">
                        <span class="dot">.</span>
                        <span class="dot">.</span>
                        <span class="dot">.</span>
                    </div>
                </ion-note>
            </div>
        </ion-toolbar>
    </ion-header>

    <ion-content
        class="content-container"
        ref="content"
        :force-overscroll="false"
    >
        <div class="message-container">
            <l-chat-message
                v-for="message in chatStore.activeChatMessages.slice()"
                @click="(e) => openPopover(e, message)"
                :key="message.uid"
                :message="message"
                :selectedMessage="selectedMessage"
                ref="messageRefList"
            />
        </div>

        <ion-popover
            :is-open="isPopoverOpen"
            :event="popoverEvent"
            :show-backdrop="false"
            :side="popoverSide"
            :alignment="popoverAlignment"
            @didDismiss="closePopoverHandler"
            class="popup-container"
        >
            <ion-list
                lines="full"
                class="popup-menu"
            >
                <ion-item @click="resendMessage(selectedMessage!)">
                    <ion-label>Повторить</ion-label>
                    <ion-icon :icon="sendOutline"></ion-icon>
                </ion-item>
                <ion-item @click="removeFailedMessage(selectedMessage!)">
                    <ion-label color="danger">Отменить</ion-label>
                    <ion-icon
                        :icon="trashOutline"
                        color="danger"
                    ></ion-icon>
                </ion-item>
            </ion-list>
        </ion-popover>
    </ion-content>

    <ion-footer>
        <ion-toolbar class="bottom-panel">
            <div class="input-container">
                <ion-textarea
                    v-model="messageText"
                    @ion-input="inputHandler"
                    @keydown="keyDownHandler"
                    :rows="1"
                    :auto-grow="true"
                    ref="input"
                    class="input"
                    fill="outline"
                    shape="round"
                />
            </div>

            <l-button
                @click="sendMessage(messageText, true)"
                :disabled="!messageText"
                variant="primary-alt"
                class="send-button mr-2"
                slot="end"
            >
                <ion-icon :icon="send" />
            </l-button>
        </ion-toolbar>
    </ion-footer>
</template>

<style scoped lang="scss">
@use '@/css/ui-kit.scss' as *;

$vh: 100svh;

.popup-container::part(content) {
    border-radius: $default-border-radius;
    background-color: $grey-300;
    border: 2px solid $grey-700;
}

.popup-container::part(arrow) {
    display: none;
}

.popup-menu {
    display: flex;
    flex-direction: column;
    ion-item:last-child {
        --border-width: 0;
    }
}

ion-header ion-toolbar {
    height: v-bind(headerHeight);
}

ion-footer ion-toolbar {
    height: v-bind(footerHeight);
}

ion-toolbar {
    --background: $grey-100;
    display: flex;
}

.icon-back {
    margin-left: 15px;
}

.container {
    overflow-y: hidden;
}

.content-container {
    height: calc($vh - v-bind(headerHeight) - v-bind(footerHeight));
    background-color: $grey-100;
}

.chat-avatar {
    width: 40px;
    height: 40px;
    margin-left: 20px;
}

.chat-name {
    margin-left: 10px;
}

.bottom-panel {
    display: flex;
}

.attach-icon {
    transform: rotate(45deg);
    margin-left: 15px;
}

.input-container {
    padding: 0 20px;

    .input {
        --border-width: 0;
        --padding-start: 10px;
        --padding-top: 5px;
        --padding-bottom: 0;
        --highlight-color-focused: $grey-100;

        min-height: v-bind(baseInputHeight);
        max-height: v-bind(inputMaxHeight);
        border: 1px solid $grey-400;
        border-radius: 48px; // hardcoded because of ion-textarea bug
        width: 100%;
        resize: none;

        textarea {
            max-height: v-bind(inputMaxHeight);
        }

        &:focus {
            border: none;
        }
    }
}

.native-wrapper .native-textarea {
    background-color: black !important;
    padding: 0;
    min-height: 30px !important;
    height: 30px;
}

.send-button {
    --border-radius: 50%;

    width: 3rem;
    height: 3rem;
}

.message-container {
    display: flex;
    flex-direction: column-reverse;
    overflow-y: auto;
    height: 100%;
    background-color: $grey-100;
}

ion-footer,
ion-header {
    z-index: 999 !important;
}

// typing animation

.typing-indicator-container {
    display: flex;
}

.typing-animation {
    display: flex;
    align-items: center;
}

.dot {
    margin: 0 2px;
    opacity: 0;
    animation: blink 1.4s infinite both;
}

.dot:nth-child(1) {
    animation-delay: 0s;
}

.dot:nth-child(2) {
    animation-delay: 0.2s;
}

.dot:nth-child(3) {
    animation-delay: 0.4s;
}

@keyframes blink {
    0%,
    20%,
    100% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }
}
</style>
