<script setup lang="ts">
import { ref, computed, nextTick, onMounted, onUnmounted } from 'vue';
import { t } from '@/plugins/i18n';
import { useLearnerStore } from '@/modules/learner/store/learner.store';
import { useAuthApi } from '../../api/auth.api';
import LFeedback from '@/core/components/LFeedback.vue';
import { IonModal, IonIcon, IonChip, IonLabel } from '@ionic/vue';
import { closeCircleOutline, pencilOutline } from 'ionicons/icons';
import LInput from '@/core/components/LInput.vue';
import LButton from '@/core/components/LButton.vue';
import { emailRegExp } from '@common/utils/email-regexp';
import { useLearnerApi } from '@/modules/learner/api/learner.api';
import LDivider from '@/core/components/LDivider.vue';
import { useScreenSize } from '@/core/utils/use-screen-size';
import { useNotification } from '@/core/composables/use-notification';
import { h } from 'vue';

const authApi = useAuthApi();
const learnerApi = useLearnerApi();
const learnerStore = useLearnerStore();
const email = ref(learnerStore.profile.user.email);
const isCodeSent = ref(false);
const emit = defineEmits(['verification-success']);

const notify = useNotification();

const isModalOpen = ref(false);

const codeLength = 6;
const codeArray = ref(Array(codeLength).fill(''));
const codeInputs = ref([]) as any;

const isCodeDangerVisible = ref(false);

const isEmailValidationFailed = ref(false);

const validateEmail = (emailValue: string) => {
    const result = emailValue.match(emailRegExp);
    isEmailValidationFailed.value = !result;

    return result;
};

const codeValue = computed(() => codeArray.value.join(''));

const newEmail = ref(email.value);

const sendVerificationCode = async () => {
    isCodeSent.value = true;
    await authApi.verificationRequest('email');
};

const verifyCode = async () => {
    const result = await authApi.verificationValidate(codeValue.value);
    if (result) {
        emit('verification-success');
    } else {
        isCodeDangerVisible.value = true;
    }
};

const handlePaste = (event) => {
    const pasteData = event.clipboardData.getData('text');
    const digits = pasteData.replace(/\D/g, '');

    for (let i = 0; i < codeLength; i++) {
        if (digits[i]) {
            codeArray.value[i] = digits[i];
        } else {
            break;
        }
    }

    if (!codeArray.value.includes('')) {
        verifyCode();
        isCodeDangerVisible.value = false;
    }

    const nextIndex = Math.min(digits.length, codeLength - 1);
    nextTick(() => {
        codeInputs.value[nextIndex]?.focus();
    });
};

const handleInput = (index, event) => {
    const value = event.target.value;
    if (/^\d$/.test(value)) {
        codeArray.value[index] = value;
        if (index < codeLength - 1) {
            nextTick(() => {
                codeInputs.value[index + 1]?.focus();
            });
        }

        if (!codeArray.value.includes('')) {
            verifyCode();
        }

        isCodeDangerVisible.value = false;
    } else {
        codeArray.value[index] = '';
    }
};

const handleBackspace = (index) => {
    if (!codeArray.value[index] && index > 0) {
        codeArray.value[index - 1] = '';
        nextTick(() => {
            codeInputs.value[index - 1]?.focus();
        });
    }
};

// timer logic

const { isLargeSize } = useScreenSize();
const initialTime = 59;
const timeLeft = ref(initialTime);
const isEmailEdit = ref(false);

let timer = null as null | NodeJS.Timeout;

const formattedTime = computed(() => {
    const minutes = String(Math.floor(timeLeft.value / 60)).padStart(2, '0');
    const seconds = String(timeLeft.value % 60).padStart(2, '0');
    return `${minutes}:${seconds}`;
});

const startTimer = () => {
    if (timer) {
        clearInterval(timer);
    }
    timer = setInterval(() => {
        if (timeLeft.value > 0) {
            timeLeft.value -= 1;
        } else {
            clearInterval(timer!);
        }
    }, 1000);
};

const resendCode = async () => {
    timeLeft.value = initialTime;
    await sendVerificationCode();
    startTimer();
};

const changeEmail = async () => {
    const response = await learnerApi.patchLearnerData({ email: newEmail.value });

    if (!response.error) {
        await learnerStore.fetch();
        email.value = learnerStore.profile.user.email;
        await resendCode();
        isModalOpen.value = false;
    } else {
        isEmailValidationFailed.value = true;
    }
};

const showSuccessMessage = () => {
    notify.success({
        mode: 'notification',
        type: 'success',
        duration: 2000,
        title: t('learner.verification.successChangeEmailTitle'),
        message: t('learner.verification.successChangeEmail'),
    });
};

const handleShowDesktopModal = () => {
    const close = () => notify.closeById(id);

    const desktopChangeEmail = () => {
        changeEmail();
        close();
        showSuccessMessage();
    };

    const slotContent = computed(() =>
        h('div', { class: 'flex flex-col gap-4' }, [
            h('div', { class: 'caption-3' }, t('learner.verification.emailLabel')),
            h(LInput, {
                'onUpdate:modelValue': (e) => {
                    newEmail.value = e as string;
                },
                type: 'email',
                color: isEmailValidationFailed.value ? 'danger' : undefined,
                placeholder: t('auth.login.emailPlaceholder'),
                onInput: () => validateEmail(newEmail.value),
            }),
            h(
                LButton,
                {
                    disabled: isEmailValidationFailed.value,
                    onClick: desktopChangeEmail,
                },
                { default: t('learner.verification.submitEmail') },
            ),
        ]),
    );
    const id = notify.error({
        mode: 'modal',
        type: 'warning',
        duration: 0,
        slotComponent: slotContent,
    });
};

onMounted(async () => {
    await sendVerificationCode();
    startTimer();
});

onUnmounted(() => {
    if (timer) {
        clearInterval(timer);
    }
});
</script>

<template>
    <div class="verification-form">
        <div class="caption-2">{{ t('learner.verification.title') }}</div>
        <div class="body-1 mt-4 px-2">
            {{ t('learner.verification.codeSended') }}
            <div v-if="!isLargeSize">
                {{ email }}
            </div>
            <div v-else>
                <ion-chip
                    class="mt-4"
                    v-if="!isEmailEdit"
                    @click="handleShowDesktopModal"
                >
                    <ion-label> {{ email }}</ion-label>
                    <ion-icon :icon="pencilOutline"></ion-icon>
                </ion-chip>
            </div>
        </div>
        <div class="body-1 mt-4 px-2">{{ t('learner.verification.codeLabel') }}</div>

        <div class="code-input-container">
            <div
                v-for="(digit, index) in codeArray"
                :key="index"
                class="code-input-wrapper"
            >
                <input
                    v-model="codeArray[index]"
                    maxlength="1"
                    @input="handleInput(index, $event)"
                    @keydown.backspace="handleBackspace(index)"
                    @paste="handlePaste($event)"
                    :class="['code-input', { 'red-dark': isCodeDangerVisible }]"
                    type="text"
                    ref="codeInputs"
                />
                <span
                    v-if="!digit"
                    class="underscore"
                ></span>
            </div>
        </div>
        <l-feedback
            color="danger-tint"
            class="mt-1 mx-auto"
            v-if="isCodeDangerVisible"
        >
            {{ t('learner.verification.codeWrong') }}
        </l-feedback>

        <l-divider />

        <div class="body-2 mt-4 px-2">{{ t('learner.verification.spamWarning') }}</div>

        <div class="resend-code-container">
            <div
                v-if="timeLeft > 0"
                class="body-1 mt-4 px-2"
            >
                {{ t('learner.verification.resendDelay') }} {{ formattedTime }}
            </div>
            <div
                v-else
                class="caption-3 mt-4 px-2 cursor-pointer"
                @click="resendCode"
            >
                {{ t('learner.verification.resendButton') }}
            </div>
        </div>
        <div
            v-if="!isLargeSize"
            class="caption-3 mt-4 px-2 cursor-pointer"
            id="open-modal"
            @click="isModalOpen = true"
        >
            {{ t('learner.verification.changeEmail') }}
        </div>
        <ion-modal
            :initial-breakpoint="0.25"
            :breakpoints="[0, 0.25]"
            trigger="open-modal"
            :handle="false"
            class="modal"
            :is-open="isModalOpen"
        >
            <div class="p-4">
                <div class="flex justify-between items-center mb-2">
                    <div class="caption-3">{{ t('learner.verification.emailLabel') }}</div>
                    <ion-icon
                        size="large"
                        :icon="closeCircleOutline"
                        @click="isModalOpen = false"
                    ></ion-icon>
                </div>
                <l-input
                    v-model="newEmail"
                    type="email"
                    :placeholder="t('auth.login.emailPlaceholder')"
                    :color="isEmailValidationFailed ? 'danger' : undefined"
                    @ion-input="validateEmail(newEmail)"
                >
                </l-input>
                <l-feedback
                    class="mt-2"
                    color="danger-tint"
                    :class="{ invisible: !isEmailValidationFailed }"
                    >{{ t('learner.verification.emailError') }}</l-feedback
                >
                <l-button
                    expand="block"
                    class="mt-2"
                    :disabled="isEmailValidationFailed"
                    @click="changeEmail"
                >
                    {{ t('learner.verification.submitEmail') }}
                </l-button>
            </div>
        </ion-modal>
    </div>
</template>

<style scoped lang="scss">
@use '@/css/ui-kit.scss' as *;

.verification-form {
    text-align: center;
    position: relative;
}

.code-input-container {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin: 20px 0;
}

.code-input-wrapper {
    position: relative;
    width: 40px;
    height: 50px;
}

.red-dark {
    color: $red-dark;
}

.code-input {
    width: 100%;
    height: 100%;
    text-align: center;
    font-size: 32px;
    border: none;
    outline: none;
    background: transparent;
    z-index: 2;
}

.code-input:focus {
    outline: none;
}

.underscore {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 2px;
    background-color: $grey-800;
    transition: background-color 0.2s;
}

.code-input:focus + .underscore {
}

.modal {
    --border-radius: 16px;
    --background: $grey-300;
    --border-width: 2px;
    --border-color: $grey-800;
    --width: calc(100% - 4px);
    --backdrop-opacity: 1;

    &::part(content) {
        background-color: $grey-300;
        border: 2px solid $grey-800;
    }

    &::part(backdrop) {
        background-color: $grey-100;
    }
}

.visible {
    opacity: 1;
}

.invisible {
    opacity: 0;
}
</style>
