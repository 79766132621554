<script lang="ts" setup>
import { schoolSharp } from 'ionicons/icons';
import LChip from './LChip.vue';
import { IonIcon, IonLabel } from '@ionic/vue';
import { t } from '@/plugins/i18n';
import { computed } from 'vue';
import { Maybe } from '@common/types';

const { icon, code } = defineProps<{
    icon?: Maybe<string>;
    code?: Maybe<string>;
}>();

const label = computed(() =>
    code ? t(`components.LSubjectChip.codes.${code}`) : t('components.LSubjectChip.codes.unset'),
);
</script>

<template>
    <l-chip :outline="true">
        <ion-icon
            v-if="icon !== null"
            :icon="icon ? icon : schoolSharp"
            class="text-base"
            color="tertiary"
        />
        <ion-label class="body-1">{{ label }}</ion-label>
    </l-chip>
</template>

<style></style>
