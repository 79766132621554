<template>
    <div class="l-divider"></div>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss">
@use '@/css/ui-kit.scss' as *;

.l-divider {
    height: 1px;
    background-color: $grey-300;
    margin: 1rem 0;
}
</style>
