Алексей Савуся, [23/1/25 18:22]
<script setup lang="ts">
import { onMounted, onBeforeUnmount, ref, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { chevronDownOutline } from 'ionicons/icons';
import { IonIcon } from '@ionic/vue';

import Cookies from 'js-cookie';

import { useLearnerStore } from '@/modules/learner/store/learner.store';
import { storeToRefs } from 'pinia';
import { useUserApi } from '@/modules/user/api/user.api';

const userApi = useUserApi();
const learnerStore = useLearnerStore();
const { profile } = storeToRefs(learnerStore);

const { locale } = useI18n();

const error = ref<string>('');
const isLanguageValidationFailed = ref(false);

const isDropdownOpen = ref(false);
const switcherRef = ref<HTMLButtonElement | null>(null);
const dropdownStyles = ref({ top: '0px', left: '0px' });

const languages = ref([
    { code: 'en', label: 'English' },
    { code: 'ru', label: 'Русский' },
]);

const selectedLanguage = ref('en');

const changeLanguage = async (lang: string) => {
    error.value = '';
    isLanguageValidationFailed.value = false;

    const prevLanguage = selectedLanguage.value;

    Cookies.set('lang', lang, { expires: 365 });

    selectedLanguage.value = lang;

    const response = await userApi.patchLearnerData({ lang: lang });

    if (!response.error) {
        await learnerStore.fetch();

        selectedLanguage.value = profile.value.user.lang || lang;

        locale.value = selectedLanguage.value;
    } else {
        error.value = 'Что-то пошло не так при смене языка';
        isLanguageValidationFailed.value = true;

        Cookies.set('lang', prevLanguage, { expires: 365 });
        selectedLanguage.value = prevLanguage;
    }

    isDropdownOpen.value = false;
};

const toggleDropdown = () => {
    isDropdownOpen.value = !isDropdownOpen.value;
};

const closeDropdown = () => {
    isDropdownOpen.value = false;
};

const positionDropdown = () => {
    if (switcherRef.value) {
        const rect = switcherRef.value.getBoundingClientRect();
        dropdownStyles.value = {
            top: `${rect.bottom + window.scrollY - 38}px`,
            left: `${rect.left + window.scrollX}px`,
        };
    }
};

const handleClickOutside = (event: MouseEvent) => {
    if (switcherRef.value && !switcherRef.value.contains(event.target as Node)) {
        closeDropdown();
    }
};

onMounted(() => {
    document.addEventListener('click', handleClickOutside);

    const savedLanguage = Cookies.get('lang');
    const profileLanguage = profile.value?.user.lang;

    selectedLanguage.value = savedLanguage || profileLanguage || 'en';

    locale.value = selectedLanguage.value;
});

onBeforeUnmount(() => {
    document.removeEventListener('click', handleClickOutside);
});
</script>

<template>
    <div class="language-switcher">
        <button
            ref="switcherRef"
            @click="toggleDropdown"
            class="current-language"
        >
            <div>
                {{ languages.find((lang) => lang.code === selectedLanguage)?.label }}
            </div>
            <ion-icon
                class="me-2"
                color="dark"
                :icon="chevronDownOutline"
            />
        </button>

        <div>
            <ul
                v-if="isDropdownOpen"
                class="dropdown"
                :style="dropdownStyles"
            >
                <li
                    v-for="lang in languages"
                    :key="lang.code"
                    :class="{ active: lang.code === selectedLanguage }"
                    @click="changeLanguage(lang.code)"
                >
                    {{ lang.label }}
                </li>
            </ul>
        </div>

        <div
            v-if="error"
            class="error"
        >
            {{ error }}
        </div>
    </div>
</template>

<style scoped lang="scss">
@use '../../css/ui-kit.scss' as *;

.language-switcher {
    position: relative;
    display: inline-block;
    max-width: 38rem;
}

.current-language {
    background: none;
    border: $grey-500 2px solid;
    border-radius: $default-border-radius;
    margin-right: 16px;
    font-size: 16px;
    cursor: pointer;
    padding: 10px;
    align-items: center;
    width: 100%;
    display: flex;
    justify-content: space-between;
    min-height: 3.5rem;
}

.current-language:hover {
    background-color: #{$grey-200};
}

.dropdown {
    position: absolute;
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 16px;
    background-color: #{$grey-100};
    border: 2px solid #{$grey-700};
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    width: 100%;
    max-width: 37rem;
    min-height: 3.5rem;
}

.dropdown li {
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 6px;
}

.dropdown li.active {
    font-weight: bold;
    background-color: #{$grey-200};
}

.error {
    color: red;
    margin-top: 0.5rem;
}
</style>
