<script setup lang="ts">
import {
    IonTitle,
    IonToolbar,
    IonHeader,
    IonPage,
    IonContent,
    IonGrid,
    IonRow,
    IonCol,
    IonCard,
    IonCardHeader,
    IonCardTitle,
} from '@ionic/vue';
import { useRouter } from 'vue-router';
import { t } from '@/plugins/i18n';
import LPage from '@/core/components/LPage.vue';
import { LearnerRoutes } from '@/router/learner.routes';
import { TutorRoutes } from '@/router/tutor.routes';

const router = useRouter();
const onSelectRole = async (role) => {
    if (role === 'learner') {
        router.push({ name: LearnerRoutes.Onboarding });
    } else if (role === 'tutor') {
        router.push({ name: TutorRoutes.Onboarding });
    }
};
</script>

<template>
    <l-page>
        <ion-header>
            <ion-toolbar>
                <ion-title>{{ t('auth.selectRole.title') }}</ion-title>
            </ion-toolbar>
        </ion-header>
        <ion-grid>
            <ion-row>
                <ion-col size="12" size-md="6">
                    <ion-card @click="onSelectRole('learner')">
                        <img
                            alt="Silhouette of mountains"
                            src="https://ionicframework.com/docs/img/demos/card-media.png"
                        />
                        <ion-card-header>
                            <ion-card-title>{{ t('auth.selectRole.learner') }}</ion-card-title>
                        </ion-card-header>
                        <ion-card-content>
                            {{ t('auth.selectRole.learnerDescription') }}
                        </ion-card-content>
                    </ion-card>
                </ion-col>

                <ion-col size="12" size-md="6">
                    <ion-card @click="onSelectRole('tutor')">
                        <img
                            alt="Silhouette of mountains"
                            src="https://ionicframework.com/docs/img/demos/card-media.png"
                        />
                        <ion-card-header>
                            <ion-card-title>{{ t('auth.selectRole.tutor') }}</ion-card-title>
                        </ion-card-header>
                        <ion-card-content>
                            {{ t('auth.selectRole.tutorDescription') }}
                        </ion-card-content>
                    </ion-card>
                </ion-col>
            </ion-row>
        </ion-grid>
    </l-page>
</template>

<style scoped>
ion-card {
    cursor: pointer;
    text-align: center;
}
ion-card:hover {
    background: var(--background-focused);
}
</style>
