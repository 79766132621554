<script setup lang="ts">
import { computed, ref } from 'vue';
import { IonIcon, useIonRouter } from '@ionic/vue';
import { useRoute } from 'vue-router';
import { useScreenSize } from '../utils/use-screen-size';
import LButton from './LButton.vue';
import { chevronBack } from 'ionicons/icons';
import { t } from '@/plugins/i18n';

const router = useIonRouter();
const route = useRoute();

const props = defineProps<{
    position: 'header' | 'content';
}>();

// get data from meta
const backName = computed(() => route.meta.backName);
const title = computed(() => route.meta.backTextKey as string);

const { isLargeSize } = useScreenSize();

const goBack = () => {
    if (backName.value) {
        router.push({ name: backName.value });
    } else {
        router.back();
    }
};
</script>

<template>
    <div v-if="title">
        <!-- Mobile -->
        <l-button
            v-if="!isLargeSize && position === 'header'"
            variant="text"
            size="small"
            class="-ms-2"
            @click="goBack()"
        >
            <ion-icon
                slot="icon-only"
                class="text-2xl"
                :icon="chevronBack"
            ></ion-icon>
        </l-button>

        <!-- Desktop -->
        <div
            v-if="isLargeSize && position === 'content'"
            @click="goBack"
            class="desktop-button mb-4"
        >
            <ion-icon
                slot="icon-only"
                class="text-2xl"
                :icon="chevronBack"
            ></ion-icon>
            <p class="caption-3">{{ t(title) }}</p>
        </div>
    </div>
</template>

<style scoped>
.desktop-button {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 0.25rem;
}
</style>
