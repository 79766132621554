<script setup lang="ts">
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { IonRow, IonCol, IonIcon, IonText } from '@ionic/vue';
import {
    addCircleOutline,
    arrowForwardOutline,
    calendar,
    removeCircleOutline,
    schoolOutline,
} from 'ionicons/icons';
import { Tutor } from '../../api/learner.api';
import { useTutorCatalogStore } from '../../store/tutors-catalog.store';
import { t } from '@/plugins/i18n';
import LButton from '@/core/components/LButton.vue';
import LHero from '@/core/components/LHero.vue';
import { LearnerRoutes } from '@/router/learner.routes';
import LIsland from '@/core/components/LIsland.vue';
import LChipList from '@/core/components/LChipList.vue';
import { computed } from 'vue';

const props = defineProps<{ tutor: Tutor; index: number; isOnboarding: boolean }>();
const { tutor, index } = props;

const localePref = 'learner.tutorCatalog.tutorCard';

const router = useRouter();

const descriptionRef = ref<HTMLElement | null>(null);
const isExpanded = ref(false);

const toggleExpand = () => {
    isExpanded.value = !isExpanded.value;
};

const tutorStore = useTutorCatalogStore();
const { setActiveTutor } = tutorStore;
const emit = defineEmits(['hoverCard']);
const maxCountSimbols = 120;

const onMouseEnter = (event: MouseEvent) => {
    setActiveTutor(index);
    const card = event.target as HTMLElement;
    const position = {
        top: card.offsetTop - 15,
    };

    emit('hoverCard', position);
};

const sheduleButton = computed(() => t(`${localePref}.schedule`));
const scheduleLessonButton = computed(() =>
    props.isOnboarding ? t(`${localePref}.scheduleTrialLesson`) : t(`${localePref}.scheduleLesson`),
);

const onClick = async () => {
    if (props.isOnboarding) {
        router.push({
            name: LearnerRoutes.OnboardingTutorPage,
            params: { tutorUserUid: tutor.tutorUserUid },
        });
    } else {
        router.push({ name: LearnerRoutes.Tutor, params: { tutorUserUid: tutor.tutorUserUid } });
    }
};
</script>

<template>
    <div
        :id="`ion-card-${tutor.tutorUserUid}`"
        @mouseenter="onMouseEnter"
        @click="onClick"
        class="mb-4"
    >
        <l-island>
            <ion-row>
                <ion-col>
                    <ion-row>
                        <l-hero
                            :name="tutor.tutorName!"
                            :avatar="tutor.tutorAvatar"
                            :price="tutor.priceFromUsd"
                            :videoUrl="tutor.profileVideo"
                        ></l-hero>
                    </ion-row>
                    <ion-row>
                        <l-chip-list
                            :icon="schoolOutline"
                            :list="[tutor.subjectCode]"
                        />
                    </ion-row>
                    <ion-row>
                        <!-- Description -->
                        <ion-col
                            class="tutor-card-description"
                            :class="{ expanded: isExpanded }"
                            ref="descriptionRef"
                        >
                            <p class="caption-3 tutor-card-title">
                                {{ tutor.profileTitle }}
                            </p>
                            <p class="body-2 mt-2">
                                {{ tutor.profileDescription }}
                                <ion-text
                                    v-if="tutor.profileDescription.length > maxCountSimbols"
                                    class="show-more-button icon-gradient-overlay"
                                    size="small"
                                    @click.stop="toggleExpand"
                                >
                                    <ion-icon
                                        class="text-2xl"
                                        color="dark"
                                        :icon="isExpanded ? removeCircleOutline : addCircleOutline"
                                    />
                                </ion-text>
                            </p>
                        </ion-col>
                    </ion-row>
                    <div class="button-container mt-6">
                        <l-button
                            expand="block"
                            variant="tertiary"
                            size="small"
                        >
                            <ion-icon
                                :icon="calendar"
                                slot:icon-only
                                class="mr-2"
                                color="dark"
                            ></ion-icon>
                            <ion-text color="dark"> {{ sheduleButton }} </ion-text>
                        </l-button>
                        <l-button
                            expand="block"
                            class="caption-3"
                            size="default"
                            @click.stop="onClick"
                        >
                            {{ scheduleLessonButton }}
                            <ion-icon
                                :icon="arrowForwardOutline"
                                class="ml-1"
                            ></ion-icon>
                        </l-button>
                    </div>
                </ion-col>
            </ion-row>
        </l-island>
    </div>
</template>

<style scoped lang="scss">
@use '@/css/ui-kit.scss' as *;

.tutor-card-description {
    .show-more-button {
        position: absolute;
        background-color: $grey-100;
        border-radius: 50%;
        bottom: -0.1rem;
        right: 5px;
        font-size: 15px;
        cursor: pointer;
    }

    max-height: 4.25rem;
    overflow: hidden;
    text-overflow: clip;
    position: relative;

    .tutor-card-title {
        max-height: 1rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}

.tutor-card-description.expanded {
    max-height: 100%;
    overflow: visible;
    .show-more-button {
        display: block;
        text-align: end;
        bottom: -0.3rem;
    }
}

.favorite {
    position: absolute;
    top: 13px;
    right: 8px;
    font-size: 1.25rem;
}

.rate-container {
    text-align: end;
}

.price-container {
    margin-top: 16px;
}

.full-profile-link {
    cursor: pointer;
    display: flex;

    &:hover {
        text-decoration: underline;
    }
}

.button-container {
    .button {
        margin-bottom: 16px;
    }

    .schedule-trial-btn {
        height: $small-button-height;
    }

    @media screen and (min-width: $breakpoint-lg) {
        display: flex;
        gap: 1rem;
    }
}

.icon-gradient-overlay {
    position: relative;
}

.icon-gradient-overlay::before {
    content: '';
    position: absolute;
    top: 7px;
    left: -20px;
    width: 1.5rem;
    height: 1.5rem;
    background: linear-gradient(to right, transparent, white);
    z-index: 0;
}

ion-img::part(image) {
    width: 165px;
    height: 165px;
    border-radius: 5px;
}

@media screen and (max-width: 768px) {
    ion-img::part(image) {
        width: 76px;
        height: 76px;
        border-radius: 5px;
    }
}

@media screen and (max-width: 992px) {
    ion-img::part(image) {
        width: 90px;
        height: 90px;
        border-radius: 5px;
    }
}
</style>
