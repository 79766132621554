<script setup lang="ts">
import { t } from '@/plugins/i18n';
import { ref, computed } from 'vue';
import { IonIcon, IonRow, IonCol } from '@ionic/vue';
import LAvatar from '@shared-components/components/LAvatar.vue';
import LIsland from '@/core/components/LIsland.vue';
import LTimeSlots from '../../components/booking/LTimeSlots.vue';
import LScheduleTimeSkeleton from '../../components/payment/LScheduleTimeSkeleton.vue';
import { TutorProfile } from '../../api/learner.api';

import LButton from '@/core/components/LButton.vue';
import { closestTo, eachDayOfInterval, endOfWeek, getDay, startOfWeek } from 'date-fns';
import { informationCircleOutline, schoolOutline } from 'ionicons/icons';

import LSubjectChip from '@/core/components/LSubjectChip.vue';
import LSegment, { LSegmentOption } from '@/core/components/LSegment.vue';
import { useLocaleDateFormat } from '@/core/utils/use-locale-date-format';
import { useScreenSize } from '@/core/utils/use-screen-size';
import { LearnerRoutes } from '@/router/learner.routes';
import { useI18n } from 'vue-i18n';
import { getTotalPrice, groupTimeSlots } from '../../utils';
import { AvailableSlot } from '../../../tutor/api/calendar.api';
import { ISODateString } from '@common/types';
import { LessonDuration } from '../../types';
import { daysInWeek } from 'date-fns/constants';

const MAX_SELECTED_SLOTS = 5;
const SUBSCRIPTION_WEEKS = 4;

const {
    tutor,
    availableSlots,
    isLoadingProfile,
    isLoadingTimeSlots,
    selectedTimeSlots,
    selectedLessonDuration,
} = defineProps<{
    tutor: TutorProfile;
    availableSlots: AvailableSlot[];
    isLoadingProfile: boolean;
    isLoadingTimeSlots: boolean;
    selectedTimeSlots: ISODateString[];
    selectedLessonDuration: LessonDuration;
}>();

const emits = defineEmits<{
    'select-time-slot': [value: ISODateString];
    'duration-change': [value: LessonDuration];
}>();

const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const { locale } = useI18n();
const format = useLocaleDateFormat();
const { isLargeSize } = useScreenSize();

const selectedDayOfWeek = ref('1');

const dayOfWeekLocalized = computed(() => (locale.value === 'ru' ? 'EEEEEE' : 'EEE'));

const lessonDurationOptions = computed<LSegmentOption[]>(() => [
    {
        value: '60',
        title: t('learner.payment.schedule.lesson50min'),
        caption: t('learner.payment.schedule.priceForMonth', {
            price: tutor.pricing.lesson50Min.regular.toFixed(2),
            currency: '$',
        }),
    },
    {
        value: '30',
        title: t('learner.payment.schedule.lesson25min'),
        caption: t('learner.payment.schedule.priceForMonth', {
            price: tutor.pricing.lesson25Min.regular.toFixed(2),
            currency: '$',
        }),
    },
]);

const weekDaysOptions = computed<LSegmentOption[]>(() =>
    eachDayOfInterval({
        start: startOfWeek(Date.now(), { weekStartsOn: 1 }),
        end: endOfWeek(Date.now(), { weekStartsOn: 1 }),
    }).map((day) => {
        return {
            value: getDay(day).toString(),
            title: format(day, dayOfWeekLocalized.value),
            disabled: !availableSlots.some(
                (slot) => getDay(slot.startTime.toString()) === getDay(day),
            ),
            highlighted: selectedTimeSlots.some((slot) => getDay(slot) === getDay(day)),
        };
    }),
);

const timeByLocation = computed(
    () => `${timeZone.replace('_', ' ')} (${format(new Date(), 'zzzz')})`,
);

const filteredSlots = computed(() => {
    return getAvailableSlotsForDayOfWeek(parseInt(selectedDayOfWeek.value));
});

const closestLesson = computed(() => closestTo(Date.now(), selectedTimeSlots));

const lessonsNumber = computed(() => selectedTimeSlots.length * SUBSCRIPTION_WEEKS);

const totalPrice = computed(() =>
    getTotalPrice(tutor!, selectedLessonDuration, lessonsNumber.value),
);

const isTimeSlotSelected = computed(() => Boolean(selectedTimeSlots.length));

const getAvailableSlotsForDayOfWeek = (dayOfWeek: number) => {
    return availableSlots
        .map((slot) => new Date(slot.startTime as string))
        .filter((slotTime) => getDay(slotTime) === dayOfWeek)
        .map((slotTime) => slotTime.toISOString());
};

const handleSelectedDayChange = (value: string) => (selectedDayOfWeek.value = value);
const handleLessonDurationChange = (value: string) =>
    emits('duration-change', parseInt(value) as LessonDuration);
const handleSelectTimeSlot = (value: ISODateString) => emits('select-time-slot', value);

const groupedTimes = computed(() => groupTimeSlots(selectedTimeSlots, locale.value));
</script>

<template>
    <ion-row class="sm:mb-8 lg:mb-4">
        <ion-col
            size="12"
            size-lg="7"
        >
            <l-island class="h-full">
                <l-schedule-time-skeleton
                    v-if="isLoadingProfile"
                    type="profile"
                />
                <template v-else>
                    <h5 class="pb-8 ion-hide-lg-down">
                        {{ t('learner.onboarding.bookTime.title') }}
                    </h5>

                    <div class="flex gap-4 items-center mb-8">
                        <l-avatar
                            size="xl"
                            :uid="tutor.userUid"
                        />
                        <div class="caption-2">
                            {{ tutor?.tutorName }}
                        </div>
                    </div>

                    <div class="flex gap-2 items-center justify-start">
                        <ion-icon
                            class="text-2xl"
                            :icon="schoolOutline"
                        />

                        <l-subject-chip
                            v-for="code of tutor?.subjectCodes"
                            :icon="null"
                            :code="code"
                        />
                    </div>
                </template>
            </l-island>
        </ion-col>
        <ion-col
            class="ion-hide-lg-down"
            size-lg="5"
        >
            <l-schedule-time-skeleton
                v-if="isLoadingProfile"
                type="video"
            />

            <div
                v-else
                class="pl-4 profile-video-container"
            >
                <iframe
                    :src="tutor?.profileVideo"
                    width="100%"
                    height="250px"
                    allowfullscreen
                ></iframe>
            </div>
        </ion-col>
    </ion-row>
    <ion-row class="mt-8 lg:mt-0">
        <ion-col
            size="12"
            size-lg="7"
        >
            <l-island>
                <l-schedule-time-skeleton
                    v-if="isLoadingTimeSlots"
                    type="schedule"
                />
                <template v-else>
                    <l-segment
                        :selected="selectedLessonDuration.toString()"
                        :options="lessonDurationOptions"
                        @change="handleLessonDurationChange"
                    />

                    <div class="caption-3 pb-4">
                        {{
                            t('learner.payment.schedule.chooseAmountFromTo', {
                                from: 1,
                                n: MAX_SELECTED_SLOTS,
                            })
                        }}
                    </div>

                    <l-segment
                        :selected="selectedDayOfWeek"
                        :options="weekDaysOptions"
                        @change="handleSelectedDayChange"
                    />

                    <div class="body-2 my-8">
                        {{ t('learner.payment.schedule.locationTime', { time: timeByLocation }) }}
                    </div>

                    <l-time-slots
                        class="mb-8"
                        :isLoading="isLoadingTimeSlots"
                        :availableSlots="filteredSlots"
                        :selected="selectedTimeSlots"
                        :disabled="selectedTimeSlots.length >= MAX_SELECTED_SLOTS"
                        @select="handleSelectTimeSlot"
                    />

                    <div
                        class="flex justify-between items-start body-2"
                        v-if="isTimeSlotSelected"
                    >
                        <div>
                            <div>
                                {{ t('learner.payment.schedule.summary.chosen') }}
                                <span class="capitalize">
                                    {{ groupedTimes }}
                                </span>
                            </div>
                            <div>
                                {{
                                    t(
                                        'learner.payment.schedule.summary.period',
                                        daysInWeek * SUBSCRIPTION_WEEKS,
                                    )
                                }}
                            </div>
                            <div v-if="closestLesson">
                                {{ t('learner.payment.schedule.summary.closestDate') }}
                                {{ format(closestLesson!, `${dayOfWeekLocalized}, dd MMM HH:mm`) }}
                            </div>
                            <div>
                                {{
                                    t('learner.payment.schedule.summary.sumUp', {
                                        totalPrice: totalPrice.toFixed(2),
                                        currency: '$',
                                        n: lessonsNumber,
                                    })
                                }}
                            </div>
                        </div>
                        <l-button variant="text">
                            <ion-icon
                                :icon="informationCircleOutline"
                                class="text-2xl"
                            ></ion-icon>
                        </l-button>
                    </div>
                    <div
                        v-else
                        class="body-2"
                    >
                        {{ t('learner.payment.schedule.chooseTimeToContinue') }}
                    </div>

                    <l-button
                        class="my-8 lg:mx-auto"
                        :expand="isLargeSize ? undefined : 'block'"
                        :disabled="!isTimeSlotSelected"
                        :router-link="{
                            name: LearnerRoutes.Checkout,
                            query: {
                                tutorUserUid: tutor.userUid,
                                timeSlots: selectedTimeSlots,
                                lessonDuration: selectedLessonDuration,
                            },
                        }"
                    >
                        {{ t('learner.payment.schedule.goToPayment') }}
                    </l-button>
                </template>
            </l-island>
        </ion-col>
    </ion-row>
</template>

<style scoped lang="scss">
@use '@/css/ui-kit' as *;

.profile-video-container {
    iframe {
        border: 2px solid transparent;
        border-radius: $default-border-radius;
    }
}
</style>
