<script lang="ts" setup>
const props = defineProps<{
    selected: boolean;
    disabled?: boolean;
}>();

const emit = defineEmits<{
    (e: 'click', event: MouseEvent): void;
}>();

const handleClick = (event: MouseEvent) => {
    if (props.disabled) {
        return;
    }

    emit('click', event);
};
</script>

<template>
    <button
        :class="['l-toggle-button', 'caption-3', { selected: props.selected }]"
        :disabled="props.disabled"
        @click="handleClick"
    >
        <slot />
    </button>
</template>

<style scoped lang="scss">
@use '@/css/ui-kit.scss' as *;

.l-toggle-button {
    padding: 10px 16px;
    border-radius: $default-border-radius;
    box-shadow: inset 0 0 0 2px $grey-400;

    &.selected {
        background-color: $grey-300;
        box-shadow: inset 0 0 0 2px $grey-700;
    }
    &:disabled {
        opacity: 0.3;
    }
}
</style>
