import { client } from '@/core/api/client-proxy';
import { RolesUsesFormT } from '@/packages/form/api/form.dto';
import { paths } from '@client';

export type Chat =
    paths['/learner/chats/']['get']['responses']['200']['content']['application/json']['data'][0];
export type GetChatMessagesResponse =
    paths['/learner/chats/{chatIdentifier}/messages']['get']['responses']['200']['content']['application/json'];
export type ChatMessage =
    paths['/learner/chats/{chatIdentifier}/messages']['get']['responses']['200']['content']['application/json']['data']['0'];
export type ChatMessagesMeta =
    paths['/learner/chats/{chatIdentifier}/messages']['get']['responses']['200']['content']['application/json']['meta'];

export class ChatApi {
    basePath: RolesUsesFormT;

    constructor(basePath: RolesUsesFormT) {
        this.basePath = basePath;
    }

    async getChats(): Promise<Chat[]> {
        const { data } = await client.GET(`/${this.basePath}/chats/`, {});

        return data?.data || [];
    }

    async getChatMessages(
        chatIdentifier: string,
        cursorMessageUid?: string,
    ): Promise<GetChatMessagesResponse> {
        const { data } = await client.GET(`/${this.basePath}/chats/{chatIdentifier}/messages`, {
            params: {
                path: {
                    chatIdentifier,
                },
                query: {
                    cursorMessageUid,
                },
            },
        });

        return data!;
    }
}

export const useChatApi = (basePath: RolesUsesFormT) => new ChatApi(basePath);
