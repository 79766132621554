import { defineStore } from 'pinia';
import { AuthApi, SignUpRequestDto, User } from '../api/auth.api';
import * as Sentry from '@sentry/vue';

const authApi = new AuthApi();

export const useAuthStore = defineStore('auth', {
    state: () => ({
        user: {} as User,
    }),
    getters: {
        isAuthenticated: (state) => !!state.user.uid,
    },
    actions: {
        setAuthUser(user: User) {
            this.user = user;

            Sentry.setUser({ id: user.uid });
        },

        async login(email: string, password: string) {
            const res = await authApi.login({ email, password });
            this.user = res.user!;
            return res.success;
        },

        async signUp(signupData: SignUpRequestDto) {
            const res = await authApi.signUp(signupData);
            this.user = res.user!;
            return res.success;
        },

        async logout() {
            this.user = {} as User;
            const res = await authApi.logout();
            if (res.success) {
                location.reload();
            }
            return res.success;
        },
    },
});
