<script setup lang="ts">
import { IonGrid, IonText, IonRow, IonCol, IonIcon } from '@ionic/vue';
import LPage from '@/core/components/LPage.vue';
import LIsland from '@/core/components/LIsland.vue';
import LSubscriptionStatus from '../components/subscriptions/LSubscriptionStatus.vue';
import LSubscriptionItemSkeleton from '../components/subscriptions/LSubscriptionItemSkeleton.vue';
import LDivider from '@/core/components/LDivider.vue';
import LSubjectChip from '@/core/components/LSubjectChip.vue';
import LAvatar from '@shared-components/components/LAvatar.vue';
import LButton from '@/core/components/LButton.vue';
import LLinksArray from '@/core/components/LLinksArray/LLinksArray.vue';
import LLinksArrayItem, {
    LLinksArrayItemProps,
} from '@/core/components/LLinksArray/LLinksArrayItem.vue';

import { useLearnerStore } from '../store/learner.store';
import { computed, onMounted } from 'vue';
import { t } from '@/plugins/i18n';
import { LearnerRoutes } from '@/router/learner.routes';
import {
    timeOutline,
    chevronForward,
    peopleOutline,
    chatbubblesOutline,
    cardOutline,
} from 'ionicons/icons';
import { storeToRefs } from 'pinia';
import { ChatOpeningReason } from '../../chat/types';
import { getSubscriptionCaption, getSubscriptionStatusMessage } from '../utils';
import { SUPPORT_CHAT_UID } from '@common/constants/constant';

type Props = {
    tutorUserUid: string;
};

const learnerStore = useLearnerStore();

const props = defineProps<Props>();

const { isLoading } = storeToRefs(learnerStore);

const subscription = computed(() => learnerStore.getSubscriptionByUid(props.tutorUserUid));

const linkToCancelSubscription = computed(() => ({
    name: LearnerRoutes.Chat,
    params: { chatUid: SUPPORT_CHAT_UID },
    query: {
        reason: ChatOpeningReason.CancelSubscription,
    },
}));

const linkToTutorPage = {
    name: LearnerRoutes.Tutor,
    params: { userUid: props.tutorUserUid },
};

const links = computed<LLinksArrayItemProps[]>(() => [
    {
        beforeIcon: peopleOutline,
        text: changeTutor.value,
        afterIcon: chevronForward,
        to: {
            name: LearnerRoutes.Chat,
            params: { chatUid: SUPPORT_CHAT_UID },
            query: {
                reason: ChatOpeningReason.ChangeTutor,
                meta: JSON.stringify({
                    tutorName: subscription.value?.tutorName,
                }),
            },
        },
    },
    {
        beforeIcon: timeOutline,
        text: changeLessonDuration.value,
        afterIcon: chevronForward,
        to: {
            name: LearnerRoutes.Chat,
            params: { chatUid: SUPPORT_CHAT_UID },
            query: {
                reason: ChatOpeningReason.ChangeLessonDuration,
                meta: JSON.stringify({
                    tutorName: subscription.value?.tutorName,
                }),
            },
        },
    },
    {
        beforeIcon: chatbubblesOutline,
        text: supportService.value,
        afterIcon: chevronForward,
        to: {
            name: LearnerRoutes.Chat,
            params: { chatUid: SUPPORT_CHAT_UID },
        },
    },
    {
        beforeIcon: cardOutline,
        text: changePaymentMethod.value,
        afterIcon: chevronForward,
        to: {
            name: LearnerRoutes.PaymentMethods,
        },
    },
]);

onMounted(() => fetchSubs());

const fetchSubs = () => {
    if (!subscription.value) {
        learnerStore.fetchSubscriptions();
    }
};

const title = computed(() => t('learner.subscriptions.details.title'));
const cancelSubscription = computed(() => t('learner.subscriptions.links.cancel'));
const supportService = computed(() => t('learner.subscriptions.links.support'));
const changePaymentMethod = computed(() => t('learner.subscriptions.links.changePaymentMethod'));
const changeLessonDuration = computed(() => t('learner.subscriptions.links.changeLessonDuration'));
const changeTutor = computed(() => t('learner.subscriptions.links.changeTutor'));

// TODO: use component LLessonDuration
const lessonDuration = (duration: number) =>
    t('learner.subscriptions.lessonDuration', { n: duration });
</script>

<template>
    <l-page class="page-container">
        <ion-row class="mt-4">
            <ion-col size="12">
                <l-island>
                    <h5 class="pb-8">{{ title }}</h5>

                    <LSubscriptionItemSkeleton v-if="isLoading.subscriptions" />

                    <template v-else>
                        <ion-grid class="ion-hide-lg-up">
                            <ion-row class="mb-4">
                                <ion-col>
                                    <ion-row class="items-center">
                                        <l-avatar
                                            :uid="subscription?.tutorUserUid"
                                            :alt="subscription?.tutorName"
                                            class="mr-4"
                                            size="xl"
                                        />
                                        <ion-text class="caption-2">{{
                                            subscription?.tutorName
                                        }}</ion-text>
                                    </ion-row>
                                </ion-col>
                                <ion-col
                                    size="auto"
                                    class="flex items-center"
                                >
                                    <l-button
                                        color="tertiary"
                                        variant="text"
                                        size="small"
                                        :router-link="linkToTutorPage"
                                    >
                                        <ion-icon
                                            :icon="chevronForward"
                                            class="text-2xl"
                                            slot="icon-only"
                                        ></ion-icon>
                                    </l-button>
                                </ion-col>
                            </ion-row>
                            <ion-row class="mb-4">
                                <ion-col size="auto">
                                    <l-subject-chip
                                        class="mr-2"
                                        :code="subscription?.subjectCode"
                                    />
                                </ion-col>
                                <ion-col class="flex items-center gap-1">
                                    <ion-icon
                                        :icon="timeOutline"
                                        class="text-2xl"
                                        color="tertiary"
                                    />
                                    {{ lessonDuration(subscription?.lessonDuration!) }}
                                </ion-col>
                            </ion-row>
                            <ion-row>
                                <l-subscription-status
                                    :status="subscription?.status!"
                                    :message="getSubscriptionStatusMessage(subscription!)"
                                ></l-subscription-status>
                            </ion-row>
                            <l-divider />
                            <ion-row>
                                <ion-text
                                    class="body-2"
                                    color="tertiary"
                                    >{{ getSubscriptionCaption(subscription!) }}
                                </ion-text>
                            </ion-row>

                            <ion-row class="mt-8">
                                <ion-col size="12">
                                    <l-links-array>
                                        <l-links-array-item
                                            v-for="link of links"
                                            :key="link.text"
                                            :to="link.to"
                                            :before-icon="link.beforeIcon"
                                            :after-icon="link.afterIcon"
                                            :text="link.text"
                                        />
                                    </l-links-array>
                                    <l-button
                                        class="mt-4"
                                        variant="text"
                                        expand="block"
                                        color="danger"
                                        size="small"
                                        :router-link="linkToCancelSubscription"
                                        >{{ cancelSubscription }}</l-button
                                    >
                                </ion-col>
                            </ion-row>
                        </ion-grid>
                        <!-- NOTE: desktop layout start here -->
                        <ion-grid class="ion-hide-lg-down">
                            <ion-row class="mb-4">
                                <ion-col>
                                    <ion-row class="items-center">
                                        <l-avatar
                                            :uid="subscription?.tutorUserUid"
                                            :alt="subscription?.tutorName"
                                            class="mr-4"
                                            size="xl"
                                        />
                                        <ion-text class="caption-2 me-4">{{
                                            subscription?.tutorName
                                        }}</ion-text>
                                        <l-subject-chip
                                            class="mr-4"
                                            :code="subscription?.subjectCode"
                                        />
                                        <ion-col class="flex items-center gap-1">
                                            <ion-icon
                                                :icon="timeOutline"
                                                class="text-2xl"
                                                color="tertiary"
                                            />
                                            {{ lessonDuration(subscription?.lessonDuration!) }}
                                        </ion-col>
                                    </ion-row>
                                </ion-col>
                                <ion-col
                                    size="auto"
                                    class="flex items-center"
                                >
                                    <l-button
                                        color="tertiary"
                                        variant="text"
                                        size="small"
                                        :router-link="linkToTutorPage"
                                    >
                                        <ion-icon
                                            :icon="chevronForward"
                                            class="text-2xl"
                                            slot="icon-only"
                                        ></ion-icon>
                                    </l-button>
                                </ion-col>
                            </ion-row>

                            <ion-row>
                                <ion-col size="6">
                                    <l-subscription-status
                                        class="w-fit mb-4"
                                        :status="subscription?.status!"
                                        :message="getSubscriptionStatusMessage(subscription!)"
                                    ></l-subscription-status>

                                    <ion-text
                                        class="body-2"
                                        color="tertiary"
                                        >{{ getSubscriptionCaption(subscription!) }}
                                    </ion-text>
                                </ion-col>

                                <ion-col
                                    size="5"
                                    offset="1"
                                >
                                    <l-links-array>
                                        <l-links-array-item
                                            v-for="link of links"
                                            :key="link.text"
                                            :to="link.to"
                                            :before-icon="link.beforeIcon"
                                            :after-icon="link.afterIcon"
                                            :text="link.text"
                                        />
                                    </l-links-array>

                                    <l-button
                                        class="mt-4"
                                        variant="text"
                                        expand="block"
                                        size="small"
                                        color="danger"
                                        :router-link="linkToCancelSubscription"
                                        >{{ cancelSubscription }}</l-button
                                    >
                                </ion-col>
                            </ion-row>
                        </ion-grid>
                    </template>
                </l-island>
            </ion-col>
        </ion-row>
    </l-page>
</template>

<style scoped>
.page-container {
    --ion-grid-padding: 0;
    --ion-grid-column-padding: 0;
}
</style>
