<script setup lang="ts">
import { Maybe } from '@common/types';
import { IonSkeletonText } from '@ionic/vue';
import { computed } from 'vue';

const { length } = defineProps<{
    length: Maybe<number>;
}>();

const defaultLen = 5;
const len = computed(() => length ?? defaultLen);
const items = new Array(len.value).fill(null);
</script>

<template>
    <ion-skeleton-text
        v-for="(_, i) in items"
        :key="i"
        class="rounded-md w-full h-20 my-3"
        animated
    />
</template>
