<script lang="ts" setup>
import { computed } from 'vue';
import LChip from './LChip.vue';
import { useScreenSize } from '../utils/use-screen-size';
import { IonIcon, IonItem, IonLabel, IonList, IonPopover } from '@ionic/vue';
import { t } from '@/plugins/i18n';

const props = defineProps<{
    icon?: string;
    list: string[];
}>();

const { isLargeSize } = useScreenSize();

const getLabel = (code: string) => {
    if (code) {
        return code.startsWith('+') ? code : t(`components.LSubjectChip.codes.${code}`, 'Unset');
    }
};

const chipDisplayList = computed(() => {
    if (isLargeSize.value || props.list.length === 1) {
        return props.list;
    }
    return [props.list[0], `+${props.list.length - 1}`];
});
</script>

<template>
    <div
        v-if="list.length > 0"
        class="chip-list my-6"
    >
        <div
            v-if="icon"
            class="chip-icon"
        >
            <ion-icon
                class="text-2xl"
                :icon="icon"
            />
        </div>

        <div class="chips">
            <l-chip
                v-for="(item, index) in chipDisplayList"
                :key="index"
                :outline="true"
            >
                <ion-label class="body-1">{{ getLabel(item) }}</ion-label>
            </l-chip>
        </div>
    </div>
</template>

<style scoped lang="scss">
@use '@/css/ui-kit.scss' as *;

.chip-list {
    display: flex;
    align-items: center;

    .chip-icon {
        margin-right: 0.5rem;
        display: flex;
    }

    .chips {
        display: flex;
        gap: 4px;
    }
}
</style>
