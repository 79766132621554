<script setup lang="ts">
import { t } from '@/plugins/i18n';

import LVerificationForm from '@/modules/auth/components/verification/LVerificationForm.vue';
import { timeOutline } from 'ionicons/icons';
import LButton from '@/core/components/LButton.vue';

import { IonText, IonIcon, useIonRouter, IonCol, IonRow } from '@ionic/vue';
import { TutorProfile, useLearnerApi } from '../../api/learner.api';
import { useLearnerStore } from '../../store/learner.store';
import { getDatesDiffInMinutes } from '@common/utils/date-formatters';
import { LearnerRoutes } from '@/router/learner.routes';
import { useRoute } from 'vue-router';
import LIsland from '@/core/components/LIsland.vue';
import LDivider from '@/core/components/LDivider.vue';
import LChipList from '@/core/components/LChipList.vue';
import { useScreenSize } from '@/core/utils/use-screen-size';
import LAvatar from '@shared-components/components/LAvatar.vue';
import { useLocaleDateFormat } from '@/core/utils/use-locale-date-format';
import { LESSON_DATE_FORMAT } from '../../constants';

const learnerStore = useLearnerStore();
const props = defineProps<{ tutor: TutorProfile; startDate: Date; endDate: Date }>();
const router = useIonRouter();
const route = useRoute();
const { isLargeSize } = useScreenSize();
const formatLocale = useLocaleDateFormat();

const onVerificationSuccess = async () => {
    submitTrialLesson();
};

const learnerApi = useLearnerApi();

const lessonDurationMinutes = getDatesDiffInMinutes(props.startDate, props.endDate);

const submitTrialLesson = async () => {
    const { success } = await learnerApi.scheduleTrialLesson({
        tutorUserUid: props.tutor.userUid,
        endTime: props.endDate.toISOString(),
        startTime: props.startDate.toISOString(),
        subjectCode: 'english',
    });
    if (success) {
        await learnerStore.fetch();
        router.push({ name: LearnerRoutes.SuccessBooking, query: route.query });
    }
};

const getFormattedLessonDateTime = () => {
    return formatLocale(new Date(props.startDate), LESSON_DATE_FORMAT);
};
</script>

<template>
    <ion-row>
        <ion-col
            size-lg="5"
            size-xs="12"
        >
            <l-island>
                <div class="tutor-info mb-8">
                    <l-avatar
                        size="xl"
                        :src="tutor.tutorAvatar"
                        :alt="tutor.tutorName"
                    />
                    <p class="caption-2 ml-4">
                        {{ tutor.tutorName }}
                    </p>
                </div>

                <l-divider v-if="!isLargeSize" />

                <h5 class="mb-4 mt-8">{{ t('learner.onboarding.trialPreview.trialLesson') }}</h5>

                <div class="caption-3">{{ getFormattedLessonDateTime() }}</div>

                <div class="flex items-center">
                    <l-chip-list :list="tutor.subjectCodes!" />
                    <ion-icon
                        class="ml-2"
                        color="tertiary"
                        :icon="timeOutline"
                    />
                    <ion-text class="body-2"> {{ lessonDurationMinutes }} min </ion-text>
                </div>

                <div class="booking-details mt-2 mb-4">
                    <div class="caption-2">
                        {{ t('learner.onboarding.trialPreview.bookingDetails') }}
                    </div>
                    <div class="price mt-4">
                        <div class="body-1 flex mt-1">
                            <div class="text-left w-9/12">
                                {{ t('learner.onboarding.trialPreview.trialLesson') }}
                            </div>
                            <div class="text-right w-3/12">
                                {{ tutor.pricing.lesson25Min.regular }}$
                            </div>
                        </div>
                        <div class="body-1 flex mt-1">
                            <div class="text-left w-9/12">
                                {{ t('learner.onboarding.trialPreview.discount') }}
                            </div>
                            <div class="text-right w-3/12">100%</div>
                        </div>
                        <div class="caption-2 flex mt-2">
                            <div class="text-left w-9/12">
                                {{ t('learner.onboarding.trialPreview.total') }}
                            </div>
                            <div class="text-right w-3/12">0 $</div>
                        </div>
                    </div>
                </div>
            </l-island>
        </ion-col>
        <l-divider />

        <ion-col
            size-lg="1"
            size-xs="0"
        ></ion-col>
        <ion-col
            size-lg="5"
            size-xs="12"
        >
            <l-island>
                <l-divider v-if="!isLargeSize" />
                <div :class="{ 'mt-8': !isLargeSize }">
                    <l-verification-form
                        @verification-success="onVerificationSuccess"
                        v-if="!learnerStore.profile.user.emailVerified"
                    />
                    <div v-else>
                        <p class="caption-2 text-center">
                            {{ t('learner.classDetails.bookingConfirmationTitle') }}
                        </p>
                        <p class="body-1 mt-4 text-center mb-8">
                            {{ t('learner.classDetails.bookingConfirmationDesc') }}
                        </p>
                        <l-button
                            expand="block"
                            @click="submitTrialLesson"
                        >
                            {{ t('learner.classDetails.submitClassButton') }}
                        </l-button>
                        <p class="body-3 mt-2 text-center">
                            {{ t('learner.classDetails.agreement') }}
                        </p>
                    </div>
                </div>
            </l-island>
        </ion-col>
    </ion-row>
</template>

<style lang="scss" scoped>
@use '@/css/ui-kit.scss' as *;

.tutor-info {
    display: flex;
    align-items: center;
}

.line {
    height: 1px;
    background-color: $grey-300;
}

.wrapper {
    // background-color: $grey-100;
}

.price > div {
}
</style>
