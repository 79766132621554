<script setup lang="ts">
import LPage from '@/core/components/LPage.vue';
import LIsland from '@/core/components/LIsland.vue';
import LLessonList from '../components/home/LLessonList.vue';
import FindTutorSection from '../components/home/FindTutorSection.vue';
import { computed, onMounted, ref } from 'vue';
import { useCalendarStore } from '../store/calendar.store';
import { useRoute } from 'vue-router';
import { LearnerRoutes } from '@/router/learner.routes';
import { LessonScheduleType, LessonType } from '../types';
import { storeToRefs } from 'pinia';

const calendarStore = useCalendarStore();
const route = useRoute();

const { isTrialLessonCancelled } = storeToRefs(calendarStore);

const isNextLessons = computed(() => route.name === LearnerRoutes.NextLessons);
const listType = computed(() =>
    isNextLessons.value ? LessonScheduleType.Next : LessonScheduleType.Past,
);

onMounted(() => Promise.all([fetchLessonsByScheduleType(), checkCancelledTrialLesson()]));

const fetchLessonsByScheduleType = async () => {
    await calendarStore.fetchLessonsByType(listType.value);
};

const checkCancelledTrialLesson = async () => {
    await calendarStore.checkIsTrialLessonCancelled();
};
</script>

<template>
    <l-page class="page-container">
        <find-tutor-section v-if="isTrialLessonCancelled" />

        <l-island v-else>
            <l-lesson-list :list-type="listType" />
        </l-island>
    </l-page>
</template>

<style scoped>
.page-container {
    --ion-grid-padding: 0;
    --ion-grid-column-padding: 0;
}
</style>
