<script setup lang="ts">
import { arrowForwardOutline } from 'ionicons/icons';
import LButton from '@/core/components/LButton.vue';
import LMessage from '@/core/components/LMessage.vue';
import { IonIcon, IonCol, IonRow } from '@ionic/vue';
import { computed } from 'vue';
import { LessonScheduleType } from '../../types';
import { t } from '@/plugins/i18n';

interface Props {
    listType: LessonScheduleType;
}
defineProps<Props>();

const noLessonText1 = computed(() => t('learner.home.noLessonsText1'));
const noLessonText2 = computed(() => t('learner.home.noLessonsText2'));
const findTutorText = computed(() => t('learner.home.findTutor'));
const noFinishedLessonsText = computed(() => t('learner.home.noFinishedLessonsText'));
</script>

<!-- TODO: complete the component according to the UI-layout -->
<template>
    <div
        v-if="listType === LessonScheduleType.Next"
        class="text-center max-w-96 m-auto"
    >
        <img
            src="/assets/learner-next-lesson-void.svg?url"
            alt="my-logo"
        />
        <div class="pb-4">
            {{ noLessonText1 }}
            <br />
            {{ noLessonText2 }}
        </div>

        <div>
            <l-button size="small">
                {{ findTutorText }}
                <ion-icon
                    :icon="arrowForwardOutline"
                    class="ml-3"
                />
            </l-button>
        </div>
    </div>

    <ion-row v-else>
        <ion-col
            size="auto"
            class="pl-0"
        >
            <img
                src="/assets/learner-prev-lesson-void.svg?url"
                alt="No previous lessons illustration"
            />
        </ion-col>
        <ion-col>
            <l-message
                :message="noFinishedLessonsText"
                :max-chars="500"
                tail-position="left-up"
                show-tail
            />
        </ion-col>
    </ion-row>
</template>
