<script setup lang="ts">
import { IonRow, IonCol, IonIcon, IonText } from '@ionic/vue';
import { useUserApi } from '@/modules/user/api/user.api';
import { useLearnerStore } from '../store/learner.store';
import { chevronForward } from 'ionicons/icons';
import { logInOutline } from 'ionicons/icons';
import { CommonRoutes } from '@/router/common.routes';
import { useAuthStore } from '@/modules/auth/store/auth.store';
import { useRouter } from 'vue-router';
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import { t } from '@/plugins/i18n';
import { emailRegExp } from '@common/utils/email-regexp';
import LLanguageSwitcher from '@/core/components/LLanguageSwitcher.vue';
import LInput from '@/core/components/LInputSettings.vue';
import LearnerName from '../components/settings/LearnerName.vue';
import LPage from '@/core/components/LPage.vue';
import LIsland from '@/core/components/LIsland.vue';
import { LearnerRoutes } from '@/router/learner.routes';
import { useNotification } from '@/core/composables/use-notification';
import InputForm from '@/modules/learner/components/settings/LearnerInputMobEmail.vue';
import { storeToRefs } from 'pinia';

const learnerStore = useLearnerStore();
const notify = useNotification();

const title = computed(() => t('learner.settings.title'));
const language = computed(() => t('learner.settings.language'));
const changePassword = computed(() => t('learner.settings.changePassword'));
const logoutText = computed(() => t('auth.logout'));
const { profile } = storeToRefs(learnerStore);

const learnerEmail = computed(() => profile.value.user.email);

const error = ref<string>('');
const authStore = useAuthStore();
const router = useRouter();
const userApi = useUserApi();

// validation
const isEmailValidationFailed = ref(false);
const validateEmail = (emailValue: string) => {
    const result = emailValue.match(emailRegExp);
    isEmailValidationFailed.value = !result;

    return result;
};

const windowWidth = ref(window.innerWidth);

const updateWindowWidth = () => {
    windowWidth.value = window.innerWidth;
};

onMounted(() => {
    window.addEventListener('resize', updateWindowWidth);
});

onBeforeUnmount(() => {
    window.removeEventListener('resize', updateWindowWidth);
});

const isMobile = computed(() => windowWidth.value <= 992);

const newEmail = ref(learnerEmail.value);
let emailChanged = false;

watch(newEmail, (newVal, oldVal) => {
    emailChanged = newVal !== oldVal;
});

const showNotification = () => {
    notify.create({
        mode: 'modal',
        duration: 0,
        title: t('learner.verification.successChangeEmailTitle'),
        message: t('learner.verification.successChangeEmail'),
        slotComponent: InputForm,
        slotProps: {},
        closable: true,
        onClose: () => {
            newEmail.value = profile.value.user.email;
        },
    });
};

const removeFocusFromAllInputs = () => {
    const inputs = document.querySelectorAll('input, textarea, select');
    inputs.forEach((input) => {
        input.blur();
    });
};

const onIconClick = async () => {
    if (!window.matchMedia('(max-width: 992px)').matches) {
        if (emailChanged) {
            return;
        }
        removeFocusFromAllInputs();

        if (validateEmail(newEmail.value)) {
            await changeEmail();

            if (!isEmailValidationFailed.value) {
                showSuccessMessage();
            }
        } else {
            error.value = t('learner.verification.emailError');
        }
    } else {
        showNotification();
    }
};

const onBlur = () => {
    if (!window.matchMedia('(max-width: 992px)').matches) {
        onIconClick();
    }
};

const onClick = () => {
    if (window.matchMedia('(max-width: 992px)').matches) {
        showNotification();
    }
};

// actions
const onLogoutClick = async () => {
    await authStore.logout();
};

const changeEmail = async () => {
    const response = await userApi.patchLearnerData({ email: newEmail.value });
    if (!response.error) {
        await learnerStore.fetch();
        learnerEmail.value = learnerStore.profile.user.email;
    } else {
        error.value = t('learner.verification.emailError');
        isEmailValidationFailed.value = true;
    }
};

const showSuccessMessage = () => {
    notify.success({
        mode: 'notification',
        type: 'success',
        duration: 2000,
        title: t('learner.verification.successChangeEmailTitle'),
        message: t('learner.verification.successChangeEmail'),
    });
};
</script>

<template>
    <l-page class="page-container">
        <ion-row>
            <ion-col
                size="12"
                size-lg="6"
            >
                <ion-row class="gap-y-12">
                    <l-island>
                        <LearnerName />
                        <ion-col class="pl-0">
                            <h5 class="py-8">{{ title }}</h5>
                            <div class="flex flex-col gap-2 pb-8">
                                <ion-text class="font-normal text-base">{{ language }}</ion-text>
                                <l-language-switcher />
                            </div>
                            <div class="flex flex-col gap-0.5">
                                <div class="l-links-array">
                                    <div class="link-item">
                                        <l-input
                                            class="settings"
                                            :showIcon="true"
                                            label="Email"
                                            type="email"
                                            v-model="learnerEmail"
                                            @blur="onBlur"
                                            @click="onClick"
                                            @iconClick="onIconClick"
                                            :errorMessage="error"
                                            :danger="error"
                                            :disabled="isMobile"
                                        />
                                    </div>
                                    <div class="link-item">
                                        <div class="min-h-14">
                                            <router-link
                                                class="min-h-14 flex flex-row justify-between w-full my-auto"
                                                :to="{ name: LearnerRoutes.SettingsPassword }"
                                            >
                                                <ion-text class="text-base my-auto pl-2">
                                                    {{ changePassword }}
                                                </ion-text>
                                                <ion-icon
                                                    class="me-2 my-auto"
                                                    color="dark"
                                                    :icon="chevronForward"
                                                />
                                            </router-link>
                                        </div>
                                    </div>
                                    <div class="link-item">
                                        <div
                                            @click="onLogoutClick"
                                            size="small"
                                            slot="end"
                                            class="flex flex-row py-2 gap-2 pl-2 cursor-pointer"
                                        >
                                            <ion-icon
                                                slot="start"
                                                :icon="logInOutline"
                                                class="text-xl"
                                                color="dark"
                                            />
                                            <span>{{ logoutText }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ion-col>
                    </l-island>
                </ion-row>
            </ion-col>
        </ion-row>
    </l-page>
</template>

<style lang="scss" scoped>
@use '@/css/ui-kit' as *;

.page-container {
    --ion-grid-padding: 0;
    --ion-grid-column-padding: 0;
}

.l-links-array {
    display: flex;
    flex-direction: column;
    align-items: center;

    .link-item {
        flex: 1 1 100%;
        width: 100%;
        border: 0.125rem solid #b0afb2;
        border-top: none;
        border-bottom: none;
        background-color: $grey-100;
        min-height: 3.5rem;

        &:hover {
            background-color: $grey-300;
        }

        &:first-child {
            border-top-left-radius: 0.75rem;
            border-top-right-radius: 0.75rem;
            border: 2px solid #b0afb2;
        }

        &:last-child {
            border-bottom-left-radius: 0.75rem;
            border-bottom-right-radius: 0.75rem;
            border: 2px solid #b0afb2;
            padding: 5px 0;
        }
    }

    .icon {
        font-size: 1.5rem;
    }
}
</style>
