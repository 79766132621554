<template>
    <transition
        name="notify"
        :css="!isModal"
    >
        <div
            v-if="notification"
            :class="['l-notification', { modal: isModal }]"
            :key="notification.id"
        >
            <div class="content">
                <component
                    v-if="notification?.slotComponent"
                    :is="notification.slotComponent"
                    v-bind="notification.slotProps"
                />
                <div
                    v-if="type || notification?.title"
                    class="flex gap-x-2 w-full mb-2"
                >
                    <div
                        v-if="type"
                        class="h-6"
                    >
                        <ion-icon
                            :icon="icon"
                            :class="['text-2xl', type]"
                        ></ion-icon>
                    </div>
                    <div class="flex flex-col gap-2 w-full">
                        <template v-if="notification?.title">
                            <div class="caption-2">{{ notification?.title }}</div>
                            <div
                                v-if="notification?.message"
                                class="body-1"
                            >
                                {{ notification?.message }}
                            </div>
                        </template>
                    </div>
                </div>

                <l-button
                    v-if="notification?.closable"
                    class="close"
                    variant="text"
                    @click="close"
                >
                    <ion-icon
                        :icon="closeCircleOutline"
                        class="text-2xl"
                        slot="icon-only"
                    ></ion-icon>
                </l-button>
            </div>
        </div>
    </transition>
    <div
        v-if="notification && isModal"
        class="backdrop"
        :key="notification.id"
    ></div>
</template>

<script setup lang="ts">
import { computed, onUnmounted, watchEffect } from 'vue';
import { IonIcon } from '@ionic/vue';
import { useNotification } from '@/core/composables/use-notification';
import {
    checkmarkCircle,
    alertCircle,
    closeCircleOutline,
    warning,
    informationCircle,
} from 'ionicons/icons';
import LButton from './LButton.vue';

const { notifications, closeById } = useNotification();

const notification = computed(() => notifications.value[0]);
const type = computed(() => notification.value.type);
const isModal = computed(() => notification.value?.mode === 'modal');

const icon = computed(() => {
    switch (type.value) {
        case 'success':
            return checkmarkCircle;
        case 'error':
            return alertCircle;
        case 'warning':
            return warning;
        case 'info':
            return informationCircle;
        default:
            return undefined;
    }
});

const handleEscape = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
        close();
    }
};

watchEffect(() => {
    if (isModal.value) {
        window.addEventListener('keydown', handleEscape);
    } else {
        window.removeEventListener('keydown', handleEscape);
    }
});

onUnmounted(() => {
    window.removeEventListener('keydown', handleEscape);
});

const close = () => {
    if (notification.value) {
        closeById(notification.value.id);
    }
};
</script>

<style scoped lang="scss">
@use '@/css/ui-kit' as *;

$border-radius: 1rem;
$min-width-mobile: 100%;
$min-width-desktop: 30rem;
$max-width-desktop: 33%;
$z-index: 20000;
$backdrop-bg: rgba(244, 242, 247, 0.8);
$border-width: 2px;

.l-notification {
    z-index: #{$z-index + 1};

    position: absolute;
    left: 50%;
    transform-origin: center;
    transform: translate(-50%);

    min-width: calc(#{$min-width-mobile} - 1rem);

    border-radius: $border-radius;

    padding: 1rem 1rem 1.5rem 1rem;
    bottom: 0.625rem;

    background-color: $grey-100;

    box-shadow:
        inset 0 0 0 #{$border-width} $grey-800,
        0px -4px 20px 0px rgba(0, 0, 0, 0.2),
        0px 0px 0px 0px rgba(0, 0, 0, 0.15);

    .content {
        display: flex;
        align-items: start;
        flex-direction: column-reverse;
        width: 100%;
        row-gap: 0.5rem;
    }

    &.modal {
        min-width: $min-width-mobile;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        padding-bottom: 2rem;
        bottom: 0;
    }

    .close {
        // FIXME: icon buttons has 34px size
        margin: -5px;
        align-self: flex-end;
    }

    // NOTE: desktop
    @media screen and (min-width: $breakpoint-lg) {
        min-width: $min-width-desktop;
        width: fit-content;
        max-width: $max-width-desktop;

        bottom: 1rem;
        padding: 1rem;

        border-radius: $border-radius;

        box-shadow:
            inset 0 0 0 #{$border-width} $grey-800,
            0px 4px 20px 0px rgba(0, 0, 0, 0.2),
            0px 4px 8px 0px rgba(0, 0, 0, 0.15);

        &.modal {
            left: 50%;
            bottom: 50%;
            transform-origin: center;
            transform: translate(-50%, 50%);
            border-radius: $border-radius;
            min-width: $min-width-desktop;
            padding-bottom: 1.5rem;
        }
    }

    .success {
        color: $green-dark;
    }
    .error {
        color: $red-dark;
    }
    .warning {
        color: $yellow-medium;
    }
    .info {
        color: $grey-800;
    }
}

.backdrop {
    position: absolute;
    inset: 0;
    z-index: $z-index;
    background-color: $backdrop-bg;
}

.notify-enter-active,
.notify-leave-active {
    transition:
        opacity 0.3s,
        transform 0.3s;
}

.notify-enter-from,
.notify-leave-to {
    opacity: 0;
    transform: translate(-50%) translateY(2rem);
}

.notify-enter-to,
.notify-leave-from {
    opacity: 1;
    transform: translate(-50%) translateY(0);
}
</style>
