<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { useTutorCatalogStore } from '../../store/tutors-catalog.store';

const props = defineProps({
    activeCardPosition: Number,
});
const { activeTutor, tutors } = storeToRefs(useTutorCatalogStore());

const today = new Date();
const endDateUnFormatted = new Date(today);
endDateUnFormatted.setDate(today.getDate() + 6);
</script>

<template>
    <transition name="move-transition">
        <div
            v-show="activeTutor !== null"
            class="right-card"
            :style="`top: ${props.activeCardPosition}px ; `"
        >
            <div class="video-container">
                <iframe
                    :src="tutors![activeTutor!]?.profileVideo"
                    width="100%"
                    height="230px"
                    style="border: none; border-radius: 24px"
                >
                </iframe>
            </div>
        </div>
    </transition>
</template>

<style scoped>
/* Стили для правой карточки */
.right-card {
    position: relative;
    transition: all 0.5s ease;
    margin-left: 2rem;
}

.video-container video {
    width: 100%;
    height: auto;
}

.schedule-container {
    margin: 16px 0;
}

.view-full-schedule {
    text-align: center;
    margin-top: 16px;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
}
</style>
