<script setup lang="ts">
import { Model } from 'survey-core';
import { SurveyComponent } from 'survey-vue3-ui';
import { onMounted, Ref, ref, watch } from 'vue';
import { IonButton, IonIcon, IonLoading, IonText } from '@ionic/vue';
import { useI18n } from 'vue-i18n';
import { useAuthStore } from '@/modules/auth/store/auth.store';
import { t } from '@/plugins/i18n';
import { theme } from './l-form.theme';
import { ILatestResponse, RolesUsesFormT } from '../api/form.dto';
import { useFormApi } from '../api/form.api';
import { useFormHandlers } from './l-form-handlers';
import { useFormSubmission } from './l-form-submission';
import { arrowForwardOutline, chevronBackOutline } from 'ionicons/icons';
import LButton from '@/core/components/LButton.vue';
import { useRouter } from 'vue-router';

const props = defineProps({
    code: {
        type: String,
        required: true,
    },
    oncomplete: {
        type: Function,
    },
    nextStepBtnText: {
        type: String,
    },
    completeSurveyBtnText: {
        type: String,
    },
});
let survey: Model | null = null;
const formCompleted = ref<boolean>(false);
const formData = ref<Record<string, any> | null>(null);
const loading = ref(true);
const formSchemaError = ref(false);
const latestResponse = ref(null) as Ref<ILatestResponse | null | undefined>;
const { user } = useAuthStore();

const role = user.role as RolesUsesFormT;
const formApi = useFormApi(role);

const { onUploadFiles, onClearFiles } = useFormHandlers(role, latestResponse, props.code);
const { onSubmit, onNextClick } = useFormSubmission(
    role,
    latestResponse,
    props.code,
    formCompleted,
);

const { locale } = useI18n();

watch(locale, (newLang) => {
    if (survey) {
        survey.locale = newLang;
    }
});

const hasFileUploadField = (surveyJson: any): boolean =>
    surveyJson.pages.some((page: any) =>
        page.elements.some((element: any) => element.type === 'file'),
    );

const loadForm = async () => {
    try {
        const form = await formApi.getFormSchema(props.code);
        if (!form.success) {
            console.error('form schema not found');
            formSchemaError.value = true;
            return;
        }
        latestResponse.value = form.latestResponse;

        const { schema } = form;

        if (latestResponse.value?.uid) {
            const responses = await formApi.getFormResponse({
                code: props.code,
                uid: latestResponse.value.uid,
            });
            if (responses.success && responses.data?.content) {
                formData.value = JSON.parse(responses.data?.content);
            }
        }

        const newSurvey = new Model(schema);
        newSurvey.onComplete.add(onSubmit.bind(this, newSurvey, props.oncomplete));
        newSurvey.onServerValidateQuestions.add(onNextClick);

        newSurvey.currentPage = formData.value?.page || 0;
        newSurvey.data = formData.value || {};
        survey = newSurvey;
        survey.locale = locale.value;
        survey.showNavigationButtons = 'none';
        survey.applyTheme(theme as any);
        if (latestResponse.value?.status === 'submitted') {
            survey.doComplete();
        }

        if (hasFileUploadField(JSON.parse(schema))) {
            const questions = survey.getAllQuestions();
            questions.forEach((question) => {
                const type = question.getType();
                if (type === 'file') {
                    question.storeDataAsText = false;
                }
            });

            survey.onUploadFiles.add((sender, options) => {
                const { files, callback } = options;
                const onComplete = callback;
                onUploadFiles(sender, files, onComplete);
            });

            survey.onClearFiles.add((sender, options) => {
                const { question, fileName } = options;
                if (question.value) {
                    const urlsToDelete = fileName
                        ? question.value.filter((file: any) => file.name === fileName)[0].content
                        : question.value.map((file: any) => file.content);
                    const onComplete = options.callback;

                    onClearFiles(sender, urlsToDelete, onComplete);
                } else {
                    options.callback('success');
                }
            });
        }
    } catch (error) {
        console.error('Error loading form:', error);
    } finally {
        loading.value = false;
    }
};

const goToPreviousPage = () => {
    if (survey && !survey.isFirstPage) {
        survey.prevPage();
    }
};

const completeSurveyHandler = async () => {
    if (survey) {
        survey.completeLastPage();
    }
};

onMounted(loadForm);
</script>

<template>
    <div class="container">
        <ion-loading
            v-if="loading"
            message="Loading..."
            duration="3000"
            spinner="circles"
        ></ion-loading>
        <div
            class="form-container"
            v-else-if="survey && !formSchemaError"
        >
            <survey-component
                v-show="survey"
                :model="survey"
            />
            <div
                id="nav-btn"
                v-if="survey && !formCompleted"
            >
                <l-button
                    v-if="!survey.isLastPage"
                    @click="survey.nextPage()"
                >
                    {{ props.nextStepBtnText || t('form.nextStep') }}
                    <ion-icon :icon="arrowForwardOutline"></ion-icon>
                </l-button>

                <l-button
                    expand="block"
                    variant="tertiary"
                    v-if="!survey.isFirstPage"
                    @click="goToPreviousPage"
                >
                    <ion-icon
                        color="dark"
                        :icon="chevronBackOutline"
                        slot="start"
                    ></ion-icon>
                    <ion-text color="dark">{{ t('form.prevStep') }}</ion-text>
                </l-button>
                <l-button
                    v-if="survey.isLastPage"
                    @click="completeSurveyHandler"
                    >{{ props.completeSurveyBtnText || t('form.complete') }}</l-button
                >
            </div>
        </div>
        <div v-else>
            <ion-text color="warning">
                <h1 style="padding: 25px">DEV ONLY!!! FORM CODE - "{{ props.code }}"</h1>
            </ion-text>
        </div>
    </div>
</template>

<style scoped>
.form-container {
    max-width: 800px;
    margin: 0 auto;
}

#nav-btn {
    margin: auto;
    justify-content: space-around;
    display: flex;
    padding: 12px;
}

@media screen and (max-width: 767px) {
    #nav-btn {
        flex-direction: column;
    }
}

.sd-boolean {
    border-radius: calc(2 * (var(--sjs-base-unit, var(--base-unit, 4px)))) !important;
}
</style>
