export enum LessonScheduleType {
    Next = 'NextLesson',
    Past = 'PastLesson',
}
export type Currency = '$';

export type LessonDuration = 60 | 30;

// TODO: add all the missing types
export enum LessonType {
    Trial = 'trial_lesson',
    Lesson = 'lesson',
}
