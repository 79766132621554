<script setup lang="ts">
import { t } from '@/plugins/i18n';
import { computed } from 'vue';
import { IonIcon } from '@ionic/vue';
import { chevronForwardOutline } from 'ionicons/icons';

const { showAllText, to } = defineProps<{ to: string; showAllText?: string }>();

const showAllFallback = computed(() => t('learner.home.showAll', { items: 'items' }));
</script>

<template>
    <router-link
        :to="to"
        class="w-full flex gap-1 text-center justify-center items-center font-bold text-base h-10"
    >
        {{ showAllText || showAllFallback }}
        <ion-icon :icon="chevronForwardOutline" />
    </router-link>
</template>

<style lang="scss" scoped></style>
