<script setup lang="ts">
import { computed } from 'vue';
import LBadge, { LBadgeProps } from '@/core/components/LBadge.vue';
import { LearnerSubscription } from '../../api/learner.api';

type Props = {
    status: LearnerSubscription['status'];
    message: string;
};

const { status = 'active', message } = defineProps<Props>();

const badgeType = computed<LBadgeProps['type']>(() => {
    switch (status) {
        case 'active':
            return 'success';
        case 'cancelled':
            return 'error';
    }
});
</script>

<template>
    <l-badge
        :type="badgeType"
        :message="message"
    />
</template>
