<script setup lang="ts">
import {
    IonTabBar,
    IonTabButton,
    IonIcon,
    IonTabs,
    IonPage,
    IonRouterOutlet,
    IonHeader,
    IonText,
    onIonViewWillEnter,
} from '@ionic/vue';

import LButton from '@/core/components/LButton.vue';
import LLogo from '@/core/components/LLogo.vue';
import LProfileMenu from './LProfileMenu.vue';
import { homeOutline, searchOutline, calendarOutline, chatbubblesOutline } from 'ionicons/icons';
import { useScreenSize } from '@/core/utils/use-screen-size';
import { t } from '@/plugins/i18n';
import { useLearnerStore } from '../store/learner.store';
import { computed } from 'vue';
import { fileTrayFullOutline, chevronBack } from 'ionicons/icons';
import { LearnerRoutes } from '@/router/learner.routes';
import { useRoute } from 'vue-router';
import LBackButton from '@/core/components/LBackButton.vue';
import { storeToRefs } from 'pinia';

const { isLargeSize } = useScreenSize();

const learnerStore = useLearnerStore();

const { hasSubscriptions } = storeToRefs(learnerStore);

const route = useRoute();

const isGoBackButtonShown = computed(() => {
    switch (route.name) {
        case LearnerRoutes.Home:
            return false;
        default:
            return true;
    }
});

/**
 * NOTE: avoids v-if and v-for on the same element
 * @see https://v3-migration.vuejs.org/breaking-changes/v-if-v-for
 */
const menuItems = computed(() => {
    const items = [
        {
            tab: 'home',
            href: '/learner/home',
            label: t('learner.layout.homeButton'),
        },
        {
            tab: 'search',
            href: '/learner/catalog',
            label: t('learner.layout.searchButton'),
        },
        {
            tab: 'chats',
            href: '/learner/chats',
            label: t('learner.layout.chatsButton'),
        },
        {
            tab: 'calendar',
            href: '/learner/calendar',
            label: t('learner.layout.calendarButton'),
        },
    ];
    if (hasSubscriptions.value) {
        items.push({
            tab: 'subscriptions',
            href: '/learner/subscriptions',
            label: t('learner.layout.subscriptionsButton'),
        });
    }
    return items;
});

onIonViewWillEnter(() => {
    learnerStore.fetchSubscriptions();
});

const isChatRoute = computed(() => {
    return LearnerRoutes.Chats === route.name;
});
/** NOTE: hide header on chats and chats page for mobile devices */
const headerClasses = computed(() => ({
    'xs:hidden': isChatRoute.value,
    'md:block': true,
}));
</script>

<template>
    <ion-page>
        <ion-tabs>
            <ion-header
                class="header-container"
                :class="headerClasses"
                slot="top"
            >
                <div :class="['header-content', { 'show-back-button': isGoBackButtonShown }]">
                    <template v-if="!isLargeSize">
                        <div>
                            <l-back-button position="header" />
                        </div>

                        <div class="flex gap-2">
                            <l-button
                                v-if="hasSubscriptions"
                                variant="tertiary"
                                size="small"
                                :routerLink="{ name: LearnerRoutes.Subscriptions }"
                            >
                                <ion-icon
                                    class="me-2"
                                    color="dark"
                                    :icon="fileTrayFullOutline"
                                ></ion-icon>
                                <ion-text
                                    color="secondary"
                                    class="caption-3"
                                    >{{ t('learner.layout.subscriptionsButton') }}</ion-text
                                >
                            </l-button>

                            <l-profile-menu />
                        </div>
                    </template>

                    <template v-if="isLargeSize">
                        <div class="flex items-center">
                            <l-logo class="mr-8 h-11" />

                            <l-button
                                v-for="item of menuItems"
                                :key="item.tab"
                                variant="text"
                                :routerLink="item.href"
                                routerDirection="root"
                                routerLinkActive="active-link"
                                class="link"
                            >
                                {{ item.label }}
                                <div
                                    class="badge-desktop ms-1"
                                    v-if="
                                        item.tab === 'chats' &&
                                        learnerStore.profile.chat?.unreadCount
                                    "
                                >
                                    <span class="">{{
                                        learnerStore.profile.chat?.unreadCount
                                    }}</span>
                                </div>
                            </l-button>
                        </div>

                        <div class="flex">
                            <l-profile-menu />
                        </div>
                    </template>
                </div>
            </ion-header>

            <ion-router-outlet :animated="false" />

            <ion-tab-bar
                v-if="!isLargeSize"
                slot="bottom"
            >
                <ion-tab-button
                    tab="home"
                    href="/learner/home"
                >
                    <ion-icon :icon="homeOutline" />
                    <span>{{ t('learner.layout.homeButton') }}</span>
                </ion-tab-button>
                <ion-tab-button
                    tab="search"
                    href="/learner/catalog"
                >
                    <ion-icon :icon="searchOutline" />
                    <span>{{ t('learner.layout.searchButton') }}</span>
                </ion-tab-button>
                <ion-tab-button
                    tab="chats"
                    href="/learner/chats"
                >
                    <!-- TODO: replace by icon from the design layot -->
                    <ion-icon :icon="chatbubblesOutline" />
                    <span
                        class="badge-mobile"
                        v-if="learnerStore.profile.chat?.unreadCount"
                        >{{ learnerStore.profile.chat?.unreadCount }}</span
                    >
                    <span>{{ t('learner.layout.chatsButton') }}</span>
                </ion-tab-button>
                <ion-tab-button
                    tab="calendar"
                    href="/learner/calendar"
                >
                    <ion-icon :icon="calendarOutline" />
                    <span>{{ t('learner.layout.calendarButton') }}</span>
                </ion-tab-button>
            </ion-tab-bar>
        </ion-tabs>
    </ion-page>
</template>

<style scoped lang="scss">
@use '@/css/ui-kit.scss' as *;

.avatar-container {
    display: flex;
    align-items: center;
}

.badge-desktop {
    background-color: $purple-primary;
    border-radius: 30px;
    color: $grey-100;
    padding: 1px 5px;
    font-size: 14px;
}

.badge-mobile {
    padding: 0 0.25rem;
    position: absolute;
    top: 3px;
    right: 10px;
    background-color: $purple-primary;
    color: $grey-100;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1.5px solid $grey-100;
}

.header-container {
    background-color: $grey-100;
    border-bottom: 1px solid $grey-400;

    .header-content {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 0.5rem;
        padding: 0.5rem 1rem;

        &.show-back-button {
            justify-content: space-between;
        }

        @media screen and (min-width: $breakpoint-lg) {
            max-width: var(--page-max-width, 1280px);
            margin: 0 auto;
            padding: 0.5rem 1.25rem;

            justify-content: space-between;
        }
    }
}
</style>
