<script setup lang="ts">
import LAvatar from '@shared-components/components/LAvatar.vue';
import LMessage from './LMessage.vue';
import { IonIcon, IonText } from '@ionic/vue';
import { t } from '@/plugins/i18n';
import { checkmarkCircle, playCircleOutline } from 'ionicons/icons';
import { useScreenSize } from '../utils/use-screen-size';

const props = defineProps<{
    name?: string;
    rating?: number;
    reviews?: number;
    isVerified?: boolean;
    avatar?: string;
    price?: number;
    message?: string;
    videoUrl?: string;
}>();

const emit = defineEmits<{ ratingClick: [] }>();

// desktop show video by hover in another component
const openVideo = (event) => {
    if (isLargeSize.value) {
        return;
    }
    event.stopPropagation();
    if (props.videoUrl) {
        window.videoask.loadModal({ url: props.videoUrl, options: { modalType: 'Fullscreen' } });
    }
};

const { isLargeSize } = useScreenSize();
</script>

<template>
    <div class="l-hero-wrapper">
        <l-message
            v-if="props.message"
            class="hero-message"
            :show-tail="true"
            tail-position="bottom-left"
            :tail-reverse="true"
            :message="props.message"
        ></l-message>
        <div class="info-container">
            <div
                class="avatar-container"
                @click="openVideo"
            >
                <l-avatar
                    size="xl"
                    :src="props.avatar"
                    :alt="props.name?.charAt(0)"
                ></l-avatar>
                <ion-icon
                    v-if="props.videoUrl && !isLargeSize"
                    :icon="playCircleOutline"
                    class="play-button"
                    size="large"
                ></ion-icon>
            </div>

            <div class="info">
                <div class="main">
                    <div class="caption-2">
                        {{ props.name }}
                    </div>
                    <ion-icon
                        :icon="checkmarkCircle"
                        class="verified-icon"
                    ></ion-icon>
                </div>
                <!-- TODO: uncomment after MVP
                <l-rating
                    :rating="props.rating"
                    :reviews="props.reviews"
                    @click="emit('ratingClick')"
                ></l-rating> -->
                <div
                    v-if="props.price"
                    class="price"
                >
                    <span class="caption-4">
                        {{ t('components.LHero.from') }}
                        <ion-text class="number caption-3">{{ props.price?.toFixed(2) }}$</ion-text>
                        {{ t('components.LHero.perLesson') }}</span
                    >
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
@use '@/css/ui-kit' as *;

.l-hero-wrapper {
    display: flex;
    flex-direction: column;

    .hero-message {
        margin-left: 64px;
    }

    .info-container {
        display: flex;
        align-items: center;

        .info {
            display: flex;
            flex-direction: column;
            padding: 0 1rem;

            .main {
                display: flex;
                gap: 0.5rem;
                align-items: center;
            }
            .verified-icon {
                font-size: 1.5rem;
                color: $purple-primary;
            }

            .price {
                margin-top: 0.5rem;
                color: $grey-600;

                .number {
                    color: $grey-700;
                }
            }
        }
    }

    .avatar-container {
        position: relative;
    }

    .play-button {
        color: $purple-dark;
        font-size: 2.5rem;
        position: absolute;
        bottom: -0.7rem;
        right: 2.125rem;
        z-index: 10;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 1.5rem;
        height: 1.5rem;
        background-color: $grey-100;
        border-radius: 50%;
    }
}
</style>
