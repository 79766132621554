import { useBreakpoints } from '@vueuse/core';

export const useScreenSize = () => {
    const breakpoints = {
        xs: 0,
        sm: 576,
        md: 768,
        lg: 992,
        xl: 1200,
    };

    const bp = useBreakpoints(breakpoints);

    const isSmallSize = bp.smaller('md');
    const isMediumSize = bp.between('md', 'lg');
    const isLargeSize = bp.greaterOrEqual('lg');

    return {
        isSmallSize,
        isMediumSize,
        isLargeSize,
        breakpoints,
    };
};
