<script setup lang="ts">
import LButton from '@/core/components/LButton.vue';
import { IonCol, IonGrid, IonIcon, IonRow, IonText } from '@ionic/vue';
import LSubjectChip from '@/core/components/LSubjectChip.vue';
import LSubscriptionStatus from './LSubscriptionStatus.vue';
import LDivider from '@/core/components/LDivider.vue';
import LAvatar from '@shared-components/components/LAvatar.vue';
import { LearnerSubscription } from '../../api/learner.api';
import { t } from '@/plugins/i18n';
import { timeOutline, chevronForward } from 'ionicons/icons';
import { LearnerRoutes } from '@/router/learner.routes';
import { useScreenSize } from '@/core/utils/use-screen-size';
import { computed } from 'vue';
import { getSubscriptionCaption, getSubscriptionStatusMessage } from '../../utils';

type Props = {
    subscription: LearnerSubscription;
};

const { subscription } = defineProps<Props>();

const { isLargeSize } = useScreenSize();

const linkToDetails = computed(() => ({
    name: LearnerRoutes.SubscriptionDetails,
    params: { tutorUserUid: subscription.tutorUserUid },
}));

// TODO: use component LLessonDuration
const lessonDuration = (duration: number) =>
    t('learner.subscriptions.lessonDuration', { n: duration });
</script>

<template>
    <ion-grid v-if="!isLargeSize">
        <ion-row class="pb-2">
            <ion-col>
                <ion-row class="items-center">
                    <l-avatar
                        :uid="subscription.tutorUserUid"
                        :alt="subscription.tutorName"
                        class="mr-2"
                        size="l"
                    />
                    <ion-text class="caption-2">{{ subscription.tutorName }}</ion-text>
                </ion-row>
            </ion-col>
            <ion-col
                size="auto"
                class="flex items-center"
            >
                <l-button
                    variant="text"
                    size="small"
                    :router-link="linkToDetails"
                >
                    <ion-icon
                        :icon="chevronForward"
                        class="text-2xl"
                        color="tertiary"
                    ></ion-icon>
                </l-button>
            </ion-col>
        </ion-row>
        <ion-row class="pb-2">
            <ion-col size="auto">
                <l-subject-chip
                    class="mr-2"
                    :code="subscription.subjectCode"
                />
            </ion-col>
            <ion-col class="flex items-center gap-1">
                <ion-icon
                    :icon="timeOutline"
                    class="text-2xl"
                    color="tertiary"
                />
                {{ lessonDuration(subscription.lessonDuration) }}
            </ion-col>
        </ion-row>
        <ion-row class="pb-2">
            <l-subscription-status
                :status="subscription.status"
                :message="getSubscriptionStatusMessage(subscription)"
            ></l-subscription-status>
        </ion-row>
        <ion-row>
            <ion-text class="body-2 subscription-caption"
                >{{ getSubscriptionCaption(subscription) }}
            </ion-text>
        </ion-row>
    </ion-grid>
    <!-- NOTE: desktop layout starts here -->
    <ion-grid v-else>
        <ion-row>
            <ion-col
                size="auto"
                class="mr-4"
            >
                <l-avatar
                    :uid="subscription.tutorUserUid"
                    :alt="subscription.tutorName"
                    size="l"
                />
            </ion-col>
            <ion-col>
                <ion-row class="mb-4">
                    <ion-col
                        size="auto"
                        class="mr-4"
                    >
                        <ion-text class="caption-2">{{ subscription.tutorName }}</ion-text>
                    </ion-col>

                    <ion-col size="auto">
                        <l-subject-chip
                            class="mr-4"
                            :code="subscription.subjectCode"
                        />
                    </ion-col>
                    <ion-col
                        size="auto"
                        class="flex items-center gap-1"
                    >
                        <ion-icon
                            :icon="timeOutline"
                            class="text-2xl"
                            color="tertiary"
                        />
                        {{ lessonDuration(subscription.lessonDuration) }}
                    </ion-col>
                </ion-row>
                <ion-row>
                    <ion-col
                        size="auto"
                        class="mr-2"
                    >
                        <l-subscription-status
                            :status="subscription.status"
                            :message="getSubscriptionStatusMessage(subscription)"
                        ></l-subscription-status
                    ></ion-col>
                    <ion-col size="auto">
                        <ion-text class="body-2 subscription-caption"
                            >{{ getSubscriptionCaption(subscription) }}
                        </ion-text>
                    </ion-col>
                </ion-row>
            </ion-col>
            <ion-col
                size="auto"
                class="flex items-center"
            >
                <l-button
                    variant="text"
                    size="small"
                    :router-link="linkToDetails"
                >
                    <ion-icon
                        :icon="chevronForward"
                        class="text-2xl"
                        color="tertiary"
                    ></ion-icon>
                </l-button>
            </ion-col>
        </ion-row>
    </ion-grid>

    <l-divider />
</template>

<style scoped lang="scss">
@use '@/css/ui-kit.scss' as *;

.subscription-caption {
    color: $grey-500;
}
</style>
