import type { paths } from '../../../../../../packages/api-client/src';
import { createClientProxy } from '@/core/api/client-proxy';

export type User = paths['/auth/login']['post']['responses']['200']['content']['application/json'];

export type LoginRequestDto =
    paths['/auth/login']['post']['requestBody']['content']['application/json'];
export type LoginResult = {
    success: boolean;
    user?: User;
};

export type SignUpRequestDto =
    paths['/auth/signup']['post']['requestBody']['content']['application/json'];
export type SignUpResult = {
    success: boolean;
    user?: User;
};

export type logoutResult = {
    success: boolean;
};

export interface IConfirmResetCodeParam {
    email: string;
    password: string;
    token: string;
}
const client = createClientProxy<paths>({
    errorNotify: false,
    errorThrow: false,
});

export class AuthApi {
    async login({ email, password }: LoginRequestDto): Promise<LoginResult> {
        const res = await client.POST('/auth/login', { body: { email, password } });
        return {
            success: !res.error,
            user: res.data,
        };
    }

    async signUp({ email, password, role }: SignUpRequestDto): Promise<SignUpResult> {
        const { data, error } = await client.POST('/auth/signup', {
            body: { email, password, role },
        });
        return {
            success: !error,
            user: data,
        };
    }

    async logout(): Promise<logoutResult> {
        const { error } = await client.POST('/auth/logout');
        return { success: !error };
    }

    async sendResetPasswordEmail(email: string) {
        const { error } = await client.POST('/auth/password/reset/', {
            body: { email },
        });
        return !error;
    }

    async validateResetCode(email: string, token: string) {
        const { error } = await client.POST('/auth/password/reset/validate', {
            body: { email, token },
        });
        return !error;
    }

    async resetPasswordConfirm(email: string, password: string, token: string) {
        const options = { body: { password, email, token } };
        const { error } = await client.POST('/auth/password/reset/confirm', options);
        return !error;
    }

    async passwordChange(currentPassword: string, newPassword: string) {
        const options = { body: { currentPassword, newPassword } };
        const { error } = await client.POST('/auth/password/change', options);
        return !error;
    }

    async verificationRequest(channel: 'sms' | 'email') {
        const { error } = await client.POST('/auth/verification/request', {
            body: { channel },
        });
        return !error;
    }

    async verificationValidate(token: string) {
        const { error } = await client.POST('/auth/verification/confirm', {
            body: { token },
        });
        return !error;
    }

    async updateFCMToken(token: string) {
        const { error } = await client.POST('/user/session/update-fcm-token', {
            body: {
                fcmToken: token,
            },
        });

        return !error;
    }
}

export const useAuthApi = () => new AuthApi();
