import { LearnerSubscription, LessonEvent, TutorProfile } from './api/learner.api';
import { Currency, LessonDuration, LessonScheduleType, LessonType } from './types';
import { addDays, differenceInMinutes, isAfter, isBefore, parseISO } from 'date-fns';
import { Locale } from 'vue-i18n';
import { t } from '../../plugins/i18n';
import { useLocaleDateFormat } from '@/core/utils/use-locale-date-format';
import { ISODateString } from '@common/types';

export const filterLessonsByListType = (lessons: LessonEvent[], listType: LessonScheduleType) => {
    const isNext = listType === LessonScheduleType.Next;
    const tomorrow = addDays(new Date(), 1);

    const filteredList = lessons.filter((l) => {
        const statuses = isNext ? ['scheduled'] : ['completed', 'cancelled'];
        const isAfterTomorrow = isNext && isAfter(l.startTime?.toString(), tomorrow);
        return statuses.includes(l.status) && isAfterTomorrow;
    });

    if (isNext) return filteredList;

    // NOTE: sort lessons only for the past lessons in ShortenedLessonList
    return filteredList.sort((a, b) => {
        const dateA = new Date(a.startTime?.toString()).getTime();
        const dateB = new Date(b.startTime?.toString()).getTime();
        return dateA - dateB;
    });
};

/**
 * Calculates the duration of a lesson event in minutes and returns it as a string with a locale-specific postfix.
 * If the duration is exactly 30 or 60 minutes, it returns 25 or 50 minutes respectively.
 *
 * @param {LessonEvent} lesson - The lesson event object containing start and end times.
 * @param {Locale} locale - The locale string to determine the postfix ('ru' for Russian, any other value for English).
 * @returns {string} - The duration of the lesson in minutes with the appropriate postfix.
 */
export const getLessonDuration = (lesson: LessonEvent, locale: Locale) => {
    const startDate = new Date(lesson.startTime?.toString());
    const endDate = new Date(lesson.endTime?.toString());
    const duration = differenceInMinutes(endDate, startDate);

    const postfix = locale === 'ru' ? 'мин' : 'min';
    if (duration !== 30 && duration !== 60) return `${duration} ${postfix}`;

    return `${duration === 60 ? 50 : 25} ${postfix}`;
};

export const getJoinLessonUrl = (lessonUid: string) =>
    `${import.meta.env.VITE_APP_URL}/room/?join=${lessonUid}`;

export const getSubscriptionStatusMessage = (sub: LearnerSubscription) => {
    const format = useLocaleDateFormat();
    switch (sub?.status) {
        case 'active':
            return t('learner.subscriptions.status.active', {
                n: sub.remainingLessons,
                date: format(new Date(sub.endsAt.toString()), 'd MMMM'),
            });
        case 'cancelled':
            return t('learner.subscriptions.status.cancelled', {
                n: sub.remainingLessons,
            });
    }
};

export const getSubscriptionCaption = (sub: LearnerSubscription, currency: Currency = '$') => {
    const format = useLocaleDateFormat();
    switch (sub?.status) {
        case 'active':
            return t('learner.subscriptions.nextPaymentTime', {
                n: sub.numberOfLessons,
                price: sub.totalPrice,
                date: format(new Date(sub.endsAt.toString()), 'd MMMM'),
                currency,
            });
        case 'cancelled':
            return t('learner.subscriptions.onHold');
    }
};

/** @description if the number of lessons fetched is equal to the limit, then there are more lessons to fetch */
export function getIsLoadMoreAvailable(limit?: number | string, data: LessonEvent[] = []) {
    return Boolean(limit) && Number(limit) === data.length;
}

export function groupTimeSlots(slots: ISODateString[], locale: Locale) {
    const format = useLocaleDateFormat();
    const dayToTimes: Record<string, string[]> = {};

    slots.forEach((isoDate) => {
        const date = parseISO(isoDate);
        const day = format(date, locale === 'ru' ? 'EEEEEE' : 'EEE');
        const time = format(date, 'HH:mm');

        if (!dayToTimes[day]) {
            dayToTimes[day] = [];
        }
        dayToTimes[day].push(time);
    });

    return Object.entries(dayToTimes)
        .map(([day, times]) => `${day} ${times.join(', ')}`)
        .join('; ');
}

export function getTotalPrice(
    tutor: TutorProfile,
    duration: LessonDuration,
    lessonsNumber: number,
) {
    const pricing = duration === 60 ? tutor.pricing.lesson50Min! : tutor.pricing.lesson25Min!;

    switch (lessonsNumber) {
        case 4:
            return pricing.sub28d4 * 4;
        case 8:
            return pricing.sub28d8 * 8;
        case 12:
            return pricing.sub28d12 * 12;
        case 16:
            return pricing.sub28d16 * 16;
        case 20:
            return pricing.sub28d20 * 20;
        default:
            return pricing.regular * lessonsNumber;
    }
}
