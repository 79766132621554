import { defineStore } from 'pinia';
import { useAuthStore } from '@/modules/auth/store/auth.store';
import { Learner, LearnerSubscription, useLearnerApi } from '../api/learner.api';
import { Maybe } from '@common/types';

const learnerApi = useLearnerApi();

export const useLearnerStore = defineStore('learner', {
    state: () => ({
        profile: {} as Learner,
        subscriptions: [] as LearnerSubscription[],
        isLoading: {
            profile: true,
            subscriptions: true,
        },
    }),
    getters: {
        isOnboardingForm: (state) => {
            return state.profile.learner?.status === 'onboarding.wait_submit_form';
        },
        isScheduleTrialWait: (state) => {
            return state.profile.learner?.status === 'onboarding.wait_schedule_trial_lesson';
        },
        hasSubscriptions: (state) => Boolean(state.subscriptions.length),
    },
    actions: {
        async fetch() {
            try {
                this.isLoading.profile = true;
                const learner = await learnerApi.getLearnerProfile();
                this.profile = learner;
                useAuthStore().setAuthUser(learner.user);
            } catch (e) {
                console.error(e);
            } finally {
                this.isLoading.profile = false;
            }
        },
        async fetchSubscriptions() {
            try {
                this.isLoading.subscriptions = true;
                const subscriptions = (await learnerApi.getSubscriptions()).data;
                this.subscriptions = subscriptions ?? [];
            } catch (e) {
                console.error(e);
            } finally {
                this.isLoading.subscriptions = false;
            }
        },
        getSubscriptionByUid(tutorUserUid?: Maybe<string>) {
            if (!tutorUserUid) return;
            return this.subscriptions.find((sub) => sub.tutorUserUid === tutorUserUid);
        },
    },
});
