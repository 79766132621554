import OnboardingLayout from '@/core/layouts/OnboardingLayout.vue';
import ChatPage from '@/modules/chat/pages/ChatPage.vue';
import ChatsPage from '@/modules/chat/pages/ChatsPage.vue';
import TutorLayout from '@/modules/tutor/layouts/TutorLayout.vue';
import TutorCalendarPage from '@/modules/tutor/pages/TutorCalendarPage.vue';
import TutorDeviceCheckPage from '@/modules/tutor/pages/TutorDeviceCheckPage.vue';
import TutorMaterialsPage from '@/modules/tutor/pages/TutorMaterialsPage.vue';
import TutorOnboardingHomePage from '@/modules/tutor/pages/TutorOnboardingHomePage.vue';
import TutorOnboardingPage from '@/modules/tutor/pages/TutorOnboardingPage.vue';
import HomePage from '@/pages/HomePage.vue';
import { RouteRecordRaw } from 'vue-router';

export enum TutorRoutes {
    Home = 'TutorHome',
    DeviceCheck = 'TutorDeviceCheck',
    MyLearners = 'TutorMyLearners',
    Calendar = 'TutorCalendar',
    Materials = 'TutorMaterials',
    Chats = 'TutorChats',
    Chat = 'TutorChat',
    Onboarding = 'TutorOnboarding',
    OnboardingSurvey = 'TutorSurvey',
}

export const tutorRoutes: RouteRecordRaw[] = [
    {
        path: '/tutor',
        component: TutorLayout,
        children: [
            {
                path: '',
                redirect: '/tutor/home',
            },
            {
                path: 'home',
                name: TutorRoutes.Home,
                component: TutorOnboardingHomePage,
            },
            {
                path: 'device-check',
                name: TutorRoutes.DeviceCheck,
                component: TutorDeviceCheckPage,
            },
            {
                path: 'my-learners',
                name: TutorRoutes.MyLearners,
                component: HomePage,
            },
            {
                path: 'calendar',
                name: TutorRoutes.Calendar,
                component: TutorCalendarPage,
            },
            {
                path: 'materials',
                name: TutorRoutes.Materials,
                component: TutorMaterialsPage,
            },
            {
                path: 'chats',
                name: TutorRoutes.Chats,
                component: ChatsPage,
            },
        ],
    },
    {
        path: '/tutor/onboarding',
        component: OnboardingLayout,
        name: TutorRoutes.Onboarding,
        children: [
            {
                path: 'survey',
                name: TutorRoutes.OnboardingSurvey,
                component: TutorOnboardingPage,
            },
        ],
    },
    {
        path: '/tutor/chats/:chatUid',
        name: TutorRoutes.Chat,
        component: ChatPage,
    },
];
