<template>
    <div class="w-full flex flex-col lg:flex-row gap-4">
        <LButton
            variant="secondary"
            class="w-full"
            @click="close"
        >
            {{ t('learner.payment.methods.cancel') }}
        </LButton>
        <LButton
            variant="destructive"
            class="w-full"
            @click="remove"
        >
            {{ t('learner.payment.methods.delete') }}
            <IonIcon
                :icon="trashOutline"
                slot="start"
            />
        </LButton>
    </div>
</template>

<script setup lang="ts">
import { IonIcon } from '@ionic/vue';
import { trashOutline } from 'ionicons/icons';
import { t } from '@/plugins/i18n';
import LButton from '@/core/components/LButton.vue';

const emit = defineEmits<{ close: []; remove: [] }>();

const close = () => emit('close');
const remove = () => emit('remove');
</script>
