import { defineStore } from 'pinia';
import { ref, Ref } from 'vue';
import { Meta, TutorCatalog, useLearnerApi } from '../api/learner.api';

const learnerApi = useLearnerApi();
const defaultPageSize = 10;

export const useTutorCatalogStore = defineStore('tutors-catalog', {
    state: () => ({
        tutors: [] as TutorCatalog | undefined,
        meta: null as Meta | null,
        loading: false,
        activeTutor: ref(null) as Ref<number | null>,
    }),

    getters: {
        isLoading(state) {
            return state.loading;
        },
        totalTutors(state) {
            return state.meta?.totalItems || 0;
        },
        currentPage(state) {
            return state.meta?.currentPage || 1;
        },
        totalPages(state) {
            return state.meta?.totalPages || 1;
        },
        isLoadMoreAvailable(state): boolean {
            if (!state.meta) return false;
            return state.meta.currentPage < state.meta.totalPages;
        },
    },

    actions: {
        async fetchTutors(subject: string, page = 1, pageSize = defaultPageSize) {
            this.loading = true;
            try {
                const response = await learnerApi.getCatalogOfTutors({ subject, page, pageSize });
                this.tutors = response.data?.data;
                this.meta = response.data?.meta || null;
            } catch (error) {
                console.error('Failed to fetch tutors:', error);
            } finally {
                this.loading = false;
            }
        },

        async loadMore(subject: string, pageSize = defaultPageSize) {
            if (this.loading || !this.isLoadMoreAvailable) return;

            const nextPage = this.currentPage + 1;

            this.loading = true;
            try {
                const response = await learnerApi.getCatalogOfTutors({
                    subject,
                    page: nextPage,
                    pageSize,
                });
                const newTutors = response.data?.data;

                if (newTutors?.length) {
                    this.tutors = [...(this.tutors || []), ...newTutors];
                }

                this.meta = response.data?.meta || null;
            } catch (error) {
                console.error('Failed to load more tutors:', error);
            } finally {
                this.loading = false;
            }
        },

        setActiveTutor(index: number) {
            this.activeTutor = index;
        },
    },
});
