import OnboardingLayout from '@/core/layouts/OnboardingLayout.vue';
import ChatPage from '@/modules/chat/pages/ChatPage.vue';
import ChatsPage from '@/modules/chat/pages/ChatsPage.vue';
import LearnerLayout from '@/modules/learner/layouts/LearnerLayout.vue';
import CatalogOfTutorsPage from '@/modules/learner/pages/CatalogOfTutorsPage.vue';
import LearnerHomePage from '@/modules/learner/pages/LearnerHomePage.vue';
import LearnerSubscriptions from '@/modules/learner/pages/LearnerSubscriptions.vue';
import LearnerSubscriptionDetails from '@/modules/learner/pages/LearnerSubscriptionDetails.vue';
import LearnerOnboardingPage from '@/modules/learner/pages/LearnerOnboardingPage.vue';
import LearnerOnboardingSearch from '@/modules/learner/pages/LearnerOnboardingSearch.vue';
import LearnerPaymentMethodsPage from '@/modules/learner/pages/LearnerPaymentMethodsPage.vue';
import LearnerScheduleRecurrentPage from '@/modules/learner/pages/LearnerScheduleRecurrentPage.vue';
import TutorPage from '@/modules/learner/pages/TutorPage.vue';
import { RouteRecordRaw } from 'vue-router';
import LearnerTrialLessonPreviewPage from '@/modules/learner/pages/LearnerTrialLessonPreviewPage.vue';
import LearnerCheckoutPage from '@/modules/learner/pages/LearnerCheckoutPage.vue';
import LearnerSchedulePage from '@/modules/learner/pages/LearnerSchedulePage.vue';
import SuccessConfirmationPage from '@/modules/learner/pages/SuccessConfirmationPage.vue';
import OnboardingTutorPage from '@/modules/learner/pages/OnboardingTutorPage.vue';
import SettingsPage from '@/modules/learner/pages/LearnerSettings.vue';
import LearnerPaymentSuccessPage from '@/modules/learner/pages/LearnerPaymentSuccessPage.vue';
import SettingsPasswordPage from '@/modules/learner/pages/LearnerSettingsPassword.vue';

export enum LearnerRoutes {
    Catalog = 'LearnerCatalog',
    Chat = 'LearnerChat',
    OnboardingChat = 'OnboardingLearnerChat',
    Chats = 'LearnerChats',
    Home = 'LearnerHome',
    Onboarding = 'LearnerOnboarding',
    OnboardingTutorPage = 'OnboardingTutorPage',
    OnboardingSurvey = 'LearnerSurvey',
    OnboardingTutors = 'LearnerTutors',
    SuccessBooking = 'SuccessBooking',
    TrialCatalog = 'LearnerTrialCatalog',
    TrialBookingOnboarding = 'LearnerTrialPreviewOnboarding',
    TrialBooking = 'LearnerTrialPreview',
    TrialLessonConfirmation = 'LearnerTrialLessonConfirmation',
    TrialTutor = 'LearnerTrialTutor',
    Tutor = 'LearnerTutorPage',
    Settings = 'LearnerSettings',
    SettingsPassword = 'LearnerSettingsPassword',
    Calendar = 'LearnerCalendar',
    PastLessons = 'LearnerPastLessons',
    NextLessons = 'LearnerNextLessons',
    Subscriptions = 'LearnerSubscriptions',
    SubscriptionDetails = 'LearnerSubscriptionDetails',
    PaymentMethods = 'LearnerPaymentMethods',
    PaymentSuccess = 'LearnerPaymentSuccess',
    ScheduleRecurrentTime = 'LearnerScheduleRecurrent',
    Checkout = 'LearnerCheckout',
}

export const learnerRoutes: RouteRecordRaw[] = [
    {
        path: '/learner',
        component: LearnerLayout,
        children: [
            {
                path: '',
                redirect: '/learner/home',
            },
            {
                path: 'home',
                name: LearnerRoutes.Home,
                component: LearnerHomePage,
            },
            {
                path: 'catalog',
                name: LearnerRoutes.Catalog,
                component: CatalogOfTutorsPage,
            },
            {
                path: 'tutor/:tutorUserUid',
                name: LearnerRoutes.Tutor,
                component: TutorPage,
                meta: {
                    backName: LearnerRoutes.Catalog,
                    backTextKey: 'learner.tutorCatalog.title',
                },
            },
            {
                path: 'chats',
                name: LearnerRoutes.Chats,
                component: ChatsPage,
            },
            {
                path: 'calendar',
                name: LearnerRoutes.NextLessons,
                component: LearnerSchedulePage, // NOTE: for the next lessons
            },
            {
                path: 'history',
                name: LearnerRoutes.PastLessons,
                component: LearnerSchedulePage, // NOTE: for the past lessons
            },
            {
                path: 'success-booking',
                component: SuccessConfirmationPage,
                name: LearnerRoutes.SuccessBooking,
                props: (route) => ({
                    uid: route.query.uid,
                    start: route.query.start,
                    end: route.query.end,
                }),
            },
            {
                path: 'subscriptions',
                component: LearnerSubscriptions,
                name: LearnerRoutes.Subscriptions,
            },
            {
                path: 'subscriptions/:tutorUserUid',
                component: LearnerSubscriptionDetails,
                name: LearnerRoutes.SubscriptionDetails,
                props: true,
                meta: {
                    backName: LearnerRoutes.Subscriptions,
                    backTextKey: 'learner.subscriptions.title',
                },
            },
            {
                path: 'trial-booking',
                name: LearnerRoutes.TrialBooking,
                component: LearnerTrialLessonPreviewPage,
                props: (route) => ({
                    uid: route.query.uid,
                    start: route.query.start,
                    end: route.query.end,
                }),
                meta: {
                    backTextKey: 'learner.onboarding.trialPreview.backButton',
                },
            },
            {
                path: 'payment/methods',
                component: LearnerPaymentMethodsPage,
                name: LearnerRoutes.PaymentMethods,
                meta: {
                    backTextKey: 'learner.payment.methods.goBack',
                },
            },
            {
                path: 'payment/book/:tutorUserUid',
                component: LearnerScheduleRecurrentPage,
                name: LearnerRoutes.ScheduleRecurrentTime,
                meta: {
                    backName: LearnerRoutes.Catalog,
                    backTextKey: 'learner.tutorCatalog.title',
                },
                props: true,
            },
            {
                path: 'payment/checkout',
                component: LearnerCheckoutPage,
                name: LearnerRoutes.Checkout,
                props: (route) => ({
                    tutorUserUid: route.query.tutorUserUid,
                    timeSlots: Array.isArray(route.query.timeSlots)
                        ? route.query.timeSlots
                        : [route.query.timeSlots],
                    lessonDuration: route.query.lessonDuration === '60' ? 60 : 30,
                }),
            },
            {
                path: 'payment/success',
                component: LearnerPaymentSuccessPage,
                name: LearnerRoutes.PaymentSuccess,
                props: (route) => ({
                    tutorUserUid: route.query.tutorUserUid,
                    timeSlots: Array.isArray(route.query.timeSlots)
                        ? route.query.timeSlots
                        : [route.query.timeSlots],
                    lessonDuration: route.query.lessonDuration === '60' ? 60 : 30,
                }),
            },
            {
                path: 'settings',
                name: LearnerRoutes.Settings,
                component: SettingsPage,
            },
            {
                path: 'settings/password',
                name: LearnerRoutes.SettingsPassword,
                component: SettingsPasswordPage,
            },
        ],
    },
    {
        path: '/trial-catalog/:userUid',
        name: LearnerRoutes.TrialTutor,
        component: TutorPage,
        props: true,
    },

    {
        path: '/learner/onboarding',
        component: OnboardingLayout,
        name: LearnerRoutes.Onboarding,
        children: [
            {
                path: 'survey',
                name: LearnerRoutes.OnboardingSurvey,
                component: LearnerOnboardingPage,
            },
            {
                path: 'tutors',
                name: LearnerRoutes.OnboardingTutors,
                component: LearnerOnboardingSearch,
            },
            {
                path: 'tutor/:tutorUserUid',
                name: LearnerRoutes.OnboardingTutorPage,
                component: OnboardingTutorPage,
                props: true,
                meta: {
                    backName: LearnerRoutes.OnboardingTutors,
                    backTextKey: 'learner.tutorCatalog.title',
                },
            },
            {
                path: 'trial-booking',
                name: LearnerRoutes.TrialBookingOnboarding,
                component: LearnerTrialLessonPreviewPage,
                props: (route) => ({
                    uid: route.query.uid,
                    start: route.query.start,
                    end: route.query.end,
                }),
                meta: {
                    backTextKey: 'learner.onboarding.trialPreview.backButton',
                },
            },
            {
                path: 'chats/:chatUid',
                name: LearnerRoutes.OnboardingChat,
                component: ChatPage,
                meta: { noHeader: true, noHelpFooter: true },
            },
        ],
    },
    {
        path: '/learner/chats/:chatUid',
        name: LearnerRoutes.Chat,
        component: ChatPage,
    },
];
