<script setup lang="ts">
import { computed } from 'vue';
import { useCalendarStore } from '../../store/calendar.store';
import { storeToRefs } from 'pinia';
import { t } from '@/plugins/i18n';
import { LessonScheduleType } from '../../types';
import LSkeletonLessonList from './LSkeletonLessonList.vue';
import LLessonItem from './LLessonItem.vue';
import LEmptyLessonList from './LEmptyLessonList.vue';
import LButton from '@/core/components/LButton.vue';

type Props = {
    listType: LessonScheduleType;
};
const { listType } = defineProps<Props>();

const calendarStore = useCalendarStore();
const { pastLessons, nextLessons, loading, isLoadMoreAvailable } = storeToRefs(calendarStore);

// TODO: extract logic to a composable
const isNext = computed(() => listType === LessonScheduleType.Next);
const lessonList = computed(() => (isNext.value ? nextLessons.value : pastLessons.value));
const isListEmpty = computed(() => !loading.value && lessonList.value.length === 0);
const skeletonsCount = 5;

const onLoadMore = () => {
    calendarStore.fetchMoreLessons(lessonList.value.length, isNext.value);
};

const listTitle = computed(() => t(`learner.home.${isNext.value ? 'nextLessons' : 'pastLessons'}`));
const showMoreText = computed(() => t('learner.home.loadMore'));
</script>

<template>
    <h5 class="pb-4">{{ listTitle }}</h5>

    <l-skeleton-lesson-list
        v-if="loading"
        :length="skeletonsCount"
    />

    <transition-group
        v-else
        name="slide-fade"
        tag="div"
    >
        <l-lesson-item
            v-for="lesson in lessonList"
            :key="lesson.uid"
            :lesson="lesson"
            :lessonType="listType"
        />
    </transition-group>

    <l-empty-lesson-list
        v-if="isListEmpty"
        :listType="listType"
    />

    <l-button
        v-if="isLoadMoreAvailable"
        @click="onLoadMore"
        class="w-full flex text-center justify-center items-center"
        type="button"
        size="small"
        variant="text"
        color="secondary"
    >
        {{ showMoreText }}
    </l-button>
</template>

<style scoped>
.slide-fade-enter-active {
    transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
    transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
    transform: translateX(20px);
    opacity: 0;
}
</style>
