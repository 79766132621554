<script setup lang="ts">
import { IonPopover, IonContent, IonText, IonIcon } from '@ionic/vue';
import { chevronForwardOutline } from 'ionicons/icons';
import { computed, ref } from 'vue';
import LAvatar from '@shared-components/components/LAvatar.vue';
import LButton from '@/core/components/LButton.vue';
import { useLearnerStore } from '../store/learner.store';
import { storeToRefs } from 'pinia';
import { useAuthStore } from '@/modules/auth/store/auth.store';
import { CommonRoutes } from '@/router/common.routes';
import { LearnerRoutes } from '@/router/learner.routes';
import { useRouter } from 'vue-router';
import { t } from '@/plugins/i18n';

const learnerStore = useLearnerStore();
const authStore = useAuthStore();
const router = useRouter();

const { profile } = storeToRefs(learnerStore);

const isPopoverOpen = ref(false);
const event = ref<MouseEvent>();

const onProfileClick = (e: MouseEvent) => {
    event.value = e;
    isPopoverOpen.value = !isPopoverOpen.value;
};

const onLogoutClick = async () => {
    await authStore.logout();

    // NOTE: close popover after logout
    if (isPopoverOpen.value === true) {
        isPopoverOpen.value = false;
    }
};

const onSettingsClick = async () => {
    console.log('LearnerRoutes', LearnerRoutes.Settings);
    console.log('learnerStore', learnerStore);
    console.log('isPopoverOpen.value', isPopoverOpen.value);
    if (isPopoverOpen.value) {
        isPopoverOpen.value = false;
    }
};

const learnerName = computed(() => profile.value.user.firstName);
const learnerUid = computed(() => profile.value.user.uid);

const logoutText = computed(() => t('auth.logout'));
const settingsText = computed(() => t('settings.title'));
</script>

<template>
    <div
        @click="onProfileClick($event)"
        class="flex items-center cursor-pointer"
    >
        <l-avatar
            :alt="learnerName"
            :uid="learnerUid"
            size="s"
        />
        <ion-text class="caption-4 ms-2 ion-hide-lg-down">{{ learnerName }}</ion-text>
        <ion-icon
            :icon="chevronForwardOutline"
            class="text-xl ion-hide-lg-down"
            color="tertiary"
        />
    </div>

    <ion-popover
        @didDismiss="isPopoverOpen = false"
        :is-open="isPopoverOpen"
        :event="event"
        :arrow="false"
        alignment="center"
        side="bottom"
    >
        <ion-content class="ion-padding flex flex-col gap-y-1">
            <router-link
                class="flex justify-center font-bold"
                :to="{ name: LearnerRoutes.Settings }"
                @click="onSettingsClick"
            >
                {{ settingsText }}
            </router-link>
            <l-button
                @click="onLogoutClick"
                size="small"
                expand="block"
                variant="text"
                color="danger"
            >
                {{ logoutText }}
            </l-button>
        </ion-content>
    </ion-popover>
</template>

<style lang="scss" scoped>
ion-content > ion-button {
    white-space: nowrap;
    text-transform: capitalize;
    font-weight: 700;
}
</style>
