<script setup lang="ts">
import { t } from '@/plugins/i18n';
import { IonIcon, useIonRouter } from '@ionic/vue';
import { chevronForwardOutline } from 'ionicons/icons';

function toGuide() {
    // TODO: ADD guide link
    // router.push()
}
</script>

<template>
    <div
        class="guide-container"
        @click="toGuide"
    >
        <div>
            <img src="/assets/book-guide.png" />
        </div>
        <div class="text-box">
            <p class="caption-2 title">{{ t('learner.onboarding.successTrial.guideTitle') }}</p>
            <p class="caption-3">
                {{ t('learner.onboarding.successTrial.guideDesc') }}
            </p>
        </div>
        <div>
            <ion-icon
                class="text-2xl"
                :icon="chevronForwardOutline"
            />
        </div>
    </div>
</template>

<style lang="scss" scoped>
@use '@/css/ui-kit.scss' as *;

.guide-container {
    padding: 0.875rem 1rem;
    border-radius: $default-border-radius;
    background-color: $grey-300;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.text-box {
    margin: 0rem 0.5rem;
}

.title {
    padding-bottom: 0.5rem;
}

@media screen and (min-width: $breakpoint-lg) {
    .guide-container {
        background-color: $grey-100;
    }
}
</style>
