<script setup lang="ts">
import LButton from '@/core/components/LButton.vue';
import LInput from '@/core/components/LInput.vue';
import LPage from '@/core/components/LPage.vue';
import LSelect from '@/core/components/LSelect.vue';
import LIsland from '@/core/components/LIsland.vue';
import {
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonIcon,
    IonCol,
    IonRow,
    IonText,
} from '@ionic/vue';
import { closeCircle, eye, leaf, lockClosed, chevronBack, chevronForward } from 'ionicons/icons';
import { useNotification } from '@/core/composables/use-notification';
import { h } from 'vue';
const notify = useNotification();

const someInfoComponent = (slotProps: { getId: () => number }) =>
    h(
        LButton,
        {
            variant: 'primary-alt',
            class: 'w-full',
            onClick: () => notify.closeById(slotProps.getId()),
        },
        ['yes, it is a button, press it to close notification'],
    );

const handleShowSuccess = () => {
    notify.success({
        title: 'Success',
        message: 'Notification will be closed automatically in 5000ms',
    });
};
const handleShowError = () => {
    notify.error({
        title: 'Error',
        message: 'You have to close it manually since duration is 0',
        duration: 0,
    });
};

const handleShowInfo = () => {
    notify.info({
        title: 'Info',
        message: "You can't close it, suffer",
        closable: false,
    });
};
const handleShowWarning = () => {
    notify.warn({
        title: 'Warning',
        message: 'Oops',
    });
};
const handleShowModal = () => {
    const close = () => notify.closeById(id);
    const id = notify.error({
        mode: 'modal',
        type: 'warning',
        duration: 0,
        title: 'Are you surely sure?',
        message: 'Be sure if you are not',
        slotComponent: h('div', { class: 'w-full flex flex-col gap-4 lg:flex-row' }, [
            h(
                LButton,
                { variant: 'tertiary', class: 'w-full', onClick: close },
                { default: () => 'Cancel' },
            ),
            h(
                LButton,
                {
                    class: 'w-full',
                    onClick: close,
                },
                { default: () => 'Sure' },
            ),
        ]),
    });
};

const handleShowCustom = () => {
    const id = notify.create({
        slotComponent: someInfoComponent,
        slotProps: {
            getId: () => id,
        },
        duration: 0,
    });
};
</script>

<template>
    <l-page>
        <h1 class="text-center mb-8">Typography</h1>
        <l-island class="mb-8">
            <ion-text color="primary">
                <h1>Primary</h1>
            </ion-text>
            <ion-text color="secondary">
                <h1>Secondary</h1>
            </ion-text>
            <ion-text color="tertiary">
                <h1>Tertiary</h1>
            </ion-text>
            <ion-text color="dark">
                <h1>dark</h1>
            </ion-text>
            <ion-text color="danger">
                <h1>danger</h1>
            </ion-text>
            <ion-text color="light">
                <h1>light</h1>
            </ion-text>
            <ion-text color="medium">
                <h1>Medium</h1>
            </ion-text>
            <ion-text color="success">
                <h1>Success</h1>
            </ion-text>
            <ion-text color="warning">
                <h1>Warning</h1>
            </ion-text>

            <ion-text>
                <h2>h2</h2>
            </ion-text>
            <ion-text>
                <h3>h3</h3>
            </ion-text>
            <ion-text>
                <h4>h4</h4>
            </ion-text>
            <ion-text>
                <h5>h5</h5>
            </ion-text>
            <br />
            <ion-text class="body-1">Body-1</ion-text><br />
            <ion-text class="body-2">Body-2</ion-text><br />
            <ion-text class="body-3">Body-3</ion-text><br />
            <ion-text class="caption-1">Caption-1</ion-text><br />
            <ion-text class="caption-2">Caption-2</ion-text><br />
            <ion-text class="caption-3">Caption-3</ion-text><br />
            <ion-text class="caption-4">Caption-4</ion-text><br />
            <ion-text class="input-1">Input-1</ion-text><br />
            <br />
        </l-island>

        <h1 class="text-center mb-8">Buttons</h1>
        <l-island class="mb-8">
            <ion-row class="my-4 flex gap-16">
                <ion-col>
                    <h4 class="mb-4">Sizes</h4>
                    <div class="flex gap-4 items-center">
                        <l-button size="large">large</l-button>
                        <l-button size="default">default</l-button>
                        <l-button size="small">small</l-button>
                    </div>
                </ion-col>
                <ion-col>
                    <h4 class="mb-4">Sizes <small>(icon-only)</small></h4>
                    <div class="flex gap-4 items-center">
                        <l-button size="large">
                            <ion-icon
                                slot="icon-only"
                                :icon="chevronBack"
                            />
                        </l-button>
                        <l-button size="default">
                            <ion-icon
                                slot="icon-only"
                                :icon="chevronBack"
                            />
                        </l-button>
                        <l-button size="small">
                            <ion-icon
                                slot="icon-only"
                                :icon="chevronBack"
                            />
                        </l-button>
                    </div>
                </ion-col>
            </ion-row>

            <ion-row class="my-4 flex gap-16">
                <ion-col>
                    <h4 class="mb-4">Variant Primary</h4>
                    <div class="flex gap-4">
                        <l-button
                            variant="primary"
                            :disabled="true"
                            >disabled</l-button
                        >
                        <l-button variant="primary">default</l-button>
                        <l-button variant="primary">
                            with icons
                            <ion-icon
                                slot="start"
                                :icon="chevronBack"
                            />
                            <ion-icon
                                slot="end"
                                :icon="chevronForward"
                            />
                        </l-button>
                    </div>
                </ion-col>
                <ion-col>
                    <h4 class="mb-4">Variant Primary Alt</h4>
                    <div class="flex gap-4">
                        <l-button
                            variant="primary-alt"
                            :disabled="true"
                            >disabled</l-button
                        >
                        <l-button variant="primary-alt">default</l-button>
                        <l-button variant="primary-alt">
                            with icons
                            <ion-icon
                                slot="start"
                                :icon="chevronBack" />
                            <ion-icon
                                slot="end"
                                :icon="chevronForward"
                        /></l-button>
                    </div>
                </ion-col>
            </ion-row>
            <ion-row class="my-4 flex gap-16">
                <ion-col>
                    <h4 class="mb-4">Variant Secondary</h4>
                    <div class="flex gap-4">
                        <l-button
                            variant="secondary"
                            :disabled="true"
                            >disabled</l-button
                        >
                        <l-button variant="secondary">default</l-button>
                        <l-button variant="secondary">
                            with icons
                            <ion-icon
                                slot="start"
                                :icon="chevronBack"
                            />
                            <ion-icon
                                slot="end"
                                :icon="chevronForward"
                            />
                        </l-button>
                    </div>
                </ion-col>
                <ion-col>
                    <h4 class="mb-4">Variant Tertiary</h4>
                    <div class="flex gap-4">
                        <l-button
                            variant="tertiary"
                            :disabled="true"
                            >disabled</l-button
                        >
                        <l-button variant="tertiary">default</l-button>
                        <l-button variant="tertiary">
                            with icons
                            <ion-icon
                                slot="start"
                                :icon="chevronBack"
                            />
                            <ion-icon
                                slot="end"
                                :icon="chevronForward"
                            />
                        </l-button>
                    </div>
                </ion-col>
            </ion-row>
            <ion-row class="my-4 flex gap-16">
                <ion-col>
                    <h4 class="mb-4">Variant Destructive</h4>
                    <div class="flex gap-4">
                        <l-button
                            variant="destructive"
                            :disabled="true"
                            >disabled</l-button
                        >
                        <l-button variant="destructive">default</l-button>
                        <l-button variant="destructive">
                            with icons
                            <ion-icon
                                slot="start"
                                :icon="chevronBack" />
                            <ion-icon
                                slot="end"
                                :icon="chevronForward"
                        /></l-button>
                    </div>
                </ion-col>
                <ion-col>
                    <h4 class="mb-4">Variant Borderless</h4>
                    <div class="flex gap-4">
                        <l-button
                            variant="borderless"
                            :disabled="true"
                            >disabled</l-button
                        >
                        <l-button variant="borderless">default</l-button>
                        <l-button variant="borderless">
                            with icons
                            <ion-icon
                                slot="start"
                                :icon="chevronBack" />
                            <ion-icon
                                slot="end"
                                :icon="chevronForward"
                        /></l-button>
                    </div>
                </ion-col>
            </ion-row>
            <ion-row class="my-4 flex gap-16">
                <ion-col>
                    <h4 class="mb-4">Variant Text <small>(not included in design)</small></h4>

                    <div class="flex gap-4">
                        <l-button
                            variant="text"
                            :disabled="true"
                            >disabled</l-button
                        >
                        <l-button variant="text">default</l-button>
                        <l-button variant="text">
                            with icons
                            <ion-icon
                                slot="start"
                                :icon="chevronBack" />
                            <ion-icon
                                slot="end"
                                :icon="chevronForward"
                        /></l-button>
                    </div>
                </ion-col>
            </ion-row>
        </l-island>
        <h1 class="text-center mb-8">Input</h1>
        <l-island class="my-8">
            <div class="flex gap-4">
                <l-input label="Input" />
                <l-input
                    color="danger"
                    label="Danger input"
                />
                <l-input
                    color="warning"
                    label="Warning input"
                />
                <l-input
                    color="success"
                    label="Success input"
                />
                <l-input
                    color="danger"
                    label="Outline input"
                >
                    <ion-icon
                        slot="start"
                        :icon="lockClosed"
                        aria-hidden="true"
                    ></ion-icon>
                    <l-button
                        variant="text"
                        slot="end"
                        aria-label="Show/hide"
                    >
                        <ion-icon
                            style="color: black"
                            slot="icon-only"
                            :icon="eye"
                            aria-hidden="true"
                        ></ion-icon>
                    </l-button>
                </l-input>
            </div>
            <br />
            <div class="flex gap-4">
                <l-input
                    helper-text="helper-text"
                    color="danger"
                    label="Outline input"
                />
                <l-input
                    helper-text="helper-text"
                    color="warning"
                    label="Outline input"
                />
                <l-input
                    helper-text="helper-text"
                    color="success"
                    label="Outline input"
                />
                <l-input
                    helper-text="helper-text"
                    color="tertiary"
                    label="Outline input"
                />
            </div>
        </l-island>
        <h1 class="text-center mb-8">Notification</h1>
        <l-island class="my-8">
            <div class="flex gap-4">
                <l-button @click="handleShowSuccess">show success</l-button>
                <l-button
                    variant="destructive"
                    @click="handleShowError"
                    >show error</l-button
                >
                <l-button
                    variant="secondary"
                    @click="handleShowInfo"
                    >show info</l-button
                >
                <l-button
                    variant="tertiary"
                    @click="handleShowWarning"
                    >show warning</l-button
                >
                <l-button
                    variant="primary-alt"
                    @click="handleShowCustom"
                    >show custom</l-button
                >
                <l-button
                    variant="primary-alt"
                    @click="handleShowModal"
                    >show modal</l-button
                >
            </div>
        </l-island>
        <l-island>
            <h3>Select</h3>
            <div style="max-width: 260px">
                single select
                <l-select
                    :options="[
                        { label: 'banana', value: 'banana' },
                        { label: 'apple', value: 'apple' },
                        {
                            label: 'orange',
                            value: 'orange',
                        },
                    ]"
                    placeholder="Choose your fruits"
                >
                </l-select>
                multiple select
                <l-select
                    :options="[
                        { label: 'banana', value: 'banana' },
                        { label: 'apple', value: 'apple' },
                        { label: 'orange', value: 'orange' },
                    ]"
                    placeholder="Choose your fruits"
                    :multiple="true"
                    :start-icon="leaf"
                    :end-icon="closeCircle"
                />
            </div>
        </l-island>
        <div>
            <h2 style="text-align: center">Card</h2>
            <ion-card>
                <ion-card-header>
                    <ion-card-title>Title</ion-card-title>
                    <ion-card-subtitle>Subtitle</ion-card-subtitle>
                </ion-card-header>
                <ion-card-content>Content</ion-card-content>
            </ion-card>
        </div>
    </l-page>
</template>
<style lang="scss" scoped>
ion-input.link-input {
    label {
        border: 2px solid #b0afb2;
        border-radius: 8px !important;
    }
    .sc-ion-input-ios {
        --padding-start: 8px;
        --padding-end: 0px;
    }
    .sc-ion-input-ios-s > [slot='start']:last-of-type {
        margin-inline-end: 8px;
    }
    .sc-ion-input-ios-s > [slot='end']:first-of-type {
        margin-inline-start: 0px;
    }
}
ion-input.link-input-2 {
    min-height: 44px !important;
}
</style>
