<script setup lang="ts">
import { IonRow, IonCol } from '@ionic/vue';
import LPage from '@/core/components/LPage.vue';
import LIsland from '@/core/components/LIsland.vue';
import { computed, onMounted, ref } from 'vue';
import LLessonListShortened from '../components/home/LLessonListShortened.vue';
import LBanner from '../components/home/LBanner.vue';
import { useCalendarStore } from '../store/calendar.store';
import { addWeeks, subHours, subWeeks } from 'date-fns';
import { LessonScheduleType, LessonType } from '../types';
import { storeToRefs } from 'pinia';
import FindTutorSection from '../components/home/FindTutorSection.vue';
import { useLearnerStore } from '../store/learner.store';

const calendarStore = useCalendarStore();
const learnerStore = useLearnerStore();
const { pastLessons, nextLessons } = storeToRefs(calendarStore);

const passedTrialLessons = computed(() =>
    pastLessons.value.filter((lesson) => lesson.type === 'trial_lesson'),
);

onMounted(() => fetchLessons());

const lastTrialLesson = computed(() => passedTrialLessons.value.at(-1));

// TODO: extract to a store action
/** @description: it takes initial lessons for shortened lesson lists */
const fetchLessons = async () => {
    const now = new Date().toISOString();
    const hourAgo = subHours(now, 1).toISOString();
    const fourWeeksAgo = subWeeks(now, 4).toISOString();
    const fourWeeksLater = addWeeks(now, 4).toISOString();
    const limit = 2;

    await Promise.all([
        calendarStore.fetchLessons(
            {
                startTime: hourAgo,
                endTime: fourWeeksLater,
                sortOrder: 'ASC',
                statuses: ['scheduled'],
                limit,
            },
            true,
        ),
        calendarStore.fetchLessons(
            {
                startTime: fourWeeksAgo,
                endTime: now,
                sortOrder: 'DESC',
                statuses: ['completed', 'cancelled'],
                limit,
            },
            false,
        ),
    ]);
};
</script>

<template>
    <l-page class="page-container">
        <ion-row>
            <ion-col
                size="12"
                size-lg="6"
            >
                <ion-row class="gap-y-12">
                    <find-tutor-section v-if="learnerStore.isScheduleTrialWait" />

                    <template v-else>
                        <l-island>
                            <l-lesson-list-shortened
                                v-if="nextLessons.length"
                                :listType="LessonScheduleType.Next"
                                :listLength="2"
                            />
                            <l-banner
                                v-if="lastTrialLesson"
                                :lesson="lastTrialLesson"
                            />
                        </l-island>

                        <l-island>
                            <l-lesson-list-shortened
                                :listType="LessonScheduleType.Past"
                                :listLength="2"
                            />
                        </l-island>
                    </template>
                </ion-row>
            </ion-col>
        </ion-row>
    </l-page>
</template>

<style scoped>
.page-container {
    --ion-grid-padding: 0;
    --ion-grid-column-padding: 0;
}
</style>
