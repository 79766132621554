<script setup lang="ts">
import { onMounted, ref, Ref } from 'vue';
import { TutorProfile, useLearnerApi } from '../api/learner.api';
import LTrialLessonPreview from '../components/onboarding/LTrialLessonPreview.vue';
import LPage from '@/core/components/LPage.vue';
import { useRoute } from 'vue-router';

const learnerApi = useLearnerApi();

const route = useRoute();

const tutor = ref(null) as Ref<null | TutorProfile>;
const lessonData = ref(null) as Ref<{ start: string; end: string } | null>;
const lessonDateTime = ref(null) as Ref<{ start: Date; end: Date } | null>;

const fetchData = async () => {
    const uid = route.query.uid as string;

    const start = route.query.start as string;
    const end = route.query.end as string;

    if (uid && start && end) {
        const lessonTime = { start: '', end: '' };
        lessonDateTime.value = { start: new Date(start), end: new Date(end) };
        lessonTime.start = new Date(start).toLocaleString('en-US', { timeZoneName: 'short' });
        lessonTime.end = new Date(end).toLocaleString('en-US', { timeZoneName: 'short' });

        lessonData.value = lessonTime;
        tutor.value = await learnerApi.getTutor(uid);
    }
};

onMounted(fetchData);
</script>

<template>
    <l-page v-if="tutor">
        <l-trial-lesson-preview
            :tutor="tutor"
            :startDate="lessonDateTime!.start"
            :endDate="lessonDateTime!.end"
        />
    </l-page>
</template>
