import { t } from '@/plugins/i18n';
import { subDays } from 'date-fns';

export const getMessageTime = (date: Date, yesterdayText: string, locale: Intl.LocalesArgument) => {
    const d = date.getDate();
    const m = date.getMonth();
    const y = date.getFullYear();

    const nowDate = new Date();
    const curD = nowDate.getDate();
    const curM = nowDate.getMonth();
    const curY = nowDate.getFullYear();

    const dateText = date.toLocaleTimeString(locale, { hour: 'numeric', minute: 'numeric' });

    if (d === curD && m === curM && y === curY) {
        return dateText;
    }
    if (d === subDays(nowDate, 1).getDate() && m === curM && y === curY) {
        return `${yesterdayText} ${dateText}`;
    }
    if (y === curY) {
        return date.toLocaleDateString(locale, {
            day: 'numeric',
            month: 'short',
            hour: 'numeric',
            minute: 'numeric',
        });
    }
    return date.toLocaleDateString(locale);
};
