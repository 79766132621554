<script setup lang="ts">
import LButton from '@/core/components/LButton.vue';
import { IonIcon } from '@ionic/vue';

import LSubscriptionItemSkeleton from './LSubscriptionItemSkeleton.vue';
import LSubscriptionItem from './LSubscriptionItem.vue';

import { LearnerSubscription } from '../../api/learner.api';
import { add, arrowForward } from 'ionicons/icons';

import { LearnerRoutes } from '@/router/learner.routes';
import { computed } from 'vue';
import { useScreenSize } from '@/core/utils/use-screen-size';
import { t } from '@/plugins/i18n';

type Props = {
    subscriptions: LearnerSubscription[];
    isLoading: boolean;
};

/** NOTE: browser interpretates it as [object%20Object]  */
const linkToTutorsCatalog = { name: LearnerRoutes.Catalog };

const { subscriptions, isLoading } = defineProps<Props>();
const { isLargeSize } = useScreenSize();

const title = computed(() => t('learner.subscriptions.title'));
const addAnotherTutor = computed(() => t('learner.subscriptions.addAnotherTutor'));
const chooseTutor = computed(() => t('learner.subscriptions.chooseTutor'));

const noSubscriptions = computed(() => !subscriptions.length);
</script>

<template>
    <h5 class="pb-8">{{ title }}</h5>

    <l-subscription-item-skeleton v-if="isLoading" />

    <template v-else>
        <l-subscription-item
            v-for="sub of subscriptions"
            :subscription="sub"
            :key="sub.tutorUserUid"
        />

        <template v-if="noSubscriptions">
            <!-- TODO:  refactor when design is ready -->
            <div class="body-1 mb-4 text-center">
                Путешествие к знаниям начинается здесь! <br />Выберите репетитора, чтобы начать
                заниматься 💫
            </div>

            <div class="flex justify-center items-center">
                <l-button
                    color="primary"
                    strong
                    :router-link="linkToTutorsCatalog"
                >
                    <ion-icon
                        slot="end"
                        :icon="arrowForward"
                        class="text-2xl"
                    ></ion-icon>
                    {{ chooseTutor }}
                </l-button>
            </div>
        </template>

        <template v-else>
            <l-button
                :expand="!isLargeSize ? 'block' : undefined"
                variant="secondary"
                :router-link="linkToTutorsCatalog"
            >
                <ion-icon
                    :icon="add"
                    class="text-2xl me-2"
                ></ion-icon>
                {{ addAnotherTutor }}
            </l-button>
        </template>
    </template>
</template>

<style scoped lang="scss">
@use '@/css/ui-kit.scss' as *;
</style>
