import { fbMessaging } from '@/boot/firebase';
import { sharedWorker } from '@/boot/worker';
import { getToken } from 'firebase/messaging';
import { useChatStore } from '../store/chat.store';
import { useAuthStore } from '@/modules/auth/store/auth.store';
import { useTutorStore } from '@/modules/tutor/store/tutor.store';
import { useLearnerStore } from '@/modules/learner/store/learner.store';
import { useAuthApi } from '@/modules/auth/api/auth.api';
import { subDays } from 'date-fns';
import { Locale } from 'vue-i18n';

export async function requestNotificationPermission() {
    const swRegistrations = await navigator.serviceWorker.getRegistrations();
    console.log('Service worker registrations', swRegistrations);

    if (swRegistrations[0]) {
        const token = await getToken(fbMessaging, {
            serviceWorkerRegistration: swRegistrations[0],
            vapidKey: import.meta.env.VITE_FIREBASE_PUSH_KEY,
        });

        const userStore =
            useAuthStore().user.role === 'tutor' ? useTutorStore() : useLearnerStore();

        if (userStore?.profile?.session && token !== userStore.profile.session.fcmToken) {
            useAuthApi().updateFCMToken(token);
        }

        console.log('FCM Token: ', token);
    } else {
        console.error('Service worker registrations not found');
    }
}

export function sendMessageToSw(dto: any) {
    console.log('Post message to service worker', dto);

    if (navigator.serviceWorker.controller) {
        navigator.serviceWorker.controller?.postMessage(JSON.stringify(dto));
    } else {
        console.log('navigator.serviceWorker.controller is null.');
    }
}

export function sendMessageToShw(dto: any) {
    console.log('Post message to shared worker', dto);

    sharedWorker.postMessage(dto);
}

export function createSharedWorkerSubscription() {
    sharedWorker.onmessage = (event) => {
        sharedWorkerMessageHandler(JSON.parse(event.data));
    };
}
async function sharedWorkerMessageHandler(data: { type: string; [key: string]: any }) {
    const chatStore = useChatStore();
    const authStore = useAuthStore();
    const userStore = authStore.user.role === 'tutor' ? useTutorStore() : useLearnerStore();

    console.log('Received message from shared worker', data);

    if (data.type === 'message_received') {
        if (data.chatUid === chatStore.activeChat?.uid) {
            const message = chatStore.activeChatMessages.find(
                (m) => m.uid === data.clientMessageId,
            );

            if (message) {
                message.uid = data.messageUid;
                message.isMessageReceivedByServer = true;
                clearTimeout(message.sendFailTimeout);

                chatStore.activeChat!.latestMessageContent = message.content;
                chatStore.activeChat!.latestMessageUid = message.uid;
            }
        }
    } else if (data.type === 'new_message') {
        const isIncomingMessage = userStore.profile.user.uid !== data.senderUid;
        if (isIncomingMessage) {
            userStore.profile.chat.unreadCount += 1;
        }

        const chat = chatStore.chats.find((ch) => ch.uid === data.chatUid);

        if (chat) {
            if (isIncomingMessage) {
                chat.unreadCount += 1;
            }

            chat.latestMessageContent = data.content;
        }

        if (data.chatUid === chatStore.activeChat?.uid) {
            chatStore.addMessageToActiveChat({
                chatUid: data.chatUid,
                content: data.content,
                senderUid: data.senderUid,
                uid: data.messageUid,
                isMessageDelivered: true,
                isMessageReceivedByServer: true,
                createdAt: new Date().toISOString(),
                deletedAt: null,
                editedAt: null,
            });
        }

        if (isIncomingMessage) {
            sendMessageToShw({
                type: 'mark_as_delivered',
                chatUid: data.chatUid,
                messageUid: data.messageUid,
            });
        }
    } else if (data.type === 'typed') {
        chatStore.setChatParticipantTyping(data.chatUid);
    } else if (data.type === 'message_delivered') {
        if (data.chatUid === chatStore.activeChat?.uid) {
            const message = chatStore.activeChatMessages.find((m) => m.uid === data.messageUid);

            if (message) {
                message.isMessageDelivered = true;
            }
        }
    } else if (data.type === 'message_read') {
        const message = chatStore.activeChatMessages.find((m) => m.uid === data.messageUid);

        if (message) {
            if (
                chatStore.activeChat &&
                message.uid > (chatStore.activeChat.otherParticipant.latestReadMessageUid as string)
            ) {
                chatStore.activeChat.otherParticipant.latestReadMessageUid = message.uid;
                sendMessageToShw({
                    type: 'mark_as_read',
                    chatUid: chatStore.activeChat?.uid,
                    messageUid: message.uid,
                });
            }
        }
    } else if (data.type === 'reconnect') {
        await userStore.fetch();
        await chatStore.fetchChats();

        if (chatStore.activeChat) {
            await chatStore.setActiveChat(chatStore.activeChat.uid);
        }
    }
}
