import type { paths } from '../../../../../../packages/api-client/src';
import { createClientProxy } from '@/core/api/client-proxy';
import { PatchLearnerDto } from '@/modules/learner/api/learner.api';

export type User = paths['/user/']['patch']['responses']['200']['content']['application/json'];

const client = createClientProxy<paths>({
    errorNotify: false,
    errorThrow: false,
});

export class UserApi {
    async patchLearnerData(data: PatchLearnerDto) {
        return await client.PATCH('/user/', { body: data });
    }
}

export const useUserApi = () => new UserApi();
