import SurveyDemoPage from '@/pages/SurveyDemoPage.vue';
import UiKitPage from '@/pages/UiKitPage.vue';
import { RouteRecordRaw } from 'vue-router';

export enum DemoRoutes {
    UiKit = 'DemoUiKit',
    SurveyDemoPage = 'DemoSurveyDemoPage',
}

export const demoRoutes: RouteRecordRaw[] = [
    {
        path: '/ui-kit',
        name: DemoRoutes.UiKit,
        component: UiKitPage,
    },
    {
        path: '/survey',
        name: DemoRoutes.SurveyDemoPage,
        component: SurveyDemoPage,
    },
];
