import { ref, ComponentPublicInstance, Component, onUnmounted, onMounted, watch } from 'vue';

type ExcludeInstanceProps<T> = Omit<T, keyof ComponentPublicInstance>;

type Notification<C extends Component = any> = {
    id: number;
    title?: string;
    message?: string;
    slotComponent?: C;
    slotProps?: C extends Component<infer P>
        ? keyof ExcludeInstanceProps<P> extends never
            ? undefined // NOTE: if component doesn't have any props
            : ExcludeInstanceProps<P>
        : Record<string, any>;
    type?: 'success' | 'error' | 'info' | 'warning';
    onClose?: (id: number) => void;
    onOpen?: (id: number) => void;
} & Options;

type Options = {
    duration: number;
    closable: boolean;
    mode: 'modal' | 'notification';
};

const defaultOptions: Options = {
    duration: 5000,
    closable: true,
    mode: 'notification',
};

// FIXME: Add normal merge. This merge leads to error if right is {duration: undefined};
const merge = <T extends Record<string, any>>(left: T, right: Record<string, unknown>): T => {
    return JSON.parse(JSON.stringify({ ...left, ...right }));
};

const notifications = ref<Notification[]>([]);

const maxNotifications = 1;

export const useNotification = (options: Partial<Options> = {}) => {
    const mergedOptions: Options = merge(defaultOptions, options);

    const addNotification = <C extends Component>(notification: Partial<Notification<C>>) => {
        const id = Date.now();
        if (notifications.value.length >= maxNotifications) {
            notifications.value.shift();
        }

        notifications.value.push({
            id,
            duration: mergedOptions.duration,
            closable: mergedOptions.closable,
            mode: mergedOptions.mode,
            ...notification,
        });

        const currentNotification = notifications.value.find((n) => n.id === id)!;
        currentNotification.onOpen?.(id);

        if (typeof currentNotification.duration === 'number' && currentNotification.duration > 0) {
            setTimeout(() => {
                closeById(id);
            }, currentNotification.duration);
        }

        return id;
    };

    const success: typeof addNotification = (opts) => addNotification({ ...opts, type: 'success' });
    const error: typeof addNotification = (opts) => addNotification({ ...opts, type: 'error' });
    const info: typeof addNotification = (opts) => addNotification({ ...opts, type: 'info' });
    const warn: typeof addNotification = (opts) => addNotification({ ...opts, type: 'warning' });
    const create: typeof addNotification = (opts) => addNotification({ ...opts });

    const closeById = (id: number) => {
        const notification = notifications.value.find((n) => n.id === id);
        notifications.value = notifications.value.filter((n) => n.id !== id);
        notification?.onClose?.(id);
    };

    return {
        notifications,
        closeById,
        success,
        error,
        info,
        warn,
        create,
    };
};
