<script setup lang="ts">
import {
    IonContent,
    IonToolbar,
    IonTitle,
    IonRow,
    IonPage,
    IonIcon,
    IonFooter,
    IonModal,
    IonButtons,
    IonRouterOutlet,
    useIonRouter,
    IonHeader,
} from '@ionic/vue';
import { t } from '@/plugins/i18n';
import LLanguageSwitcher from '../components/LLanguageSwitcher.vue';
import LLogo from '@/core/components/LLogo.vue';
import { chatbubblesOutline, chevronBackOutline } from 'ionicons/icons';
import { ref } from 'vue';
import { useAuthStore } from '@/modules/auth/store/auth.store';
import LButton from '../components/LButton.vue';
import { CommonRoutes } from '@/router/common.routes';
import { useRoute } from 'vue-router';
import { computed } from 'vue';
import LProfileMenu from '@/modules/learner/layouts/LProfileMenu.vue';
import { useScreenSize } from '../utils/use-screen-size';
import { LearnerRoutes } from '@/router/learner.routes';
import { SUPPORT_CHAT_UID } from '@common/constants/constant';
import LBackButton from '../components/LBackButton.vue';

const isOpenProfile = ref(false);
const authStore = useAuthStore();
const router = useIonRouter();
const route = useRoute();

const setOpenProfileModal = (open: boolean) => (isOpenProfile.value = open);

const onLogout = async () => {
    await authStore.logout();
};

const toChat = () =>
    router.push({ name: LearnerRoutes.OnboardingChat, params: { chatUid: SUPPORT_CHAT_UID } });
const noHelpFooter = computed(() => route.meta.noHelpFooter);
const { isLargeSize } = useScreenSize();

const handleBack = () => router.back();
</script>

<template>
    <ion-page>
        <ion-header
            class="header-container"
            slot="top"
        >
            <div class="header-content">
                <div v-if="!isLargeSize">
                    <l-back-button position="header" />
                </div>

                <l-logo
                    v-if="isLargeSize"
                    class="mr-8 h-11"
                />
                <!-- Add switcher for TESTING  -->
                <div class="profile-container">
                    <l-language-switcher />
                    <l-profile-menu />
                </div>
            </div>
        </ion-header>

        <ion-content class="ion-padding">
            <ion-router-outlet :animated="false" />
        </ion-content>

        <ion-footer
            v-if="!noHelpFooter"
            collapse="fade"
        >
            <ion-toolbar
                class="footer-toolbar"
                @click="toChat"
            >
                <div class="footer-toolbar">
                    <ion-icon
                        style="margin-right: 8px"
                        size="small"
                        :icon="chatbubblesOutline"
                    ></ion-icon>
                    {{ t('learner.onboarding.footer') }}
                </div>
            </ion-toolbar>
        </ion-footer>

        <ion-modal :is-open="isOpenProfile">
            <ion-toolbar>
                <ion-title>PROFILE</ion-title>
                <ion-buttons slot="end">
                    <l-button @click="setOpenProfileModal(false)">Close</l-button>
                </ion-buttons>
            </ion-toolbar>
            <ion-content class="ion-padding">
                <ion-row>
                    <l-language-switcher />
                    <l-button
                        color="danger"
                        @click="onLogout"
                        >{{ t('auth.logout') }}</l-button
                    >
                </ion-row>
            </ion-content>
        </ion-modal>
    </ion-page>
</template>

<style scoped lang="scss">
@use '@/css/ui-kit.scss' as *;

.header-container {
    background-color: $grey-100;
    border-bottom: 1px solid $grey-400;

    .header-content {
        height: 4.355rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 0.5rem;
        padding: 0.5rem 1.25rem;

        @media screen and (min-width: $breakpoint-lg) {
            max-width: var(--page-max-width, 1280px);
            margin: 0 auto;
        }
    }
}

.footer-toolbar {
    background-color: $grey-100;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid $grey-400;
}

.language-select {
    margin-right: 26px;
}

.profile-container {
    display: flex;
}

.profile-button,
.back-button {
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
