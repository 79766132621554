<script setup lang="ts">
import { ref } from 'vue';
import { TutorProfile, useLearnerApi } from '../api/learner.api';
import LScheduleTimeCheckout from '../components/payment/LScheduleTimeCheckout.vue';
import LPage from '@/core/components/LPage.vue';
import { onIonViewWillEnter } from '@ionic/vue';
import { LessonDuration } from '../types';
import { ISODateString } from '@common/types';

const learnerApi = useLearnerApi();

const { tutorUserUid, timeSlots, lessonDuration } = defineProps<{
    tutorUserUid: string;
    timeSlots: ISODateString[];
    lessonDuration: LessonDuration;
}>();

const tutor = ref<TutorProfile | null>(null);
const isLoading = ref(false);

const fetchData = async () => {
    if (!tutorUserUid) return;
    try {
        isLoading.value = true;

        tutor.value = await learnerApi.getTutor(tutorUserUid);
    } catch (e) {
        console.error(e);
    } finally {
        isLoading.value = false;
    }
};

onIonViewWillEnter(fetchData);
</script>

<template>
    <l-page class="page-container">
        <l-schedule-time-checkout
            :isLoading="isLoading || !tutor"
            :tutor="tutor!"
            :slots="timeSlots"
            :duration="lessonDuration"
        />
    </l-page>
</template>

<style scoped>
.page-container {
    --ion-grid-padding: 0;
    --ion-grid-column-padding: 0.625rem;
}
</style>
