<script setup lang="ts">
import { IonIcon, useIonRouter } from '@ionic/vue';
import { ref, watch } from 'vue';
import { useAuthStore } from '@/modules/auth/store/auth.store';
import LButton from '@/core/components/LButton.vue';
import LInput from '@/core/components/LInput.vue';
import LLogo from '@/core/components/LLogo.vue';
import LFeedback from '@/core/components/LFeedback.vue';
import { eyeOutline, eyeOffOutline } from 'ionicons/icons';
import { CommonRoutes } from '@/router/common.routes';
import { t } from '@/plugins/i18n';

const router = useIonRouter();

const authStore = useAuthStore();

const isPasswordVisible = ref(false);
const isValidationFailed = ref(false);

const togglePasswordVisible = () => {
    isPasswordVisible.value = !isPasswordVisible.value;
};

/** Define the reactive login form data using ref */
const loginForm = ref({
    email: '',
    password: '',
});

/** Login function that handles form validation and navigation */
const onLogin = async () => {
    if (!loginForm.value.email || !loginForm.value.password) {
        isValidationFailed.value = true;
        return;
    }

    const success = await authStore.login(loginForm.value.email, loginForm.value.password);
    if (success) {
        isValidationFailed.value = false;
        router.push({ name: CommonRoutes.Home });
    } else {
        isValidationFailed.value = true;
    }
};

const toRegister = () => router.push({ name: CommonRoutes.SignUp });
const toResetPassword = () => router.push({ name: CommonRoutes.ResetPassword });

watch(isValidationFailed, () => {
    if (isValidationFailed.value) {
        isPasswordVisible.value = true;
    }
});
</script>

<template>
    <div class="container px-4 pt-16">
        <div class="inner-container">
            <l-logo class="mx-auto mb-8 h-16" />

            <div class="caption-2 mb-6 text-center">{{ t('auth.login.title') }}</div>

            <div class="w-full mb-4">
                <label
                    for="login"
                    class="block caption-3 mb-1"
                    >{{ t('auth.login.emailLabel') }}</label
                >
                <l-input
                    v-model="loginForm.email"
                    type="email"
                    :placeholder="t('auth.login.emailPlaceholder')"
                    :color="isValidationFailed ? 'danger' : undefined"
                >
                </l-input>
            </div>

            <div class="w-full mb-2">
                <label
                    for="login"
                    class="block caption-3 mb-1"
                    >{{ t('auth.login.password') }}</label
                >
                <l-input
                    v-model="loginForm.password"
                    :type="isPasswordVisible ? 'text' : 'password'"
                    :placeholder="t('auth.login.passwordPlaceholder')"
                    :color="isValidationFailed ? 'danger' : undefined"
                >
                    <l-button
                        @click="togglePasswordVisible"
                        variant="text"
                        slot="end"
                        aria-label="Show/hide"
                        class="pr-4 text-black"
                    >
                        <ion-icon
                            v-if="isPasswordVisible"
                            slot="icon-only"
                            :icon="eyeOutline"
                            aria-hidden="true"
                        ></ion-icon>
                        <ion-icon
                            v-else
                            slot="icon-only"
                            :icon="eyeOffOutline"
                            aria-hidden="true"
                        ></ion-icon>
                    </l-button>
                </l-input>
            </div>

            <l-feedback
                color="danger-tint"
                v-if="isValidationFailed"
                >{{ t('auth.login.validationFailed') }}</l-feedback
            >

            <l-button
                class="mt-6"
                expand="block"
                :disabled="!loginForm.email || !loginForm.password"
                @click="onLogin"
            >
                {{ t('auth.login.submit') }}
            </l-button>

            <p class="body-3 text-center mt-2">
                {{ t('auth.signup.policyQuestion') }}
                <a
                    href="https://files.learnlink.com/legal/terms_of_service_en.pdf"
                    class="underline"
                    >{{ t('auth.signup.termsOfService') }}</a
                >
                {{ t('auth.signup.and') }}
                <a
                    href="https://files.learnlink.com/legal/privacy_policy_en.pdf"
                    class="underline"
                    >{{ t('auth.signup.privacyPolicy') }}</a
                >.
            </p>

            <div class="text-center w-full mt-6 caption-4">
                <p>
                    {{ t('auth.login.noAccount') }}
                    <a
                        href="/signup"
                        @click.prevent="toRegister"
                        class="purple-primary"
                        >{{ t('auth.login.register') }}</a
                    >
                </p>
            </div>
            <div class="text-center w-full mt-6">
                <a
                    href="/reset-password"
                    @click.prevent="toResetPassword"
                    class="purple-primary"
                    >{{ t('auth.login.resetPassword') }}</a
                >
            </div>
        </div>
    </div>
</template>

<style scoped>
.container {
    .inner-container {
        margin: 0 auto;
        max-width: 450px;
    }
}

.purple-primary {
    color: var(--ion-color-success);
}
</style>
