<script setup lang="ts">
import LPage from '@/core/components/LPage.vue';
import LTutorProfile from '../components/tutor-profile/LTutorProfile.vue';
import { useRoute } from 'vue-router';

const route = useRoute();
const tutorUserUid = (route.params?.tutorUserUid as string) ?? '';
</script>

<template>
    <l-page>
        <l-tutor-profile
            :is-onboarding="true"
            :tutor-user-uid="tutorUserUid"
        />
    </l-page>
</template>

<style scoped lang="scss"></style>
