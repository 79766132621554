<script lang="ts" setup>
import { IonIcon, IonRow, IonCol } from '@ionic/vue';
import { ref, computed } from 'vue';
import { useLocaleDateFormat } from '@/core/utils/use-locale-date-format';
import { chevronBackOutline, chevronForwardOutline } from 'ionicons/icons';
import LButton from '@/core/components/LButton.vue';
import { eachDayOfInterval, isSameDay } from 'date-fns';

const format = useLocaleDateFormat();
type Props = {
    selectedDate: Date;
    weekStartDate: Date;
    weekEndDate: Date;
};

const props = defineProps<Props>();
const emit = defineEmits<{
    (event: 'updateDate', date: Date): void;
    (event: 'prevWeek'): void;
    (event: 'nextWeek'): void;
}>();

const today = new Date();

const weekDays = computed(() =>
    eachDayOfInterval({
        start: props.weekStartDate,
        end: props.weekEndDate,
    }),
);

const formattedWeek = computed(() => {
    const start = format(props.weekStartDate, 'd MMMM');
    const end = format(props.weekEndDate, 'd MMMM');

    return `${start} - ${end}`;
});

const disablePrevWeekButton = computed(() => isSameDay(props.weekStartDate, today));

const prevWeek = () => {
    emit('prevWeek');
};

const nextWeek = () => {
    emit('nextWeek');
};

const selectDay = (day: Date) => {
    emit('updateDate', day);
};

const isSelected = (day: Date) => {
    return day.toDateString() === props.selectedDate.toDateString();
};
</script>

<template>
    <ion-row style="align-items: center">
        <ion-col size="auto">
            <l-button
                @click="prevWeek"
                variant="tertiary"
                class="week-button"
                :disabled="disablePrevWeekButton"
            >
                <ion-icon
                    :icon="chevronBackOutline"
                    color="dark"
            /></l-button>
        </ion-col>
        <ion-col>
            <div class="selected-week caption-4">
                {{ formattedWeek }}
            </div>
        </ion-col>
        <ion-col size="auto">
            <l-button
                @click="nextWeek"
                variant="tertiary"
                class="week-button"
            >
                <ion-icon
                    :icon="chevronForwardOutline"
                    color="dark"
                />
            </l-button>
        </ion-col>
    </ion-row>
    <ion-row class="mt-4 justify-start items-center">
        <ion-col size="12">
            <div class="week-days-container">
                <div
                    v-for="day of weekDays"
                    :key="day.toISOString()"
                    style="flex: 1"
                    :class="{ 'selected-day': isSelected(day), 'week-day': true }"
                    @click="selectDay(day)"
                >
                    <div class="day caption-3">
                        {{ format(day, 'EEEEEE') }}
                    </div>
                    <div class="caption-6">
                        {{ format(day, 'd.MM') }}
                    </div>
                </div>
            </div>
        </ion-col>
    </ion-row>
</template>

<style scoped lang="scss">
@use '@/css/ui-kit.scss' as *;

.week-days-container {
    height: 48px;
    background-color: $grey-400;
    border-radius: $default-border-radius;
    display: flex;
    cursor: pointer;
}

.selected-day {
    border-radius: $default-border-radius;
    background-color: $grey-300;
    box-shadow: 0 0 0 2px $grey-700;
}

.week-selector {
    display: flex;
    height: 40px;
    gap: 16px;
    align-items: center;
    justify-content: space-between;
}
.week-button {
    --padding-top: 6px;
    --padding-bottom: 6px;
    min-height: 40px;
}

.selected-week {
    text-align: center;
}

.week-day {
    .day {
        text-transform: capitalize;
        text-align: center;
    }

    .caption-6 {
        font-size: 10px;
        text-align: center;
        color: $grey-600;
    }

    padding: 4px 8px;
    display: flex;
    justify-content: center;
    gap: 4px;
    flex: 1;
    flex-direction: column;
}
</style>
