<script setup lang="ts">
import { t } from '@/plugins/i18n';
import { ref, computed, onMounted } from 'vue';
import { IonAlert, IonGrid, IonRow, IonCol, IonIcon, IonPopover, IonContent } from '@ionic/vue';
import LHero from '@/core/components/LHero.vue';
import LDateSelector from '../booking/LDateSelector.vue';
import LTimeSlots from '../booking/LTimeSlots.vue';
import {
    AvaliableSlot,
    GetAvaliableSlotsParams,
    TutorProfile,
    useLearnerApi,
} from '../../api/learner.api';
import { useRouter } from 'vue-router';
import LButton from '@/core/components/LButton.vue';
import {
    addDays,
    addWeeks,
    differenceInHours,
    endOfDay,
    format,
    isSameDay,
    startOfDay,
    subWeeks,
} from 'date-fns';
import { LearnerRoutes } from '@/router/learner.routes';
import LPricingInfo from '@/core/components/LPricingInfo.vue';
import LChipList from '@/core/components/LChipList.vue';
import LSkeletonTutorList from '../tutors-search/LSkeletonTutorList.vue';
import { chatboxOutline, schoolOutline } from 'ionicons/icons';
import { useScreenSize } from '@/core/utils/use-screen-size';
import LIsland from '@/core/components/LIsland.vue';

const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const today = new Date();
const selectedDate = ref(today);
const selectedTimeSlot = ref<string | null>(null);
const isLoading = ref<boolean>(false);
const router = useRouter();
const emit = defineEmits(['closeModal']);
const learnerApi = useLearnerApi();
const weekStartDate = ref(startOfDay(today));
const weekEndDate = computed(() => endOfDay(addDays(weekStartDate.value, 6)));

const props = defineProps<{ tutorUserUid: string; isOnboarding: boolean }>();

const tutorProfile = ref<TutorProfile>();
const availableSlots = ref<AvaliableSlot[]>([]);
const isAlert = ref(false);
const alertButtons = ['Ok'];

const { isLargeSize } = useScreenSize();

const isLoadingTimeSlots = ref(false);

const setOpenAlert = (state: boolean) => {
    isAlert.value = state;
};

const timeByLocation = computed(() => `${timeZone.replace('_', ' ')} (${format(today, 'zzzz')})`);

const filteredSlots = computed(() => {
    return getAvailableSlotsForDate(selectedDate.value) as string[];
});

const isTimeSlotSelected = computed(() => Boolean(selectedTimeSlot.value));

const fetchAvailableSlots = async (params: GetAvaliableSlotsParams) => {
    try {
        isLoadingTimeSlots.value = true;
        const response = await learnerApi.getTutorAvailableSlots(params);
        if (response.success) {
            availableSlots.value = response.data!;
        }
    } finally {
        isLoadingTimeSlots.value = false;
    }
};

const fetchTutorProfile = async () => {
    const response = await learnerApi.getTutor(props.tutorUserUid);

    tutorProfile.value = response;
};

const getAvailableSlotsForDate = (filterDate: Date) => {
    const minimumHoursDifference = 12;
    return availableSlots.value
        .map((slot) => new Date(slot.startTime as string))
        .filter(
            (slotTime) =>
                isSameDay(slotTime, filterDate) &&
                differenceInHours(slotTime, today) >= minimumHoursDifference,
        )
        .map((slotTime) => slotTime.toISOString());
};

onMounted(async () => {
    isLoading.value = true;
    await Promise.all([
        fetchTutorProfile(),
        fetchAvailableSlots({
            startTime: weekStartDate.value.toISOString(),
            endTime: weekEndDate.value.toISOString(),
            tutorUserUid: props.tutorUserUid,
        }),
    ]);

    isLoading.value = false;
});

const updateSelectedDate = (date: Date) => {
    selectedDate.value = date;
    selectedTimeSlot.value = null;
};

const prevWeek = async () => {
    weekStartDate.value = subWeeks(weekStartDate.value, 1);
    await updateAvailebleSlots();
};

const nextWeek = async () => {
    weekStartDate.value = addWeeks(weekStartDate.value, 1);
    await updateAvailebleSlots();
};

async function updateAvailebleSlots() {
    isLoadingTimeSlots.value = true;
    try {
        await fetchAvailableSlots({
            startTime: weekStartDate.value.toISOString(),
            endTime: weekEndDate.value.toISOString(),
            tutorUserUid: props.tutorUserUid,
        });
    } catch (error) {
        console.error(error, 'fetchAvailableSlots');
    } finally {
        isLoadingTimeSlots.value = false;
    }
    updateSelectedDate(weekStartDate.value);
}

const selectTimeSlot = (timeSlot: string) => {
    selectedTimeSlot.value = timeSlot;
};

const proceedBooking = async () => {
    if (selectedTimeSlot.value) {
        const selectedSlot = availableSlots.value.find(
            (item) => item.startTime === selectedTimeSlot.value,
        );

        if (!selectedSlot) return;

        router.push({
            name: props.isOnboarding
                ? LearnerRoutes.TrialBookingOnboarding
                : LearnerRoutes.TrialBooking,
            query: {
                uid: selectedSlot.tutorUserUid,
                start: selectedSlot.startTime as string,
                end: selectedSlot.endTime as string,
            },
        });
    } else {
        setOpenAlert(true);
    }
};
const enableBook = computed(
    () => tutorProfile.value?.isAllowedTrialLesson && isTimeSlotSelected?.value,
);

const toChat = async () => {
    // TODO: add chatUid
    router.push({
        name: LearnerRoutes.Chat,
        params: { chatUid: tutorProfile.value?.chatUid },
    });
};
</script>

<template>
    <ion-alert
        :is-open="isAlert"
        :header="t(`learner.booking.alertTitle`)"
        :buttons="alertButtons"
        @didDismiss="setOpenAlert(false)"
    ></ion-alert>
    <ion-grid class="wrapper">
        <ion-row>
            <ion-col
                size-lg="7"
                size-sm="12"
                size-xs="12"
            >
                <l-island>
                    <l-skeleton-tutor-list
                        v-if="isLoading"
                        :length="1"
                    />
                    <ion-row v-else>
                        <ion-col>
                            <p
                                v-if="isOnboarding"
                                class="caption-2 mb-4"
                            >
                                {{ t('learner.onboarding.bookTime.title') }}
                            </p>
                            <!-- TODO: "reviews" prop needs backend implementation-->
                            <l-hero
                                class="tutor-card mb-6"
                                :name="tutorProfile?.tutorName!"
                                :avatar="tutorProfile?.tutorAvatar"
                                :rating="tutorProfile?.rating"
                                :reviews="243"
                                :message="
                                    props.isOnboarding
                                        ? t('learner.onboarding.bookTime.tutorMessage')
                                        : ''
                                "
                            ></l-hero>
                            <l-pricing-info
                                v-if="tutorProfile?.pricing"
                                :pricing="tutorProfile?.pricing"
                            />
                            <l-chip-list
                                :icon="schoolOutline"
                                :list="tutorProfile?.subjectCodes ?? []"
                            />
                            <l-button
                                v-if="tutorProfile?.chatUid"
                                class="w-full"
                                variant="secondary"
                                @click="toChat"
                            >
                                <ion-icon
                                    slot="start"
                                    :icon="chatboxOutline"
                                ></ion-icon>
                                {{ t('learner.tutorCatalog.tutorProfile.sendMessage') }}
                            </l-button>
                            <div
                                v-if="!isLargeSize"
                                class="profile-video-container mt-6"
                            >
                                <iframe
                                    :src="tutorProfile?.profileVideo"
                                    width="100%"
                                    height="250px"
                                    allowfullscreen
                                ></iframe>
                            </div>

                            <p class="caption-3 mt-4">{{ tutorProfile?.profileTitle }}</p>
                            <p class="body-2 mt-2">{{ tutorProfile?.profileDescription }}</p>
                        </ion-col>
                    </ion-row>
                </l-island>
                <l-island class="mt-4">
                    <l-skeleton-tutor-list
                        v-if="isLoading"
                        :length="1"
                    />
                    <div v-else>
                        <l-date-selector
                            :selectedDate="selectedDate"
                            :weekStartDate="weekStartDate"
                            :weekEndDate="weekEndDate"
                            @updateDate="updateSelectedDate"
                            @prevWeek="prevWeek"
                            @nextWeek="nextWeek"
                        />

                        <ion-row class="mt-4">
                            <ion-col>
                                <div class="time-by-location body-2">
                                    {{ t('learner.onboarding.bookTime.locationTime') }}
                                    <p>{{ timeByLocation }}</p>
                                </div>
                            </ion-col>
                        </ion-row>
                        <ion-row class="mt-4">
                            <ion-col size="12">
                                <l-time-slots
                                    :disabled="!tutorProfile?.isAllowedTrialLesson"
                                    :isLoading="isLoadingTimeSlots"
                                    :availableSlots="filteredSlots"
                                    :selected="selectedTimeSlot"
                                    @select="selectTimeSlot"
                                />
                            </ion-col>
                        </ion-row>

                        <ion-row class="ion-justify-content-center mt-4">
                            <ion-col
                                size="12"
                                size-lg="4"
                            >
                                <div id="proceedBooking">
                                    <l-button
                                        @click="proceedBooking"
                                        :disabled="!enableBook"
                                        expand="block"
                                        class="book-button"
                                        >{{ t('learner.booking.submitButton') }}
                                    </l-button>
                                </div>
                                <ion-popover
                                    v-if="!enableBook"
                                    :showBackdrop="false"
                                    alignment="center"
                                    trigger="proceedBooking"
                                >
                                    <ion-content class="ion-padding">{{
                                        t('learner.booking.submitPopup')
                                    }}</ion-content>
                                </ion-popover>
                            </ion-col>
                        </ion-row>
                    </div>
                </l-island>
            </ion-col>
            <ion-col
                v-show="isLargeSize"
                size="5"
            >
                <div
                    v-if="!isLoading && isLargeSize"
                    class="pl-4 profile-video-container"
                >
                    <iframe
                        :src="tutorProfile?.profileVideo"
                        width="100%"
                        height="250px"
                        allowfullscreen
                    ></iframe>
                </div>
            </ion-col>
        </ion-row>
    </ion-grid>
</template>

<style scoped lang="scss">
@use '@/css/ui-kit' as *;

ion-popover {
    --width: 23rem;
}

.heading {
    text-align: center;
}

.wrapper {
    --ion-grid-column-padding: 0;
    display: flex;
    gap: 2rem;
    flex-direction: column;
}

.book-button {
    margin: auto;
}

.profile-video-container {
    iframe {
        border: 2px solid $grey-400;
        border-radius: $default-border-radius;
    }
}

.time-by-location {
    color: $grey-700;
}
</style>
