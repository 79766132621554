<script setup lang="ts">
import { IonRow, IonCol, IonIcon, IonPopover, onIonViewWillEnter } from '@ionic/vue';
import LPage from '@/core/components/LPage.vue';
import LIsland from '@/core/components/LIsland.vue';
import LButton from '@/core/components/LButton.vue';
import LLinksArray from '@/core/components/LLinksArray/LLinksArray.vue';
import LLinksArrayItem from '@/core/components/LLinksArray/LLinksArrayItem.vue';
import LDeleteCardModal from '../components/payment/LDeleteCardModal.vue';
import {
    chevronForward,
    ellipsisHorizontal,
    trash,
    informationCircleOutline,
} from 'ionicons/icons';

import LBadge from '@/core/components/LBadge.vue';
import { capitalize, computed, ref } from 'vue';
import { useNotification } from '@/core/composables/use-notification';
import { useScreenSize } from '@/core/utils/use-screen-size';
import { t } from '@/plugins/i18n';
import { PaymentMethod, useLearnerApi } from '../api/learner.api';

const learnerApi = useLearnerApi();

const paymentMethods = ref<PaymentMethod[]>([]);

const notify = useNotification();
const { isLargeSize } = useScreenSize();
const isLoading = ref(false);

const infoEvent = ref<MouseEvent | null>();
const contextMenuIsOpenedFor = ref<PaymentMethod | null>(null);
const contextMenuEvent = ref<MouseEvent | null>(null);

const hasOnlyOneCard = computed(() => paymentMethods.value.length === 1);

const fetchData = async () => {
    try {
        isLoading.value = true;
        const { data } = await learnerApi.getPaymentMethods();

        paymentMethods.value = data || [];
    } catch (error) {
        notifyUndefinedError();
    } finally {
        isLoading.value = false;
    }
};

onIonViewWillEnter(fetchData);

const notifyUndefinedError = () =>
    notify.error({
        title: t('learner.payment.methods.error.undefinedTitle'),
        message: t('learner.payment.methods.error.undefinedMessage'),
    });

const notifyCardDeletionSuccess = (card: PaymentMethod) =>
    notify.success({
        title: t('learner.payment.methods.deletionSuccess.title'),
        message: t('learner.payment.methods.deletionSuccess.message', {
            provider: capitalize(card.cardBrand),
            cardNumber: card.cardLast4Digits,
        }),
    });

const notifyCardDefaultSuccess = (card: PaymentMethod) =>
    notify.success({
        title: t('learner.payment.methods.methodChanged'),
        message: t('learner.payment.methods.nextPayment', {
            provider: capitalize(card.cardBrand),
            cardNumber: card.cardLast4Digits,
        }),
    });

const handleDeleteCard = (card: PaymentMethod) => {
    const onClose = () => notify.closeById(id);

    const onRemove = async () => {
        const { success } = await learnerApi.deletePaymentMethod(card.uid);
        if (!success) {
            notifyUndefinedError();
            return;
        }
        onClose();

        await fetchData();
        notifyCardDeletionSuccess(card);
    };

    const id = notify.error({
        mode: 'modal',
        duration: 0,
        title: t('learner.payment.methods.deleteConfirmation', {
            provider: capitalize(card.cardBrand),
            cardNumber: card.cardLast4Digits,
        }),
        slotComponent: LDeleteCardModal,
        slotProps: { onClose, onRemove },
    });
};

const handleAddPayment = async () => {
    const { data, success } = await learnerApi.addPaymentMethod();
    if (!success || !data) {
        notifyUndefinedError();
        return;
    }

    location.replace(data.paymentPageUrl);
};

const handleMakeDefault = async (card: PaymentMethod) => {
    const { success } = await learnerApi.patchPaymentMethod(card.uid, { isDefault: true });

    if (!success) {
        notifyUndefinedError();
        return;
    }

    await fetchData();

    notifyCardDefaultSuccess(card);
};

const handleOpenContextMenu = (card: PaymentMethod, event: MouseEvent) => {
    contextMenuIsOpenedFor.value = card;
    contextMenuEvent.value = event;
};

const infoTooltipPosition = computed(() =>
    isLargeSize ? { side: 'right', alignment: 'center' } : { side: 'top', alignment: 'center' },
);
</script>

<template>
    <l-page class="page-container">
        <ion-row class="mt-4">
            <ion-col
                size="12"
                size-lg="6"
            >
                <ion-row class="gap-y-12">
                    <l-island>
                        <h5 class="pb-8">{{ t('learner.payment.methods.title') }}</h5>

                        <l-links-array :isLoading="isLoading">
                            <l-links-array-item
                                v-for="card of paymentMethods"
                                :key="card.uid"
                                :selected="card.uid === contextMenuIsOpenedFor?.uid"
                            >
                                <div class="w-full">
                                    <span class="capitalize">{{ card.cardBrand }}</span>
                                    <span> · </span>
                                    <span>{{ card.cardLast4Digits }}</span>
                                </div>
                                <div class="flex gap-2 items-center">
                                    <l-badge
                                        v-if="card.isDefault"
                                        type="success"
                                        :message="t('learner.payment.methods.default')"
                                    ></l-badge>
                                    <l-button
                                        v-show="hasOnlyOneCard"
                                        variant="text"
                                        @click="infoEvent = $event"
                                        class="-m-[5px]"
                                    >
                                        <ion-icon
                                            class="text-2xl"
                                            :icon="informationCircleOutline"
                                            slot="icon-only"
                                        ></ion-icon>
                                    </l-button>
                                    <l-button
                                        v-if="!hasOnlyOneCard && !card.isDefault"
                                        variant="text"
                                        class="-m-[5px]"
                                        @click="handleOpenContextMenu(card, $event)"
                                    >
                                        <ion-icon
                                            class="text-2xl"
                                            :icon="ellipsisHorizontal"
                                            slot="icon-only"
                                        ></ion-icon>
                                    </l-button>
                                </div>
                            </l-links-array-item>
                            <l-links-array-item
                                @click="handleAddPayment"
                                clickable
                                :text="t('learner.payment.methods.addNewCard')"
                                :after-icon="chevronForward"
                            />
                        </l-links-array>
                    </l-island>

                    <ion-popover
                        :isOpen="Boolean(contextMenuIsOpenedFor)"
                        :dismiss-on-select="true"
                        :arrow="false"
                        alignment="top"
                        side="start"
                        :showBackdrop="false"
                        class="context-menu"
                        :event="contextMenuEvent"
                        @didDismiss="contextMenuIsOpenedFor = null"
                    >
                        <l-button
                            variant="text"
                            expand="full"
                            size="small"
                            class="menu-item"
                            @click="handleMakeDefault(contextMenuIsOpenedFor!)"
                        >
                            {{ t('learner.payment.methods.setDefault') }}
                        </l-button>
                        <l-button
                            variant="text"
                            color="danger"
                            expand="full"
                            size="small"
                            :disabled="contextMenuIsOpenedFor!.isDefault"
                            class="menu-item"
                            @click="handleDeleteCard(contextMenuIsOpenedFor!)"
                        >
                            {{ t('learner.payment.methods.delete') }}

                            <ion-icon
                                :icon="trash"
                                slot="end"
                                color="danger"
                            />
                        </l-button>
                    </ion-popover>

                    <ion-popover
                        :side="infoTooltipPosition.side"
                        :alignment="infoTooltipPosition.alignment"
                        size="auto"
                        :isOpen="Boolean(infoEvent)"
                        :event="infoEvent"
                        @didDismiss="infoEvent = null"
                        :showBackdrop="false"
                    >
                        <div class="p-2 body-3">
                            {{ t('learner.payment.methods.addNewMethodTooltip') }}
                        </div>
                    </ion-popover>
                </ion-row>
            </ion-col>
        </ion-row>
    </l-page>
</template>

<style scoped lang="scss">
@use '@/css/ui-kit' as *;
.page-container {
    --ion-grid-padding: 0;
    --ion-grid-column-padding: 0;
}

.context-menu {
    &::part(content) {
        border: 2px solid $grey-800;
    }
    // FIXME: button with icon has size 34px
    --offset-x: 34px;
    --background: #{$grey-300};
}
</style>
