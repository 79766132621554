<script lang="ts">
export type LSegmentOption = {
    value: string;
    title: string;
    caption?: string;
    disabled?: boolean;
    highlighted?: boolean;
};
</script>

<script setup lang="ts">
import { computed } from 'vue';

type Props = {
    selected: string;
    options: LSegmentOption[];
};

const { selected, options } = defineProps<Props>();

const emits = defineEmits<{ change: [value: string] }>();

const selectedIndex = computed(() => options.findIndex((o) => o.value === selected));

const optionLeftOffset = computed(() =>
    selectedIndex.value > 0 ? optionWidth.value * selectedIndex.value : 0,
);
const optionWidth = computed(() => (options.length > 0 ? 100 / options.length : 0));

const handleClick = (value: string) => emits('change', value);
</script>

<template>
    <div class="l-segment">
        <label
            v-for="option of options"
            class="option"
            :key="option.value"
            :class="{
                highlighted: option.highlighted,
                disabled: option.disabled,
            }"
        >
            <input
                type="radio"
                :value="option.value"
                :checked="option.value === selected"
                :disabled="option.disabled"
                @change="handleClick(option.value)"
            />
            <div class="caption-3">
                <div>
                    <strong>{{ option.title }}</strong>
                </div>
                <div v-if="option.caption">
                    <span class="body-2 caption">{{ option.caption }}</span>
                </div>
            </div>
        </label>

        <div
            class="border-animation"
            :style="{
                left: `${optionLeftOffset}%`,
                width: `${optionWidth}%`,
            }"
        ></div>
    </div>
</template>

<style scoped lang="scss">
@use '@/css/ui-kit' as *;

.l-segment {
    display: flex;
    background-color: $grey-300;
    border-radius: $default-border-radius;
    margin-bottom: 2rem;
    position: relative;

    .option {
        max-height: 3rem;
        min-height: 3rem;
        display: flex;
        flex: 1 1 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        border-radius: $default-border-radius;
        padding: 0.25rem 0.5rem;
        margin: 2px;

        cursor: pointer;
        text-align: center;

        &.highlighted {
            background-color: $grey-400;
        }

        &.disabled {
            opacity: 0.3;
            cursor: default;
        }

        & .caption {
            color: $grey-600;
        }

        & input {
            display: none;
        }
    }

    .border-animation {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        border: 2px solid $grey-700;
        border-radius: $default-border-radius;
        pointer-events: none;
        transition:
            left 0.2s ease-in-out,
            background-color 0.2s ease-in-out;
        background-color: transparent;
    }
}
</style>
