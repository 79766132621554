<script lang="ts" setup>
import { IonButton } from '@ionic/vue';
import { AnimationBuilder, Color, RouterDirection } from '@ionic/core';
import { RouteLocationRaw } from 'vue-router';
import { computed } from 'vue';

type LButtonVariant =
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'destructive'
    | 'primary-alt'
    | 'borderless'
    // NOTE: There is no such variant in design. Use it with text if you don't need horizontal paddings
    | 'text';

interface Props {
    color?: Color;
    disabled?: boolean;
    expand?: 'full' | 'block' | undefined;
    routerAnimation?: AnimationBuilder | undefined;
    routerDirection?: RouterDirection;
    routerLink?: RouteLocationRaw;
    size?: 'default' | 'small' | 'large';
    strong?: boolean;
    type?: 'button' | 'submit' | 'reset';
    variant?: LButtonVariant;
}

const {
    type = 'button',
    size = 'default',
    disabled = false,
    expand,
    routerLink,
    variant = 'primary',
    strong,
    color,
} = defineProps<Props>();

const emit = defineEmits<{
    (e: 'click', event: MouseEvent): void;
}>();

const handleClick = (e: MouseEvent) => {
    emit('click', e);
};

const decoratedProps = computed<{
    fill?: 'clear' | 'outline' | 'solid' | 'default';
    color?: Color;
}>(() => {
    switch (variant) {
        default:
        case 'primary':
            return {
                fill: 'default',
                color: 'primary',
            };
        case 'primary-alt':
            return {
                fill: 'default',
                color: 'secondary',
            };
        case 'secondary':
            return {};
        case 'tertiary':
            return {
                fill: 'outline',
                color: 'secondary',
            };
        case 'destructive':
            return {
                fill: 'default',
                color: 'danger',
            };
        case 'text':
        case 'borderless':
            return {
                fill: 'clear',
                color: color ?? 'secondary',
            };
    }
});
</script>

<template>
    <ion-button
        :class="['l-button', variant]"
        :disabled="disabled"
        :expand="expand"
        :type="type"
        :size="size"
        :fill="decoratedProps.fill"
        :color="decoratedProps.color"
        :router-link="routerLink"
        :strong="strong"
        @click="handleClick"
    >
        <slot />
    </ion-button>
</template>

<style lang="scss">
@use '@/css/ui-kit.scss' as *;

ion-button {
    margin: 0;
    --border-color: var(--ion-color-contrast);
    --box-shadow: inset 0 0 0 2px var(--border-color);
    --border-radius: #{$default-border-radius};
    --border-width: 0;
    --background-hover: #{$grey-100};
    --background-hover-opacity: 0.5;
    --color: var(--ion-color-contrast);
    --background: var(--ion-color-base);
    &.button {
        &.button-has-icon-only {
            --padding-top: 0;
            --padding-bottom: 0;
            --padding-start: 0;
            --padding-end: 0;
        }
        &.button-small:not(.button-has-icon-only) {
            font-size: 1rem;
            line-height: 1.25rem;
            --padding-start: 1rem;
            --padding-end: 1rem;
            --padding-top: 0.5rem;
            --padding-bottom: 0.5rem;
            min-height: 2.5rem;
        }
        &.button:not(.button-has-icon-only, .button-small, .button-large) {
            font-size: 1rem;
            line-height: 1.25rem;
            --padding-bottom: 0.75rem;
            --padding-top: 0.75rem;
            --padding-start: 1rem;
            --padding-end: 1rem;
            min-height: 3rem;
        }
        &.button-large:not(.button-has-icon-only) {
            font-size: 1.25rem;
            line-height: 1.5rem;
            --padding-start: 1.5rem;
            --padding-end: 1.5rem;
            --padding-top: 1rem;
            --padding-bottom: 1rem;
            min-height: 3.5rem;
        }
        &.button-outline {
            --box-shadow: inset 0 0 0 2px #{$grey-400};
            &:hover {
                --box-shadow: inset 0 0 0 2px #{$grey-800};
            }
        }
        &.button-clear {
            font-size: 1rem;
            &:not(.button-has-icon-only, .text):hover {
                --box-shadow: inset 0 0 0 2px #{$grey-800};
                --border-color: #{$grey-800};
            }
        }
        &.primary {
            --background-hover: #{$green-light};
            --border-color: #{$grey-700};
        }
        &.primary-alt {
            --border-color: var(--background);
            --background-hover: #{$grey-600};
        }
        &.borderless {
            --border-color: transparent;
        }
        &.secondary {
            --background-activated-opacity: 0;
            --background-hover: #{$grey-300};
        }
        &.text {
            --border-color: transparent;
            --padding-start: 0;
            --padding-end: 0;
        }
        &.destructive {
            --border-color: var(--background);
            --background-hover: #{$red-dark};
        }
    }
}
</style>
