import LoginPage from '@/modules/auth/pages/LoginPage.vue';
import ResetPasswordPage from '@/modules/auth/pages/ResetPasswordPage.vue';
import SelectYourRolePage from '@/modules/auth/pages/SelectYourRolePage.vue';
import SignUpPage from '@/modules/auth/pages/SignUpPage.vue';
import { RouteRecordRaw } from 'vue-router';
import HomePage from '@/pages/HomePage.vue';
import { authGuard } from './guards/auth.guard';

export enum CommonRoutes {
    Home = 'CommonHome',
    Login = 'CommonLogin',
    SignUp = 'CommonSignUp',
    SelectRole = 'CommonSelectRole',
    ResetPassword = 'CommonResetPassword',
}

export const commonRoutes: RouteRecordRaw[] = [
    {
        path: '/',
        name: CommonRoutes.Home,
        component: HomePage,
        beforeEnter: authGuard,
    },
    // Auth routes
    {
        path: '/login',
        name: CommonRoutes.Login,
        component: LoginPage,
    },
    {
        path: '/signup',
        name: CommonRoutes.SignUp,
        component: SignUpPage,
    },
    {
        path: '/select-role',
        name: CommonRoutes.SelectRole,
        component: SelectYourRolePage,
    },
    {
        path: '/reset-password',
        name: CommonRoutes.ResetPassword,
        component: ResetPasswordPage,
    },
];
